/**
 * @summary MapUploadGrid.js
 * @file Grid component that allows users to view different status variables of their map uploads
 * @returns {JSX}
 * @usedBy MapUpload.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import 'react-toastify/dist/ReactToastify.css';
import KendoGridBase from '../../../shared/ui/kendoGridBase/KendoGridBase';
import {
  gridSelectedDataActions,
  getProjects,
  clearSelectedProject
} from '../../../store/projects/ProjectActions';
import { getUploadStats } from '../../../store/uploads/MapUploadActions';
import store from '../../../store/store';
import { clearNodes } from '../../../store/nodes/NodeActions';
import formattedCell from '../../../shared/ui/kendoGridBase/Grid/FormattedCell';

const MapUploadGrid = ({
  getUploadStats,
  refresh,
  setRefresh,
  projectId,
  isGridRefreshOnUpload
}) => {
  const [gridState, setGridState] = useState({
    projectcollections: { data: [], total: 0 },
    dataState: { take: 10, skip: 1 }
  });
  const { userObj } = useSelector((state) => ({
    userObj: state.authReducer.userObj
  }));
  const [filter, setFilter] = useState({});
  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 10 });
  const [, setLoading] = useState(false);
  const [, setSkipProcessing] = useState(0);

  const messageOptions = {
    messsageType: {
      success: 'Success'
    },
    messages: {
      underConstruction: 'This Function is Under Construction',
      successfulDownload: 'Success Downloading Package',
      errorOnDownload: 'Error Downloading Package'
    }
  };

  const handleGridFilterChange = (colFilter) => {
    setRefresh(true);
    setFilter(colFilter || {});
  };

  const handleGridSortChange = (obj) => {
    setRefresh(true);
    setSort(obj);
  };

  /**
   * This method is used to get s3 signed get url for a given file path
   */
  const downloadExportedPackage = (e, params) => {
    e.preventDefault();
    axios
      .post('/api/signedgeturl', params)
      .then((res) => {
        if (res.data.success) {
          const dlink = document.createElement('a');
          dlink.href = res.data.data;
          dlink.onclick = () => window.URL.revokeObjectURL(res.data.data);
          dlink.click();
          dlink.remove();
          return toast.success(messageOptions.messages.successfulDownload);
        }
      })
      .catch((error) => {
        if (error?.response?.data) {
          return toast.error(messageOptions.messages.errorOnDownload);
        } else {
          return toast.error(messageOptions.messages.errorOnDownload);
        }
      });
  };

  // Handler is used to view the imported package error
  const handleDownloadPackage = (cell, row) => (
    <>
      {row?.dataItem?.s3_file_upload_path !== 'undefined' && (
        // eslint-disable-next-line
        <a
          href="#"
          onClick={(e) => {
            const params = {
              params: {
                mediaPath: row?.dataItem?.s3_file_upload_path
              }
            };
            downloadExportedPackage(e, params);
          }}
        >
          <u style={{ color: 'blue' }}>{row?.dataItem?.file_name}</u>
        </a>
      )}
    </>
  );

  const gridUploadStatsColumns = [
    {
      field: 'created_date',
      title: 'Uploaded',
      show: true,
      filterable: false,
      width: '250px'
    },
    {
      field: 'status',
      title: 'Status',
      show: true,
      filterable: false,
      // filter: 'numeric',
      width: '250px'
    },
    {
      field: 'attr_created_count',
      title: 'Created',
      show: true,
      filterable: false,
      filter: 'text',
      width: '250px'
    },
    // {
    //   field: 'attr_updated_count',
    //   title: 'Updated',
    //   show: true,
    //   filterable: false,
    //   filter: 'text',
    //   width: '100px'
    // },
    // {
    //   field: 'attr_deleted_count',
    //   title: 'Removed',
    //   show: true,
    //   filterable: false,
    //   filter: 'text',
    //   width: '100px'
    // },
    {
      field: 'attr_rejected_count',
      title: 'Rejected',
      show: true,
      filterable: false,
      filter: 'text',
      width: '250px'
    },
    {
      field: 'attr_error_count',
      title: 'Error',
      show: true,
      filterable: false,
      filter: 'text',
      width: '250px'
    },
    {
      field: 'errors',
      title: 'Error Messages',
      show: true,
      filterable: false,
      filter: 'text',
      width: '300px'
    },
    {
      field: 'file_name',
      title: 'File',
      show: true,
      filterable: false,
      filter: 'text',
      width: '250px',
      cell: formattedCell(handleDownloadPackage),
      cellType: 'link'
    }
  ];

  const updateGridState = (projectId) => {
    getUploadStats(projectId).then(() => {
      clearNodes();
      const projectState = store.getState().nodeUploadReducer;
      const projects = { ...projectState };
      delete projects.selectedData;
      const projectcollections = {
        data: projects?.uploadStatsList?.filter(Boolean),
        total: projects?.uploadStatsList?.length
      };
      setGridState({
        ...gridState,
        projectcollections,
        dataState: { take: page.take, skip: page.skip }
      });
    });
  };

  useEffect(() => {
    if (isGridRefreshOnUpload && projectId !== null) {
      updateGridState(projectId);
    }
  }, [isGridRefreshOnUpload, projectId]);

  useEffect(() => {
    if (refresh && projectId !== null) {
      setLoading(true);
      updateGridState(projectId);
      setLoading(false);
      setSkipProcessing(page.skip);
    } else {
      const gridObjs = { ...gridState.projectcollections };
      gridObjs.data = [];
      setGridState({ ...gridState, projectcollections: gridObjs });
    }
  }, [filter, sort, page, refresh, projectId]); // eslint-disable-line

  return (
    <div>
      <KendoGridBase
        className="block-grid"
        gridColumns={gridUploadStatsColumns}
        data={gridState.projectcollections.data || []}
        setGridFilters={handleGridFilterChange}
        setGridSort={handleGridSortChange}
        updateGridData={gridSelectedDataActions}
        sorter={sort}
        rowHeight={40}
        skip={page.skip}
        take={page.take}
        total={
          gridState.projectcollections ? gridState.projectcollections.total : 0
        }
        pageSize={10}
        scrollable
        selectable="selected"
        pageable
        sortable
        resizable
      />
    </div>
  );
};
MapUploadGrid.propTypes = {
  getUploadStats: PropTypes.func,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  projectId: PropTypes.number,
  isGridRefreshOnUpload: PropTypes.bool
};

export default connect(null, {
  gridSelectedDataActions,
  getUploadStats,
  clearNodes,
  clearSelectedProject
})(MapUploadGrid);
