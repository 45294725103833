/**
 * @summary Reducer for auth state management
 * @file  AuthReducer.jsx
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SIGNOUT_SUCCESS,
  FORGOT_PWD_FAILURE,
  RESET_PWD_FAILURE,
  NEW_PWD_SUCCESS,
  NEW_PWD_FAILURE,
  FORGOT_PWD_SUCCESS,
  RESET_PWD_SUCCESS,
  GET_CURRENT_USER,
  MODE_CHANGE,
  UPDATE_USER,
  UPDATE_USER_TAB
} from './AuthType';

const defaultState = {
  isAuthenticated: false,
  userObj: {},
  errorMessage: null,
  userTab: 'nodes'
};

const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case GET_CURRENT_USER:
    case UPDATE_USER:
      return {
        ...state,
        isAuthenticated: true,
        userObj: action.userObj,
        errorMessage: null
      };

    case UPDATE_USER_TAB:
      return {
        ...state,
        userTab: action.tabName || 'nodes'
      };
    case LOGIN_FAILURE:
    case FORGOT_PWD_FAILURE:
    case RESET_PWD_FAILURE:
    case NEW_PWD_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        userObj: {},
        errorMessage: action.errorMessage
      };

    case SIGNOUT_SUCCESS:
    case FORGOT_PWD_SUCCESS:
    case RESET_PWD_SUCCESS:
    case NEW_PWD_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        userObj: {},
        errorMessage: null
      };

    case MODE_CHANGE:
      return {
        ...state,
        userObj: {
          ...state.userObj,
          mode: action.mode
        }
      };

    default:
      return state;
  }
};

export default authReducer;
