/**
 * @summary ProtectedRoute.jsx
 * @file Protects routes by validating if user is logged. If not, re-directs them to login page
 * @returns {JSX}
 * @usedBy LinkageLevelMediaGrid.js, NeighborhoodMediaGrid.js, NodeMediaGrid.js, MapUploadGrid.js
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

export const ProtectedRoute = ({ children }) => {
  const { user } = useSelector((store) => ({
    user: store.authReducer.userObj
  }));
  if (!user.id) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return children;
};

export default ProtectedRoute;

ProtectedRoute.propTypes = {
  children: PropTypes.object
};