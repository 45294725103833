/**
 * @summary ConnectionsActions.jsx
 * @file Connection actions
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import axios from 'axios';
import {
  CREATE_CONNECTION,
  GET_CONNECTIONS,
  GRID_DATA_ACTIONS_SELECTIONS_CONNECTIONS_FULLFILLED,
  CLEAR_CONNECTIONS,
  GET_CONNECTION,
  EDIT_CONNECTIONS,
  SET_NEW_CONNECTION,
  EDIT_CONNECTION,
  DELETE_CONNECTION,
  DELETE_MULTIPLE_CONNECTIONS
} from './ConnectionTypes';

export const createConnection = (formValues) => async (dispatch) => {
  const resp = await axios.post(
    `/api/projects/${formValues.projectId}/branch/${formValues.branchId}/connections`,
    { ...formValues }
  );
  dispatch({
    type: CREATE_CONNECTION,
    connection: resp.data.connection,
    meta: resp.data.connection
  });
  return resp;
};

export const editConnection = (formValues) => async (dispatch) => {
  const resp = await axios.put(
    `/api/projects/${formValues.projectId}/branch/${formValues.branchId}/connections/${formValues.id}`,
    { ...formValues }
  );
  dispatch({
    type: EDIT_CONNECTION,
    connection: formValues,
    meta: resp.data
  });
  return resp;
};

export const editConnections = (formValues) => async (dispatch) => {
  const resp = await axios.put(
    `/api/projects/${formValues.projectId}/branch/${formValues.branchId}/connections/${formValues.id}`,
    { ...formValues }
  );
  dispatch({
    type: EDIT_CONNECTIONS,
    connections: formValues,
    meta: resp.data
  });
  return resp;
};

export const clearConnections = () => ({ type: CLEAR_CONNECTIONS });

export const getConnections = (projectId, branchId) => async (dispatch) => {
  const { data } = await axios.get(
    `/api/projects/${projectId}/branch/${branchId}/connections`
  );
  if (data) {
    dispatch({
      type: GET_CONNECTIONS,
      connections: data
    });
  } else {
    dispatch(clearConnections());
  }
};

export const getConnection =
  (projectId, branchId, connectionId) => async (dispatch) => {
    const { data } = await axios.get(
      `/api/projects/${projectId}/branch/${branchId}/connection/${connectionId}`
    );
    if (data) {
      dispatch({
        type: GET_CONNECTION,
        selectedConn: data
      });
    } else {
      dispatch(clearConnections());
    }
  };

const updateSelectedGrid = (data) => ({
  type: GRID_DATA_ACTIONS_SELECTIONS_CONNECTIONS_FULLFILLED,
  connections: {
    data
  }
});

export const gridSelectedDataActions = (data) => (dispatch) => {
  dispatch(updateSelectedGrid(data));
};

export const deleteConnection = (connection) => async (dispatch) => {
  try {
    const resp = await axios.delete(
      `/api/projects/${connection.projectId}/branch/${connection.branchId}/connections/${connection.id}`
    );
    dispatch({
      type: DELETE_CONNECTION,
      connection
    });
    return resp;
  } catch (error) {
    console.log(error);
  }
};

export const deleteMultipleConnections =
  (project, branch, connectionIds) => async (dispatch) => {
    try {
      const resp = await axios.post(
        `/api/projects/${project.id}/branch/${branch.id}/connections/multiple`,
        { connectionIds }
      );
      dispatch({
        type: DELETE_MULTIPLE_CONNECTIONS,
        connectionIds
      });
      return resp;
    } catch (error) {
      console.log(error);
    }
  };

export const getConnectionSettings =
  (projectId, branchId) => async (dispatch) => {
    const { data } = await axios.get(
      `/api/projects/${projectId}/branch/${branchId}/connectionSettings`
    );
    return data;
  };

export const setNewConnection = () => ({ type: SET_NEW_CONNECTION });
