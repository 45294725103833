/**
 * @summary Profile.jsx
 * @file Modal component that allows user to change and save their program/project/branch
 * @returns {JSX}
 * @usedBy Header.jsx
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Offcanvas from 'react-bootstrap/Offcanvas';
import axios from 'axios';
import store from '../../store/store';
import { getBranchesDetails } from '../../store/branchesDetails/branchesDetailsActions';
import {
  receiveSignOut,
  signOutError,
  receiveLogin,
  updateUserPrefs
} from '../../store/auth/AuthActions';
import {
  getProjects,
  clearAllProjects
} from '../../store/projects/ProjectActions';
import { hydrateProject } from 'store/ProjectSetup/ProjectSetupActions';
import { updateUser } from '../../store/auth/AuthActions';
import { toast } from 'react-toastify';

const Profile = () => {
  const navigate = useNavigate();
  const [userSessionText, setUserSessionText] = useState();
  const [show, setShow] = useState(false);

  const defaultStyles = {
    control: (base, state) => ({
      ...base
    }),
    menu: (base) => ({
      ...base
    }),
    menuList: (base) => ({
      ...base,
      minHeight: '25px', // your desired height
      maxHeight: '160px' // your desired
    })
  };

  const defaultProject = [{ id: 0, name: '<Select a Project>' }];
  const defaultBranch = [{ id: 0, name: '<Select a Branch>' }];

  const userSessionTextTemplate =
    'Signed in as <firstName> <lastName> on <program> <selectedProject> on <selectedBranch>';

  const { projects, userObj, branches, selectedProgram } = useSelector(
    (state) => {
      const projectList = { ...state.projectReducer };
      delete projectList.error;
      delete projectList.selectedData;
      const branchesList = { ...state.branchesDetailsReducer.activeBranches };
      delete branchesList.error;
      delete branchesList.selectedData;
      return {
        projects: Object.values(projectList).filter(Boolean),
        branches: Object.values(branchesList).filter(Boolean),
        userObj: state.authReducer.userObj,
        selectedProgram: state.authReducer.userObj.currentAssessmentProgram,
        selectedProject: state.authReducer.userObj.selectedProject,
        selectedBranch: state.authReducer.userObj.selectedBranch
      };
    }
  );

  const buildUserSessionText = (
    currentProgram,
    currentProject,
    currentBranch
  ) => {
    const sessionText = userSessionTextTemplate
      .replace(/<firstName>/, userObj.firstName)
      .replace(/<lastName>/, userObj.lastName)
      .replace(/<program>/, currentProgram.abbreviatedName)
      .replace(
        /<selectedProject>/,
        currentProject ? currentProject.name : defaultProject[0].name
      )
      .replace(
        /<selectedBranch>/,
        currentBranch ? currentBranch.name : defaultBranch[0].name
      );

    setUserSessionText(sessionText);
  };

  const handleSignOut = (event) => {
    event.preventDefault();
    axios
      .post('/api/signOut', {
        email: userObj.emailAddress
      })
      .then((res) => {
        toast.success('User Signed Out');
        const statusCode = res.status;

        if (statusCode === 200) {
          // redirect to the login page
          store.dispatch(receiveSignOut(res.data));
          navigate('/login');
        } else {
          toast.error('Error Signing Out');
          store.dispatch(
            signOutError(
              `Sign out received status other than 200 (${statusCode}) but was not caught.`
            )
          ); // send that to the redux store
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUserPrefSave = async () => {
    const newUserData = {
      ...userObj,
      currentAssessmentProgram: programState,
      selectedProject: [projectState] || defaultProject[0],
      selectedBranch: [branchState] || defaultBranch[0],
      mode: userObj.mode
    };
    //check if the incoming project change has merged data on published
    const data = await store.dispatch(hydrateProject(newUserData));
    if (data === null && newUserData.selectedProject.id > 0) {
      //User cannot access published or edit branch until some data is merged
      toast.error(
        `${userObj.selectedProject[0].name} on branch: ${userObj.selectedBranch[0].name} does not have merged data.`
      );
    } else if (newUserData.selectedProject.id === 0 || newUserData.selectedBranch.id === 0) {
      buildUserSessionText(
        currentProgram,
        newUserData.selectedProject[0],
        newUserData.selectedBranch[0]
      );
      store.dispatch(receiveLogin(newUserData));
      const sessionStorage = window.sessionStorage;
      sessionStorage.setItem('user', JSON.stringify(newUserData));
      onModalChange();
      toast.success('User Settings Saved');
    } else {
      axios
        .post('/api/saveUserPreference', newUserData)
        .then(() => {
          const currentProgram = newUserData.assessmentPrograms.filter(
            (el) => el.id === newUserData.currentAssessmentProgram
          )[0];
          buildUserSessionText(
            currentProgram,
            newUserData.selectedProject[0],
            newUserData.selectedBranch[0]
          );
          store.dispatch(receiveLogin(newUserData));
          const sessionStorage = window.sessionStorage;
          sessionStorage.setItem('user', JSON.stringify(newUserData));
          onModalChange();
          toast.success('User Settings Saved');
        })
        .catch((err) => {
          toast.error('Error Saving User Settings');
        });
    }
  };

  const onModeChange = (e) => {
    const { value } = e.target;
    const newUserData = {
      ...userObj,
      currentAssessmentProgram: selectedProgram,
      selectedProject: defaultProject,
      selectedBranch: defaultBranch,
      mode: value
    };
    store.dispatch(updateUserPrefs(newUserData));
    store.dispatch(updateUser(newUserData));
  };

  const [programState, setProgramState] = useState(selectedProgram);
  const [projectState, setProjectState] = useState(userObj.selectedProject[0]);
  const [branchState, setBranchState] = useState(userObj.selectedBranch[0]);

  const programOnChange = (e) => {
    const value = parseInt(e.target.value, 10);
    const newSelectedProgram = userObj.assessmentPrograms.find(
      (program) => program.id === value
    );
    setProgramState(value);
    store.dispatch(clearAllProjects());
    store.dispatch(getProjects(newSelectedProgram.id));
    setProjectState(defaultProject[0]);
    setBranchState(defaultBranch[0]);
    // need to update user
  };

  const projectOnChange = (e) => {
    const value = parseInt(e.target.value, 10);
    const newSelectedProject = projects.find((project) => project.id === value);
    setBranchState(defaultBranch[0]);
    if (newSelectedProject) {
      setProjectState(newSelectedProject);
      store.dispatch(getBranchesDetails(newSelectedProject.id));
    } else {
      setProjectState(defaultProject[0]);
    }
  };

  const branchOnChange = (e) => {
    const value = parseInt(e.target.value, 10);
    const newSelectedBranch = branches.find((branch) => branch.id === value);
    if (newSelectedBranch) {
      setBranchState(newSelectedBranch);
    } else {
      setBranchState(defaultBranch[0]);
    }
  };

  const onModalChange = () => {
    setShow(!show);
  };

  useEffect(() => {
    const programObj = userObj.assessmentPrograms.find(
      (ap) => ap.id === selectedProgram
    );
    buildUserSessionText(
      programObj,
      userObj.selectedProject[0],
      userObj.selectedBranch[0]
    );
    setProjectState(userObj.selectedProject[0]);
    setBranchState(userObj.selectedBranch[0]);
  }, []);

  useEffect(() => {
    if (userObj.selectedProject && userObj.selectedProject[0]) {
      store.dispatch(getBranchesDetails(userObj.selectedProject[0].id));
    }
  }, [selectedProgram, userObj]);

  useEffect(() => {
    const programObj = userObj.assessmentPrograms.find(
      (ap) => ap.id === selectedProgram
    );
    buildUserSessionText(
      programObj,
      userObj.selectedProject[0],
      userObj.selectedBranch[0]
    );
    setProjectState(userObj.selectedProject[0]);
    setBranchState(userObj.selectedBranch[0]);
  }, [userObj, show]);

  return (
    <div className="float-end">
      <div
        onClick={(e) => onModalChange()}
        className="nav-link profile-link-text cursor-pointer"
      >
        {userSessionText}
        <i className="bi bi-person-circle ms-2 me-1" />
        <span
          className={
            show ? 'k-icon k-i-arrow-60-down' : 'k-icon k-i-arrow-60-left'
          }
        ></span>
      </div>
      <Offcanvas
        show={show}
        placement="end"
        scroll={false}
        backdrop={false}
        className="userProfile"
      >
        <Offcanvas.Header>
          <Offcanvas.Title>Current Settings</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <fieldset className="bg-light p-3 rounded">
            <div className="col d-flex p-2 justify-content-between">
              <div className="col-5 text-end">
                <label className="form-label">Program:</label>
              </div>
              <div className="col-7">
                <select
                  className="form-select form-select-sm"
                  onChange={programOnChange}
                  value={programState}
                >
                  <option value="0" disabled>
                    Select a Program
                  </option>
                  {userObj.assessmentPrograms.map((program) => (
                    <option key={program.id} value={program.id}>
                      {program.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col d-flex p-2 justify-content-between">
              <div className="col-5 text-end">
                <label className="form-label">Project:</label>
              </div>
              <div className="col-7">
                <select
                  className="form-select form-select-sm"
                  onChange={projectOnChange}
                  value={projectState ? projectState?.id : defaultProject.id}
                >
                  <option value={0}>
                    Select a Project
                  </option>
                  {projects.map((project) => (
                    <option key={project.id} value={project.id}>
                      {project.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col d-flex p-2 justify-content-between">
              <div className="col-5 text-end">
                <label className="form-label">Branch:</label>
              </div>
              <div className="col-7">
                <select
                  className="form-select form-select-sm"
                  onChange={branchOnChange}
                  value={branchState ? branchState?.id : defaultBranch.id}
                >
                  <option value={0}>
                    Select a Branch
                  </option>
                  {branches.map((branch) => (
                    <option key={branch.id} value={branch.id}>
                      {branch.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </fieldset>
          <div className="col pt-1 pb-1 hstack gap-2" />

          <div className="d-grid d-md-flex justify-content-between mt-2">
            <button
              className="btn btn-primary me-md-2"
              type="button"
              onClick={handleSignOut}
            >
              <i className="bi bi-box-arrow-right" /> Log Out
            </button>
            <button
              className="btn btn-primary me-md-2"
              type="button"
              onClick={handleUserPrefSave}
            >
              Save
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Profile;
