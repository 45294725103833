/**
 * @summary CanvasWrapper.js
 * @file Wrapper for CanvasPage - its purpose is to pull and format data that's used in CanvasPage (based on Profile.jsx inputs).
 * @returns {JSX}
 * @usedBy routes.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import CanvasPage from './CanvasPage';
import store from '../../store/store';
import { axiosCanvasMapData}  from './utils/axiosMapData'
import formatCanvasMapData from './utils/formatCanvasMapData'
import { toast } from 'react-toastify';

const CanvasWrapper = () => {
  /////////////////////////////
  // MASTER STATE FOR CANVAS //
  /////////////////////////////
  // When branch or project selection change, this gets updates and resent to Canvas Page
  const [canvasMapData, setCanvasMapData] = useState(null) 

  // USE SELECTOR GRABS PROFILE SELECTION DATA
  const {profileSelectionObj} = useSelector((state) => {
    const userObj = state.authReducer.userObj;
    return {
      profileSelectionObj: {
        userObj,
        selectedProject: state.authReducer.userObj.selectedProject[0],
        selectedBranch: state.authReducer.userObj.selectedBranch[0],
      }
    };
  }, shallowEqual);
  
  // CANVAS GRABS NEW DATA IF BRANCH OR PROJECT CHANGE UNDER PROFILE SELECTIONS
  useEffect(()=> {
      if(profileSelectionObj) {
        // Set Canvas to Null While New Data is Fetching so Canvas Knows to Reset  
        setCanvasMapData(null)
        axiosCanvasMapData(profileSelectionObj).then(axiosResponse => {
          const formattedMapData = formatCanvasMapData(profileSelectionObj.selectedProject, profileSelectionObj.selectedBranch, axiosResponse.nodes, axiosResponse.connections, axiosResponse.neighborhoods)
          const finalDataObj = {
            data: formattedMapData,
            userObj: profileSelectionObj.userObj,
            selectedProject: profileSelectionObj.selectedProject,
            selectedBranch: profileSelectionObj.selectedBranch,
            // views: axiosResponse.views,
          }
          setCanvasMapData(finalDataObj)
          // if(axiosResponse.errorMessages.length > 0){
          //   return toast.error(axiosResponse.errorMessages[0])
          // }
        })
      }
     
  }, [profileSelectionObj.selectedProject, profileSelectionObj.selectedBranch])
  // USE SELECTOR
  if(canvasMapData){
    return(
      <>
        {canvasMapData ? 
        <CanvasPage 
          canvasMapData={canvasMapData}
        /> 
        : 
        <div>
          Something went wrong. Try refreshing.
        </div>}
      </>
    );
  } else {
      return (
        <>
        <div className="canvas-page-container">
          <div className='load-message'>
            <h2 style={{fontSize: "48px", fontWeight: 700}}>One Moment</h2>
            <span style={{fontSize: "36px", fontWeight: 500}}>Formatting Data for Canvas...</span>
          </div>
        </div>
        </>
      );
  }

};

export default CanvasWrapper;
