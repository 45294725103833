/**
 * @summary normalizeName.js
 * @file formats metadata objects into category for forms
 * @returns {JSX}
 * @usedBy NodeForm, ConnectionForm, NewForm, NeighborhoodForm
 * @author Dj Ritchey
 * @since 4/01/2021
 * @lastUpdated 04/2021
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { taxonomiesDimensions, accessibilityFlags } from './constants';
import { snakeCase } from 'lodash';
import { normalizeName } from './normalizeName';

export const setSelectedValues = ({
  name,
  checked,
  isNewField,
  category,
  value,
  attributeId,
  attributeSelectionLevelId,
  qty,
  dataType,
  metaDataId,
  isCloned
}) => {
  if (checked || isNewField || attributeId || isCloned) {
    switch (name) {
      case 'observation':
        return {
          name,
          dataType: 'Text',
          category: normalizeName(category),
          isTypeChangable: false,
          hasQty: false,
          qty: qty || 1,
          value: value || [],
          complexValues: false,
          id: attributeId || null,
          levelJoinId: attributeSelectionLevelId || null,
          metaDataId: metaDataId || [],
          valueNames: value || []
        };

      case 'citation':
        return {
          name,
          dataType,
          category: normalizeName(category),
          isTypeChangable: true,
          hasQty: false,
          qty: qty || 1,
          value: value || [],
          complexValues: false,
          id: attributeId || null,
          levelJoinId: attributeSelectionLevelId || null,
          metaDataId: metaDataId || [],
          valueNames: value || []
        };
      case 'citation_category':
        return {
          name,
          dataType: 'Multi-Select',
          category: normalizeName(category),
          isTypeChangable: false,
          hasQty: false,
          qty: qty || 1,
          value: value || [],
          complexValues: false,
          id: attributeId || null,
          levelJoinId: attributeSelectionLevelId || null,
          metaDataId: metaDataId || [],
          valueNames: value || []
        };
      case 'subject':
        return {
          name,
          dataType: 'Drop-Down',
          category: normalizeName(category),
          isTypeChangable: false,
          hasQty: false,
          qty: qty || 1,
          value: setValueObj(metaDataId, value),
          complexValues: true,
          id: attributeId || null,
          levelJoinId: attributeSelectionLevelId || null,
          metaDataId: metaDataId || [],
          valueNames: value || []
        };
      case 'content_framework_type':
        return {
          name,
          dataType: 'Drop-Down',
          category: normalizeName(category),
          isTypeChangable: false,
          hasQty: false,
          qty: qty || 1,
          value: setValueObj(metaDataId, value),
          complexValues: true,
          id: attributeId || null,
          levelJoinId: attributeSelectionLevelId || null,
          metaDataId: metaDataId || [],
          valueNames: value || []
        };
      case 'content_codes':
        return {
          name,
          dataType: 'Custom',
          category: normalizeName(category),
          isTypeChangable: false,
          hasQty: true,
          qty: qty || 1,
          value: setValueObj(metaDataId, value),
          complexValues: false,
          id: attributeId || null,
          levelJoinId: attributeSelectionLevelId || null,
          metaDataId: metaDataId || [],
          valueNames: value || []
        };
      case 'linkage_level':
        return {
          name,
          dataType: 'Multi-Select',
          category: normalizeName(category),
          isTypeChangable: false,
          hasQty: false,
          qty: qty || 1,
          value: setValueObj(metaDataId, value),
          complexValues: true,
          id: attributeId || null,
          levelJoinId: attributeSelectionLevelId || null,
          metaDataId: metaDataId || [],
          valueNames: value || []
        };
      case 'cognitive_taxonomy':
        return {
          name,
          dataType: 'Multi-Select',
          category: taxonomiesDimensions,
          isTypeChangable: false,
          hasQty: false,
          qty: qty || 1,
          value: setValueObj(metaDataId, value),
          complexValues: true,
          id: attributeId || null,
          levelJoinId: attributeSelectionLevelId || null,
          metaDataId: metaDataId || [],
          valueNames: value
        };
      case 'dimension':
        return {
          name,
          dataType: 'Multi-Select',
          category: taxonomiesDimensions,
          isTypeChangable: false,
          hasQty: false,
          qty: qty || 1,
          value: setValueObj(metaDataId, value),
          complexValues: false,
          id: attributeId || null,
          levelJoinId: attributeSelectionLevelId || null,
          metaDataId: metaDataId || [],
          valueNames: value || []
        };
      case 'mathematical_practice':
        return {
          name,
          dataType: 'Drop-Down',
          category: taxonomiesDimensions,
          isTypeChangable: false,
          hasQty: false,
          qty: qty || 1,
          value: setValueObj(metaDataId, value),
          complexValues: true,
          id: attributeId || null,
          levelJoinId: attributeSelectionLevelId || null,
          metaDataId: metaDataId || [],
          valueNames: value || []
        };
      case 'science':
        return {
          name,
          dataType: 'Drop-Down',
          category: taxonomiesDimensions,
          isTypeChangable: false,
          hasQty: false,
          qty: qty || 1,
          value: value || [],
          complexValues: false,
          id: attributeId || null,
          levelJoinId: attributeSelectionLevelId || null,
          metaDataId: metaDataId || [],
          valueNames: value || []
        };
      case 'science_and_engineering_practice':
        return {
          name,
          dataType: 'Drop-Down',
          category: taxonomiesDimensions,
          isTypeChangable: false,
          hasQty: false,
          qty: qty || 1,
          value: value || [],
          complexValues: false,
          id: attributeId || null,
          levelJoinId: attributeSelectionLevelId || null,
          metaDataId: metaDataId || [],
          valueNames: value || []
        };
      case 'text_complexity':
        return {
          name,
          dataType: 'Drop-Down',
          category: taxonomiesDimensions,
          isTypeChangable: false,
          hasQty: false,
          qty: qty || 1,
          value: value || [],
          complexValues: false,
          id: attributeId || null,
          levelJoinId: attributeSelectionLevelId || null,
          metaDataId: metaDataId || [],
          valueNames: value || []
        };
      case 'vision':
        return {
          name,
          dataType: 'True/False',
          category: accessibilityFlags,
          isTypeChangable: false,
          hasQty: false,
          qty: qty || 1,
          value: ['True', 'False'],
          id: attributeId || null,
          levelJoinId: attributeSelectionLevelId || null,
          metaDataId: metaDataId || [],
          valueNames: ['True', 'False']
        };
      case 'hearing':
        return {
          name,
          dataType: 'True/False',
          category: accessibilityFlags,
          isTypeChangable: false,
          hasQty: false,
          qty: qty || 1,
          value: ['True', 'False'],
          id: attributeId || null,
          levelJoinId: attributeSelectionLevelId || null,
          metaDataId: metaDataId || [],
          valueNames: ['True', 'False']
        };
      case 'speech':
        return {
          name,
          dataType: 'True/False',
          category: accessibilityFlags,
          isTypeChangable: false,
          hasQty: false,
          qty: qty || 1,
          value: ['True', 'False'],
          id: attributeId || null,
          levelJoinId: attributeSelectionLevelId || null,
          metaDataId: metaDataId || [],
          valueNames: ['True', 'False']
        };
      case 'mobility':
        return {
          name,
          dataType: 'True/False',
          category: accessibilityFlags,
          isTypeChangable: false,
          hasQty: false,
          qty: qty || 1,
          value: ['True', 'False'],
          complexValues: false,
          id: attributeId || null,
          levelJoinId: attributeSelectionLevelId || null,
          metaDataId: metaDataId || [],
          valueNames: ['True', 'False']
        };
      case 'dual_sensory':
        return {
          name,
          dataType: 'True/False',
          category: accessibilityFlags,
          isTypeChangable: false,
          hasQty: false,
          qty: qty || 1,
          value: ['True', 'False'],
          complexValues: false,
          id: attributeId || null,
          levelJoinId: attributeSelectionLevelId || null,
          metaDataId: metaDataId || [],
          valueNames: ['True', 'False']
        };
      case 'tags':
        return {
          name,
          dataType: 'Multi-Select',
          category: 'Tags',
          isTypeChangable: false,
          hasQty: false,
          qty: qty || 1,
          value: value || [],
          complexValues: false,
          id: attributeId || null,
          levelJoinId: attributeSelectionLevelId || null,
          metaDataId: metaDataId || [],
          valueNames: value || []
        };
      default:
        name = snakeCase(name);
        return {
          name,
          isTypeChangable: true,
          dataType: dataType || '',
          category: normalizeName(category),
          checked: true,
          hasQty: false,
          qty: qty || 1,
          value: value || [],
          complexValues: false,
          isNewField: true,
          id: attributeId || null,
          levelJoinId: attributeSelectionLevelId || null,
          metaDataId: metaDataId || [],
          valueNames: value || []
        };
    }
  }
};

const setValueObj = (metaDataId, value) => {
  if (metaDataId) {
    return metaDataId.map((id, idx) => ({ id, name: value[idx] }));
  }
  return [];
};
