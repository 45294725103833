/**
 * @summary InspectorTool.js
 * @file Modal for Containing Injuey Data for Selected Element(s). Opened through CanvasInterface.js After at least one Element is Selected
 * @returns {JSX}
 * @usedBy CanvasPage.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { React, useState } from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../canvasPage.scss';
import parse from 'html-react-parser';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import { addComment, updateComment, deleteNodeComment } from 'store/nodes/NodeActions';
import { useSelector } from 'react-redux';
import store from 'store/store';
import { toast } from 'react-toastify';

const InspectorTool = ({ 
  selectedNode, 
  show, 
  setShow 
}) => {

  const { projectId, branchId, assessmentProgramId, screenName } = useSelector((store) => ({
    projectId: store.authReducer.userObj.selectedProject[0].id,
    branchId: store.authReducer.userObj.selectedBranch[0].id,
    assessmentProgramId: store.authReducer.userObj.currentAssessmentProgram,
    screenName: store.authReducer.userObj.screenName
  }));
  const [selectedComment, setSelectedComment] = useState(null)
  const [showNewForm, setShowNewForm] = useState(false)
  const [newComment, setNewComment] = useState({
    content: '',
    projectId,
    branchId,
    assessmentProgramId,
    nodeId: ''
  })

  if (selectedNode) {
    const { data: node } = selectedNode

    const resetComment = () => {
      setNewComment({
        content: '',
        projectId,
        branchId,
        assessmentProgramId,
        nodeId: ''
      })
    }

    const updateNodeComment = async (commentId) => {
      try {
        const { data: { comment } } = await store.dispatch(updateComment({ ...newComment, nodeId: node.id, commentId: commentId }))
        const commentIdx = node.comments.findIndex((commentObj) => commentObj.id === comment.id)
        if (commentIdx > -1) {
          node.comments.splice(commentIdx, 1, { ...node.comments[commentIdx], content: newComment.content, nodeId: node.id, id: comment });
          setSelectedComment(null)
          resetComment()
        }
      } catch(err) {
        toast.error('Error updating comment')
      }
    }

    const addNewComment = async () => {
      try {
        const { data: { comment } } = await store.dispatch(addComment({ ...newComment, nodeId: node.id }))
        node.comments.push(comment)
        setShowNewForm(false)
        resetComment()
      } catch(err) {
        console.log(err)
        toast.error('Error adding comment')
      }
    }

    const deleteComment = async (commentObj) => {
      try {
        const { data: { id } } = await store.dispatch(deleteNodeComment({ ...commentObj, nodeId: node.id, commentId: commentObj.id, projectId, branchId }))
        const commentIdx = node.comments.findIndex((commentObj) => commentObj.id === id)
  
        if (commentIdx > -1) {
          node.comments.splice(commentIdx, 1);
          resetComment()
        }
      } catch(err) {
        toast.error('Error deleting comment')
      }
    }

    if (selectedNode !== null) {
      return (
        <>
          <Modal show={show} backdrop="static" centered size="lg">
            <Modal.Header>
              <div className="row">
                <div className="col-2 text-white">
                  <label className="">Key</label>
                </div>
                <div className="col-2 text-white">
                  <label className="">Name</label>
                </div>
                <div className="col-5 text-white">
                  <label className="">Description</label>
                </div>
                <div className="col-3 text-white">
                  <label className="">Checked out by</label>
                </div>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group row  d-flex"></div>
              <div className="row">
                <div className="col-2">
                  <div className="node-inspector-data-field">
                    {node.nodeKey}
                  </div>
                </div>
                <div className="col-2">
                  <div className="node-inspector-data-field">
                    {node.title}
                  </div>
                </div>
                <div className="col-5">
                  <div className="node-inspector-data-field">
                    {parse(node.markdownDescription)}
                  </div>
                </div>
                <div className="col-3">
                  <div className="node-inspector-data-field">
                    {node.checkedOutBy}
                  </div>
                </div>
              </div>
              <hr className="hr" />
              <div className="form-group">
                <h5>comments</h5>
                {
                  node.comments ? node.comments.map((comment) => (
                    <Card key={`node-comment-${comment.id}`} style={{ margin: '3px' }}>
                      <Card.Body>
                        {selectedComment && comment.id === selectedComment.id ? (
                          <>
                            <textarea 
                              value={newComment.content}
                              style={{ width: '500px' }}
                              onChange={(e) => {
                                setNewComment({
                                  ...newComment,
                                  content: e.target.value
                                })
                              }}
                            />
                             <button
                                type="button"
                                className="btn btn-danger btn-sm text-white"
                                style={{ float: 'right', margin: '2px' }}
                                onClick={() => {
                                  setSelectedComment(null)
                                  resetComment()
                                }}
                              >
                                Cancel
                              </button>
                            <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                style={{ float: 'right', margin: '2px' }}
                                disabled={newComment.content.length < 2}
                                onClick={() => {
                                  setSelectedComment(null)
                                  updateNodeComment(comment.id)
                                }}
                              >
                                <i className="bi bi-save2"></i> Update
                              </button>
                            </>
                        ) : (
                          <>
                            <p>
                            {comment.content}
                            </p>
  
                            <footer className="blockquote-footer">
                            <cite title="Source Title">{comment.createdBy}{' '}{comment.createdDate}</cite>
                            {
                              comment.createdBy === screenName ? (
                                <>
                                  <button
                                  type="button"
                                  className="btn btn-danger btn-sm text-white"
                                  style={{ float: 'right', margin: '2px' }}
                                  onClick={() => {
                                    deleteComment(comment)
                                  }}
                                >
                                  <i className="bi bi-trash"></i> Delete
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm"
                                  style={{ float: 'right', margin: '2px' }}
                                  onClick={() => {
                                    setSelectedComment(comment)
                                    setShowNewForm(false)
                                    setNewComment({
                                      ...newComment,
                                      content: comment.content
                                    })
                                  }}
                                >
                                  <i className="bi bi-save2"></i> Edit
                                </button>
                              </>
                              ) : (null)
                            }
                          </footer>
                        </>
                        )}
                      </Card.Body>
                    </Card>
                  )) : null
                }
              </div>
              {
                showNewForm ? (
                  <Card style={{ margin: '3px' }}>
                    <Card.Body>
                    <textarea 
                      value={newComment.content}
                      style={{ width: '500px' }}
                      onChange={(e) => {
                        setNewComment({
                          ...newComment,
                          content: e.target.value
                        })
                      }}
                    />
                    <button
                      type="button"
                      className="btn btn-danger btn-sm text-white"
                      style={{ float: 'right', margin: '2px' }}
                      onClick={() => {
                        setShowNewForm(false)
                      }}
                    >
                       Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      style={{ float: 'right', margin: '2px' }}
                      disabled={newComment.content.length < 2}
                      onClick={() => {
                        addNewComment()
                        resetComment()
                      }}
                    >
                      <i className="bi bi-save2"></i> Save
                    </button>
                    </Card.Body>
                  </Card>
                ) : (null)
              }
            </Modal.Body>
            <Modal.Footer>
            <button
                className="btn btn-primary btn-sm"
                onClick={() => {
                  setSelectedComment(null)
                  resetComment()
                  setShowNewForm(true)
                }}
              >
                Add Comment
              </button>
              <button
                className="btn btn-danger btn-sm text-white"
                onClick={() => {
                  setSelectedComment(null)
                  resetComment()
                  setShow(false)
                }}
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
  }
  return null;
};

InspectorTool.propTypes = {
  selectedNode: PropTypes.object,
  show: PropTypes.bool,
  setShow: PropTypes.func,
};

export default InspectorTool;