/**
 * @summary ReportPage.jsx
 * @file Parent component for the entire report page section
 * @returns {JSX}
 * @usedBy routes.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

////////////////////////////////////////////////////////////////////
// REPORT PAGE: Parent Component for the Entire Reporting Section //
////////////////////////////////////////////////////////////////////
// This component is broken into three primary steps: INPUT Components, Formatting UTILS, and RETURN Components
// Each Report has its own folder for INPUTS, UTILS, and RETURNS
// PAGE OPENS: The user is introduced with one dropdown for selecting a report
// INPUT COMPONENTS: After selecting a report, that report's input component will render for the user to enter data
// FORMATTING UTILS: After the user enters data and clicks "Generate Report," it is formatted through generateReports() - which may include fetch requests
// RETURN COMPONENTS: After that data is formatted, it is set as "reportData" and passed into ITS corresponding RETURN component - where it is ultimately displayed
///////////////////////////////////////////////////////////////////////////
// QUESTIONS: Contact Andy Greenhaw, Chaitanya Nandibatla, or DJ Ritchey //
///////////////////////////////////////////////////////////////////////////

// MISC IMPORTS: Things Needed to Give the App Its Juice
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import GenerateReport from './GenerateReport'
import axios from 'axios'
import { toast } from 'react-toastify';
import { snakeCase, startCase } from 'lodash';
import { useEffect } from 'react';
import store from 'store/store';
import { getViews } from 'store/views/ViewActions'
import "../../styles/base/index.scss"

///////////////////////////////////////////////////////////////////////////////////////////
// INPUT COMPONENTS: These components render input options based on the selected Report. //
// (RETURN COMPONENTS are contained within the '<GenerateReport/>' Component at the end) //
///////////////////////////////////////////////////////////////////////////////////////////
import CycleCheckInputs from './reportingInputs/CycleCheckInputs';
import ProfileCheckInputs from './reportingInputs/ProfileCheckInputs';
import RadiusAndDiameterInputs from './reportingInputs/RadiusAndDiameterInputs';
import ListComponentsInputs from './reportingInputs/ListComponentsInputs';
import DegreeHistogramInputs from './reportingInputs/DegreeHistogramInputs';
import ShortestPathInputs from './reportingInputs/ShortestPathInputs';
import SnapShotComparisonInputs from './reportingInputs/SnapshotComparisonInputs';
import ChangeLogInputs from './reportingInputs/ChangeLogInputs';

///////////////////////////////////////////////////////////////////////////////////////////////////////////////
// FORMATTING UTILS: These utils format the input data before passing it into the Generate Report component. //
// They are all used in the generateReports() function below //
///////////////////////////////////////////////////////////////
import { getDegreeHistogram } from './reportingUtils/degreeHistogramUtils/getDegreeHistogram';
import { axiosChangeLog } from './reportingUtils/changeLogUtils/axiosChangeLog';
import { axiosCycleCheck } from './reportingUtils/cycleCheckUtils/axiosCycleCheck'
import { axiosDegreeHistogram } from './reportingUtils/degreeHistogramUtils/axiosDegreeHistogram'
import { axiosListComponents } from './reportingUtils/listComponentsUtils/axiosListComponents';
import { axiosRadius } from './reportingUtils/radiusUtils/axiosRadius'
import { getCycleCheck } from './reportingUtils/cycleCheckUtils/cycleCheckUtil';
import { getListComponents } from './reportingUtils/listComponentsUtils/listComponentsUtil'
import { formatPCResponse } from './reportingUtils/profileCheckUtils/formatPCResponse';
import { getNodeRadius } from './reportingUtils/radiusUtils/radiusUtil';
import { getShortestPath } from './reportingUtils/shortestPathUtils/shortestPathUtil';
import { formatFullChangeLogList } from './reportingUtils/changeLogUtils/formatFullChangeLogList'
import { handleOneNode } from './reportingUtils/changeLogUtils/handleOneNode';
import { formatSelectedChangeLog} from './reportingUtils/changeLogUtils/formatSelectedChangeLog'
import { findLogChanges } from './reportingUtils/changeLogUtils/findLogChanges'
import { axiosSnapshots } from './reportingUtils/snapshotComparisonUtils/axiosSnapshots'
import { formatSnapshots } from './reportingUtils/snapshotComparisonUtils/formatSnapshots'
import { findSnapshotDifferences } from './reportingUtils/snapshotComparisonUtils/findSnapshotDifferences'
import { axiosShortestPath } from './reportingUtils/shortestPathUtils/axiosShortestPath';

/////////////////////////////
// PARENT COMPONENT STARTS //
/////////////////////////////
const ReportPage = () => {
  // REPORT TYPE: This STRING is the report's name, which dictates which INPUTS to render. It is set when the user first selects a report.
  const [reportType, setReportType] = useState(null);
  
  const ReportTypeOptions = [
    'Change Log', 
    // 'Cycle Check', 
    'Degree Histogram', 
    // 'List Components', 
    'Profile Check', 
    'Radius and Diameter', 
    'Shortest Path', 
    'Snapshot Comparison'
  ]

  // Sets Report Type When Report Selected
  const handleReportTypeSelection = (reportTypeSelected) => {
    setReportData(null)
    setReportType(reportTypeSelected)
  }
  
  // REPORT DATA: This is the final data object we pass into the "<GenerateReport/>" component to display. It is set after being formatted through generateReport() - after a user clicks "Generate Report."
  const [reportData, setReportData] = useState(null);
  
  // USE SELECTOR: Pulls branch and project data from Redux
  const { project, userObj } = useSelector((state) => {
    return {
      project: state.authReducer.userObj.selectedProject[0],
      userObj: state.authReducer.userObj,
    }
  })

  // USE EFFECT: Gets VIews for the Use Selector (for Menus)
  useEffect(() => {
    store.dispatch(getViews(userObj.selectedProject[0].id))
  }, [])

  ///////////////////////////////////////////////////////////////////////////
  // REPORT GENERATOR: Formats INPUT Data into Displayable "reportingData" // 
  // After clicking "Generate Report", this function formats and returns the final data object that gets displayed in RETURNS //
  ///////////////////////////////////////////////////////////////////////////
  const generateReport = async (inputData) => {
    let resp;
    setReportData(null)
    switch (reportType) {
      ////////////////
      // CHANGE LOG //
      ////////////////
      case 'Change Log': 
      // InputData Includes Project, Branch, and Formatted Request Obj for Axios
        if(inputData.errorMessage !== undefined){
          return toast.error(input.errorMessage)
        }
        axiosChangeLog(inputData).then((formattedResp) => {
          // Error Handler
          if(formattedResp.errorMessage !== null){
            return toast.error(formattedResp.errorMessage)
          // If Response is a Complete Array of Versioned Nodes with No Issues, Keep It Going...
          } else {
            // Format the Array of Versioned Nodes into an Array of "[Before, After]" Pairs We Can Compare
            const unformattedListOfChanges = formatFullChangeLogList(formattedResp.nodePair, inputData.startDate, inputData.endDate)
            if(unformattedListOfChanges.errorMessage){
              return toast.error(unformattedListOfChanges.errorMessage)
            }
            const formattedPairs = formatSelectedChangeLog(unformattedListOfChanges)
            // Compare [Before,After] Nodes in Each Pair to Find Changes
            const formattedPairsWithChanges = findLogChanges(formattedPairs)
            let changeLogObj = {
              startDate: inputData.startDate,
              endDate: inputData.endDate,
              changeLogs: formattedPairsWithChanges
            }

            // Final Report is an Obj with Header Data and an Array of Change Logs We Will Map Into the Table Display
            setReportData(changeLogObj);
          }
        })    
      break;
      /////////////////
      // CYCLE CHECK //
      /////////////////
      case 'Cycle Check':
        axiosCycleCheck(inputData).then((axiosResponse) => {
          const results = getCycleCheck(axiosResponse.nodes, axiosResponse.connections)
          // setReportData(results)
        })
        
      break;
      //////////////////////
      // DEGREE HISTOGRAM //
      //////////////////////
      case 'Degree Histogram':
        // InputData Includes Project and Branch Data for Axios; Extracting Min and Max Before to Save Space Inside
        let {minConnections, maxConnections} = inputData;
        axiosDegreeHistogram(inputData).then((formattedResp) => {
          // Error Handler 
          if(formattedResp.errorMessages.length > 0){
            return toast.error(formattedResp.errorMessages[0])
          }
          // Runs Through Every Node and Finds All the Connected Nodes
          const degreeHistogramData = getDegreeHistogram(formattedResp.nodes, formattedResp.connections, minConnections, maxConnections)
          // Final Report is an Obj with Min/Max Selections and an Array of {NodeKeys, Number of Connections, Connected Nodes} we can map into Table Display}
          setReportData(degreeHistogramData);
        })
        
      break;
      /////////////////////
      // LIST COMPONENTS //
      /////////////////////
      case 'List Components': 
        // InputData Includes Project and Branch Data for Axios; Extracting maxCluster to Save Space on Conditional Returns Inside
        let { maxCluster } = inputData
        axiosListComponents(inputData.project.id, inputData.branchSelection).then((formattedResp) => {
          if(formattedResp.errorMessages.length > 0){
            return toast.error(formattedResp.errorMessages[0])
          }
          // Runs Through Every Node and Finds All Nodes within Cluster (Connected Nodes, and Connected Nodes to those, ongoing until they run out)
          const listComponentData = getListComponents(formattedResp.nodes, formattedResp.connections, maxCluster, inputData.branchSelection.type)
          // Final Report: An Object with Header Data and an Array of Cluster Data to Map Into Table Display
          setReportData(listComponentData);
        })
      break;
      ///////////////////
      // PROFILE CHECK //
      ///////////////////
      case 'Profile Check':
        // Format Input Data for Backend: Snake Case Flags, 
        const { subject } = inputData
        const formattedFlags = inputData.accessibilityFlagSelections.map((flag) => snakeCase(flag.name))
        const projectId = inputData.project.id
        const branchId = inputData.selectedMap.id
        // Simple Request: Minimal Formatting Needed for Axios
        resp = await axios.post(`/api/projects/${projectId}/branch/${branchId}/nodes/profile_check`,{
          projectId,
          branchId,
          accessibilityFlags: formattedFlags,
          subjectId: subject.id,
          subjectName: subject.name
        }) 
        const profileCheckReportData = formatPCResponse(resp.data, formattedFlags, subject.name)
        // Error Handler
        if(profileCheckReportData.errorMessage !== null){
          return toast.error(profileCheckReportData.errorMessage)
        } else {
          setReportData(profileCheckReportData);
        }
      break;
      /////////////////////////
      // RADIUS AND DIAMETER //
      /////////////////////////
      case 'Radius and Diameter':
        let {project, radius, selectedNodeKey} = inputData
        axiosRadius(project.id, inputData.branchSelection).then((formattedResp) => {
          // Error Handler
          if(formattedResp.errorMessages.length > 0){
            return toast.error(formattedResp.errorMessages[0])
          }
          const radiusNodeData = getNodeRadius(formattedResp.nodes, formattedResp.connections, selectedNodeKey, radius, formattedResp.branchType)
          if(radiusNodeData.errorMessage !== null){
            return toast.error(radiusNodeData.errorMessage)
          } else {
            let radiusReportData = {
              nodeDataArray: radiusNodeData.nodeDataArray,
              radius,
              selectedNodeKey
            }
            setReportData(radiusReportData);
          }
        })
      break;
      ///////////////////
      // SHORTEST PATH //
      ///////////////////
      case 'Shortest Path': 
        const {startingNode, destinationNode} = inputData
          if(startingNode === destinationNode){
            return toast.error("You entered two identical node keys. These need to be different to calculate the shortest path between them.")
          }
        axiosShortestPath(inputData.project.id, inputData.selectedBranch).then((formattedResp) => {
          if(formattedResp.errorMessages.length > 0){
            return toast.error(formattedResp.errorMessages[0])
          }
          const  shortestPath = getShortestPath(startingNode, destinationNode, formattedResp.nodes, formattedResp.connections, formattedResp.branchType)
          if(shortestPath.errorMessage !== null){
            return toast.error(shortestPath.errorMessage)
          } else {
            setReportData(shortestPath);
          } 
        })
      break;
      /////////////////////////
      // SNAPSHOT COMPARISON //
      /////////////////////////
      case 'Snapshot Comparison':
        const {branchA, branchB} = inputData;
        if(branchA.menuId === branchB.menuId){
          return toast.error("The two maps you selected are the same.")
        }
        axiosSnapshots(inputData.project.id, branchA, branchB).then((unformattedSnapshotsAandB) => {
          const formattedSnapshotA = formatSnapshots(unformattedSnapshotsAandB[0])

          const formattedSnapshotB = formatSnapshots(unformattedSnapshotsAandB[1])
          const nodeComparisonLog = findSnapshotDifferences([formattedSnapshotA.nodes, formattedSnapshotB.nodes,], "Node")
          const neighborhoodComparisonLog = findSnapshotDifferences([formattedSnapshotA.neighborhoods, formattedSnapshotB.neighborhoods], "Neighborhood")
          const connectionComparisonLog = findSnapshotDifferences([formattedSnapshotA.connections, formattedSnapshotB.connections], "Connection")

          const countAllDifferences = nodeComparisonLog.count + neighborhoodComparisonLog.count + connectionComparisonLog.count
          if(countAllDifferences === 0){
            return toast.error("No differences found between these two maps.")
          } else {
            let snapshotReportData = {
              branchA: formattedSnapshotA.branch,
              branchB: formattedSnapshotB.branch,
              snapshotA: formattedSnapshotA,
              snapshotB: formattedSnapshotB,
              comparisonLogsObj: {
                countAllDifferences,
                nodeLogs: nodeComparisonLog,
                neighborhoodLogs: neighborhoodComparisonLog,
                connectionLogs: connectionComparisonLog
              }
            }
            setReportData(snapshotReportData)
          }
        })
        .catch((error) => {
          console.error(error);
        });        
      break;
    }
  }
  //////////// 
  // RETURN //
  ////////////
  return (
    <div className="col-12">
      <div className="reporting-container">
        <div className='reporting-section'>
          {/* /////////////////////// */}
          {/* HEADER AND INSTRUCTIONS */}
          {/* /////////////////////// */}
          <div className="reporting-header">
            Reports
          </div>
          <div className="reporting-intro-text">
            Select a report from the dropdown that you would like to compile and analyze.
          </div>
          {/* //////////////////////// */}
          {/* REPORT SELECTOR DROPDOWN */}
          {/* //////////////////////// */}
          <select
            className="form-select form-select-sm"
            aria-label=".form-select-sm example"
            value={reportType}
            style={{width: '200px'}}
            onChange={(event) => {
              handleReportTypeSelection(event.target.value)
            }}
            name="reportType"
          >
            <option value="0">Select a Report</option>
            {ReportTypeOptions.map((report) => (
              <option key={ReportTypeOptions.indexOf(report)} value={report}>
                {report}
              </option>
            ))}
          </select>
        </div>
        {/* //////////////////////////////////////////////////////////////// */}
        {/* INPUT COMPONENTS: Renders Inputs Based On Report Selection Above */}
        {/* //////////////////////////////////////////////////////////////// */}
        {reportType === 'Change Log' && (  
            <ChangeLogInputs
              userObj={userObj}
              project={project}
              generateReport={generateReport}
            />
          )
        }
        {reportType === 'Cycle Check' && ( 
            <CycleCheckInputs 
              userObj={userObj}
              project={project}
              generateReport={generateReport}       
            />
          )
        }
        {reportType === 'Degree Histogram' && ( 
            <DegreeHistogramInputs
              userObj={userObj}
              project={project}
              generateReport={generateReport}
            />
          )
        }
        {reportType === 'List Components' && ( 
            <ListComponentsInputs
              userObj={userObj}
              project={project}
              generateReport={generateReport}
            />
          )
        }
        {reportType === 'Profile Check' && ( 
            <ProfileCheckInputs 
              userObj={userObj}
              project={project}
              generateReport={generateReport}
            />
          )
        }
        {reportType === 'Radius and Diameter' && ( 
            <RadiusAndDiameterInputs
              userObj={userObj}
              project={project}
              generateReport={generateReport}
            />
          )
        }
        {reportType === 'Shortest Path' && ( 
            <ShortestPathInputs
              userObj={userObj}
              project={project}
              generateReport={generateReport}
            />
          )
        }
        {reportType === 'Snapshot Comparison' && ( 
            <SnapShotComparisonInputs
              userObj={userObj}
              project={project}
              generateReport={generateReport}
            />
          )
        }
        {/* GENERATE REPORT: This component contains all the RETURN components that ultimately display the formatted "ReportData"*/}
        {/* ReportData (State): DATA OBJECT formatted through GenerateReports() - Displayed in Corresponding RETURN Component (see intro) */}
        {/* ReportType (State): STRING Name of the Report - used to determine which RETURN component to render. */}
        {
          reportData && (
            <GenerateReport reportData={reportData} reportType={reportType}/>
          )
        }
      </div>
    </div>
  )
}

export default ReportPage;