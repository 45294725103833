/**
 * @summary NeighborhoodCollectionGrid.js
 * @file grid component that populates and updates the UI with neighborhoods
 * @returns {JSX}
 * @usedBy NeighborhoodCollectionPage.js
 * @author Sam Lee
 * @since 2/17/2023
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import KendoGridBase from '../../../shared/ui/kendoGridBase/KendoGridBase';
import {
  getNeighborhoods,
  gridSelectedDataActions,
  selectAllNeighbrohoods,
  getNeighborhoodNodeIds,
  setNewNeighborhood,
  deleteNeighborhood,
  deleteMultipleNeighborhoods
} from '../../../store/neighborhoods/NeighborhoodActions';
import { updateNodesByNeighborhood } from '../../../store/nodes/NodeActions';
// to be used later
// import NestedGrid from '../shared/NestedGrid';
import store from '../../../store/store';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import { snakeCase, startCase } from 'lodash';
import { getLinkageLevels } from 'store/linkageLevels/LinkageLevelActions';
import DeleteConfirmation from '../modal/DeleteConfirmation';

const NeighborhoodCollectionGrid = ({ setRefresh, refresh, headers }) => {
  const [gridState, setGridState] = useState({
    neighborhoodcollections: { data: [], total: 0 },
    dataState: { take: 20, skip: 1 },
    gridDynamicColumns: [
      {
        field: 'selected',
        show: true,
        filterable: false
      },
      {
        field: 'name',
        title: 'Name',
        show: true,
        filterable: true,
        filter: 'text',
        width: '200px'
      },
      {
        field: 'description',
        title: 'Description',
        show: true,
        filterable: true,
        filter: 'text',
        cellType: 'ckeditor_content',
        width: '300px'
      },
      {
        field: 'checkedOutBy',
        title: 'Checked Out',
        show: true,
        filterable: true,
        filter: 'text',
        width: '200px'
      },
      {
        field: 'lastUpdated',
        title: 'Last Updated',
        show: true,
        filterable: true,
        filter: 'text',
        width: '200px'
      }
    ]
  });
  // to be used later
  // const [nestedGridData, setNestedGridData] = useState({
  //   nodecollections: { data: [], total: 0 },
  //   dataState: { take: 100, skip: 1 }
  // });
  const [filter, setFilter] = useState({});
  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 100 });
  const [, setLoading] = useState(false);
  const [, setSkipProcessing] = useState(0);
  const [selectedRowsStateArray, setSelectedStateArray] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const messageOptions = {
    messsageType: {
      success: 'Success'
    },
    messages: {
      successOnDelete: 'Neighborhood Deleted',
      errorOnDelete: 'Unable to Delete Neighborhood',
      errorOnFetch: 'Unable to Load Neighborhoods',
      underConstruction: 'This Function is Under Construction'
    }
  };

  const { userObj } = useSelector((state) => ({
    userObj: state.authReducer.userObj
  }));
  const selectedProject = useSelector(
    (state) => state.authReducer.userObj.selectedProject[0]
  );
  const selectedBranch = useSelector(
    (state) => state.authReducer.userObj.selectedBranch[0]
  );
  const neighborhoods = useSelector((state) => state.neighborhoodReducer);

  const handlePageChange = (e) => {
    e.page.skip = isNaN(e.page.skip) ? 1 : e.page.skip;
    setPage(e.page);
  };

  const handleGridFilterChange = (colFilter) => {
    setRefresh(true);
    setFilter(colFilter || {});
  };

  const handleGridSortChange = (obj) => {
    setRefresh(!refresh);
    // set initial sort order
    if (sort.length === 0 || sort[0].field !== obj[0].field) {
      setSort(obj);
      return;
    }

    // check if we should apply a desc, or reset sort completely
    if (sort[0].field === obj[0].field && sort[0].dir === 'asc') {
      sort[0].dir = 'desc';
      setSort(sort);
    } else if (sort[0].field === obj[0].field && sort[0].dir === 'desc') {
      setSort([]);
    }
  };

  const gridColumns = [
    {
      field: 'selected',
      show: true,
      filterable: false
    },
    {
      field: 'name',
      title: 'Name',
      show: true,
      filterable: true,
      filter: 'text',
      width: '200px'
    },
    {
      field: 'description',
      title: 'Description',
      show: true,
      filterable: true,
      filter: 'text',
      cellType: 'ckeditor_content',
      width: '300px'
    },
    {
      field: 'associatedEe',
      title: 'Associated EE',
      show: true,
      filterable: true,
      filter: 'text',
      cellType: 'text',
      width: '200px'
    },
    {
      field: 'checkedOutBy',
      title: 'Checked Out',
      show: true,
      filterable: true,
      filter: 'text',
      width: '200px'
    },
    {
      field: 'lastUpdated',
      title: 'Last Updated',
      show: true,
      filterable: true,
      filter: 'text',
      width: '200px'
    }
  ];

  useEffect(() => {
    if (headers) {
      const attrHeaders = headers.groupHeader.map((header) => {
        const key  = Object.keys(header);
        return header[key].map((attribute) => {
          return {
            field: attribute.name,
            title: startCase(attribute.name),
            show: true,
            filterable: true,
            filter: 'text',
            width: '200px'
          }

        })
      }).flat()
      setGridState({
        ...gridState,
        gridDynamicColumns: [...gridColumns, ...attrHeaders]
      });
    }
  }, [headers, gridState.neighborhoodcollections])

  const updateGridState = async () => {
    // wrapped in promise purpose: allow async actions to finish prior to populating state
    if (selectedProject && userObj.selectedBranch[0].id) {
      store
        .dispatch(
          getNeighborhoods(selectedProject.id, userObj.selectedBranch[0].id)
        )
        .then(() => {
          store.dispatch(
            getNeighborhoodNodeIds(
              selectedProject.id,
              userObj.selectedBranch[0].id
            )
          );
          store.dispatch(
            getLinkageLevels(selectedProject.id, userObj.selectedBranch[0].id)
          );
        })
        .then(() => {
          const neighborhoods = { ...store.getState().neighborhoodReducer };
          delete neighborhoods.selectedData;
          let neighborhoodcollections = {
            data: Object.values(neighborhoods).filter(Boolean),
            total: Object.values(neighborhoods).length
          };
          if (!neighborhoodcollections.data.length) {
            neighborhoodcollections = { data: [], total: 0 };
          }
          setGridState({
            ...gridState,
            neighborhoodcollections,
            dataState: { take: 10, skip: 0 },
          });
        });
    }
  };

  // Handles Neighborhood Selections //
  const selectionChange = (e) => {
    const data = gridState.neighborhoodcollections.data.map((collection) => {
      if (collection === e.dataItem) {
        collection.selected = !e.dataItem.selected;
        if (collection.selected === true) {
          setSelectedStateArray((selectedRowsStateArray) => [
            ...selectedRowsStateArray,
            collection
          ]);
        } else {
          const filteredSelectedState = selectedRowsStateArray.filter(
            (selecteCheck) => selecteCheck.selected === true
          );
          setSelectedStateArray(filteredSelectedState);
        }
      }
      return collection;
    });
    const gridObjs = { ...gridState.neighborhoodcollections };
    gridObjs.data = data;
    store.dispatch(gridSelectedDataActions(e.dataItem));
    // go over data item nodes, add selected prop
    // update all nodes to reflect this
    const nodeIds = e.dataItem.nodeIds;
    nodeIds && store.dispatch(updateNodesByNeighborhood(nodeIds));
    setGridState({ ...gridState, neighborhoodcollections: gridObjs });
  };

  // Handles All Button and Row Click Actions
  const actionHandler = async (action, selectedCollections) => {
    if (action === 'edit' || action === 'doubleclick') {
      navigate(`/neighborhoods/edit`);
    }
    if (action === 'addNeighborhood') {
      store.dispatch(setNewNeighborhood());
      navigate('/neighborhoods/new');
    }
    if (action === 'delete') {
      // only handle single delete for now
      setShowModal(true);
    }
  };

  const deleteNeighborhoodHandler = async () => {
    if (neighborhoods.selectedData.length > 1) {
      const neighborhoodIds = neighborhoods.selectedData.map(neighborhood => neighborhood.id)
      await store.dispatch(deleteMultipleNeighborhoods(selectedProject, selectedBranch, neighborhoodIds));
      const newGridState = [...gridState.neighborhoodcollections.data]
      neighborhoods.selectedData.forEach((deletedNeighborhood) => {
        const neighborhoodIdx = newGridState.findIndex((neighborhood) => neighborhood.id === deletedNeighborhood.id)
        if (neighborhoodIdx > -1) {
          newGridState.splice(neighborhoodIdx, 1)
        }
      })
      setSelectedStateArray([])
      setGridState({
        ...gridState,
        neighborhoodcollections: {
          ...gridState.neighborhoodcollections,
          data: newGridState,
          total: newGridState.length
        }
      })
      return;
    }
    await store.dispatch(deleteNeighborhood(neighborhoods.selectedData[0]))
    const newGridState = [...gridState.neighborhoodcollections.data]
    const neighborhoodIdx = newGridState.findIndex((neighborhood) => neighborhood.id === neighborhoods.selectedData[0].id)
    if (neighborhoodIdx > -1) {
      setSelectedStateArray([])
      newGridState.splice(neighborhoodIdx, 1)
    }
    setGridState({
      ...gridState,
      neighborhoodcollections: {
        ...gridState.neighborhoodcollections,
        data: newGridState,
        total: newGridState.length
      }
    })
  }

  useEffect(() => {
    updateGridState();
  }, [selectedProject]);

  useEffect(() => {
    setLoading(true);
    updateGridState();
    setLoading(false);
    setSkipProcessing(page.skip);
  }, [filter, sort, page, selectedProject, selectedBranch]);

  return (
    <div
      style={{
        height: '61vh',
        margin: '16px',
        marginLeft: 'auto',
        marginRight: 'auto'
      }}
    >
      <DeleteConfirmation showModal={showModal} setShowModal={setShowModal} deleteFn={deleteNeighborhoodHandler}/>
      <KendoGridBase
        data={gridState.neighborhoodcollections.data || []}
        gridColumns={gridState.gridDynamicColumns}
        setGridFilters={handleGridFilterChange}
        setGridSort={handleGridSortChange}
        updateGridData={selectAllNeighbrohoods}
        onSelectionChange={selectionChange}
        onRowSingleClick={selectionChange}
        onPageChange={handlePageChange}
        sorter={sort}
        rowHeight={40}
        skip={page.skip}
        take={page.take}
        total={
          gridState.neighborhoodcollections
            ? gridState.neighborhoodcollections.total
            : 0
        }
        pageSize={100}
        selectable="selected"
        pageable={{
          pageSizes: [10, 25, 50, 75, 100],
          messages: {
            empty: 'no data to display'
          }
        }}
        groupable
        sortable
      />
      <div className="container-fluid p-0 d-flex justify-content-between mt-2">
        <div>
          <button
            className="btn btn-primary btn-sm me-2"
            type="button"
            onClick={() => {
              actionHandler('edit', neighborhoods.selectedData[0]);
            }}
            disabled={neighborhoods.selectedData.length !== 1}
          >
            <i className="bi bi-pencil me-2" />
            Edit
          </button>
          <button
            className="btn btn-success btn-sm text-white"
            type="button"
            onClick={() => {
              actionHandler('addNeighborhood', 'need function');
            }}
            disabled={userObj.selectedBranch[0].branchStatus === 'published'}
          >
            <i className="bi bi-plus me-2" />
            Add Neighborhood
          </button>
        </div>
        <div>
          <button
            className="btn btn-danger btn-sm text-white"
            type="button"
            onClick={() => {
              actionHandler('delete', selectedRowsStateArray);
            }}
            disabled={
              !selectedRowsStateArray.length ||
              userObj.selectedBranch[0].branchStatus === 'published'
            }
          >
            <i className="bi bi-trash me-2" />
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

NeighborhoodCollectionGrid.propTypes = {
  setRefresh: PropTypes.func,
  getNeighborhoods: PropTypes.func,
  getNeighborhoodNodeIds: PropTypes.func,
  gridSelectedDataActions: PropTypes.func,
  updateNodesByNeighborhood: PropTypes.func,
  setShow: PropTypes.func,
  selectAllNeighbrohoods: PropTypes.func,
  setNewNeighborhood: PropTypes.func,
  refresh: PropTypes.bool,
  headers: PropTypes.object
};

export default NeighborhoodCollectionGrid;
