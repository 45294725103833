/**
 * @summary ViewCollectionGrid.js
 * @file Grid component that renders all selected nodes, from the view, into the grid
 * @returns {JSX}
 * @usedBy ViewCollectionPage.js 
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import KendoGridBase from '../../../shared/ui/kendoGridBase/KendoGridBase';
import {
  activateView,
  deleteView,
  getViews,
  gridSelectedDataActions
} from '../../../store/views/ViewActions';
import store from '../../../store/store';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import DeleteConfirmation from '../modal/DeleteConfirmation';
import DeactivateModal from '../modal/DeactivateModal';

const ViewCollectionGrid = ({ setRefresh, refresh }) => {
  const [activeGridState, setActiveGridState] = useState({
    viewcollections: { data: [], total: 0 },
    dataState: { take: 100, skip: 1 },
    selectedViews: []
  });

  const [deactiveGridState, setDeactiveGridState] = useState({
    viewcollections: { data: [], total: 0 },
    dataState: { take: 100, skip: 1 },
    selectedViews: []
  });

  const [filter, setFilter] = useState({});
  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 100 });
  const [, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [actionType, setActionType] = useState('');
  const [, setSkipProcessing] = useState(0);

  const { userObj } = useSelector((state) => ({
    userObj: state.authReducer.userObj
  }));
  const selectedProject = useSelector(
    (state) => state.authReducer.userObj.selectedProject[0]
  );

  const handlePageChange = (e) => {
    e.page.skip = isNaN(e.page.skip) ? 1 : e.page.skip;
    setPage(e.page);
  };

  const handleGridFilterChange = (colFilter) => {
    setRefresh(true);
    setFilter(colFilter || {});
  };

  const handleGridSortChange = (obj) => {
    setRefresh(!refresh);
    // set initial sort order
    if (sort.length === 0 || sort[0].field !== obj[0].field) {
      setSort(obj);
      return;
    }

    // check if we should apply a desc, or reset sort completely
    if (sort[0].field === obj[0].field && sort[0].dir === 'asc') {
      sort[0].dir = 'desc';
      setSort(sort);
    } else if (sort[0].field === obj[0].field && sort[0].dir === 'desc') {
      setSort([]);
    }
  };

  const gridColumns = [
    {
      field: 'selected',
      show: true,
      filterable: false
    },
    {
      field: 'name',
      title: 'Name',
      show: true,
      filterable: true,
      filter: 'text',
      width: '400px'
    },
    {
      field: 'parentSource',
      title: 'Parent Source',
      show: true,
      filterable: true,
      filter: 'text',
      cellType: 'text',
      width: '500px'
    },
    {
      field: 'parentSourceType',
      title: 'Parent Source Type',
      show: true,
      filterable: true,
      filter: 'text',
      cellType: 'text',
      width: '300px'
    },
    {
      field: 'nodes',
      title: 'Node Count',
      show: true,
      filterable: true,
      filter: 'text',
      width: '200px'
    },
    {
      field: 'createdBy',
      title: 'Created By',
      show: true,
      filterable: true,
      filter: 'text',
      width: '400px'
    },
    {
      field: 'isActive',
      title: 'Is Active',
      show: true,
      filterable: true,
      filter: 'boolean',
      width: '200px'
    },
    {
      field: 'lastUpdated',
      title: 'Last Updated',
      show: true,
      filterable: true,
      filter: 'text',
      width: '200px'
    }
  ];
  // TODO: come back and add dynamic columns for the attributes

  const updateGridState = async () => {
    // wrapped in promise purpose: allow async actions to finish prior to populating state
    if (selectedProject && userObj.selectedBranch[0].id) {
      await store.dispatch(
        getViews(selectedProject.id, userObj.selectedBranch[0].id)
      );
      const views = { ...store.getState().viewReducer };
      delete views.selectedData;

      const [activeViewArr, deactivatedViewArr] = Object.values(views).reduce(
        ([pass, fail], view) => {
          return view.isActive
            ? [[...pass, view], fail]
            : [pass, [...fail, view]];
        },
        [[], []]
      );

      const activeViews = {
        data: activeViewArr,
        total: activeViewArr.length
      };

      const deactivatedViews = {
        data: deactivatedViewArr,
        total: deactivatedViewArr.length
      };

      setActiveGridState({
        ...activeGridState,
        viewcollections: activeViews,
        dataState: { take: 100, skip: 0 }
      });
      setDeactiveGridState({
        ...deactiveGridState,
        viewcollections: deactivatedViews,
        dataState: { take: 100, skip: 0 }
      });
    }
  };

  const selectionChange = (e) => {
    let newSelected = activeGridState.selectedViews;
    const data = activeGridState.viewcollections.data.map((collection) => {
      if (collection === e.dataItem) {
        collection.selected = !e.dataItem.selected;
        if (collection.selected === true) {
          newSelected.push(collection);
        } else {
          newSelected = newSelected.filter((obj) => obj.id !== collection.id)
        }
      }
      return collection;
    });

    const gridObjs = { ...activeGridState.viewcollections };
    gridObjs.data = data;
    gridSelectedDataActions(e.dataItem);
    setActiveGridState({ ...activeGridState, viewcollections: gridObjs, selectedViews: newSelected });
  };

  const deactivatedSelectionChange = (e) => {
    let newSelected = deactiveGridState.selectedViews;
    const data = deactiveGridState.viewcollections.data.map((collection) => {
      if (collection === e.dataItem) {
        collection.selected = !e.dataItem.selected;
        if (collection.selected === true) {
          newSelected.push(collection);
        } else {
          newSelected = newSelected.filter((obj) => obj.id !== collection.id)
        }
      }
      return collection;
    });

    const gridObjs = { ...deactiveGridState.viewcollections };
    gridObjs.data = data;
    gridSelectedDataActions(e.dataItem);
    setDeactiveGridState({ ...deactiveGridState, viewcollections: gridObjs, selectedViews: newSelected });
  };

  const handleActivate = () => {
    const copyDeactiveState = [...deactiveGridState.viewcollections.data];
    const copyActiveState = [...activeGridState.viewcollections.data];
   
    if (actionType === 'Activate') {
      const newDeactivatedState = copyDeactiveState.map((view) => {
        view.selected = false
        return view
      })
      deactiveGridState.selectedViews.forEach(async (view) => {
        await store.dispatch(activateView(selectedProject.id, view.id));
        const idx = deactiveGridState.viewcollections.data.findIndex(
          (viewObj) => viewObj.id === view.id
        );
        const viewToMove = newDeactivatedState.splice(idx, 1)[0];
        viewToMove.isActive = true;

        setDeactiveGridState({
          ...deactiveGridState,
          viewcollections: {
            data: newDeactivatedState,
            total: newDeactivatedState.length
          },
          selectedViews: []
        });

        setActiveGridState({
          ...activeGridState,
          viewcollections: {
            data: [...activeGridState.viewcollections.data, viewToMove],
            total: activeGridState.viewcollections.data.length + 1
          },
          selectedViews: []
        });
      });
      toast.success('View Activated');
    }
    if (actionType === 'Deactivate') {
      const newActivatedState = copyActiveState.map((view) => {
        view.selected = false
        return view;
      })
      activeGridState.selectedViews.forEach(async (view) => {
        await store.dispatch(deleteView(selectedProject.id, view.id));

        const idx = activeGridState.viewcollections.data.findIndex(
          (viewObj) => viewObj.id === view.id
        );
        const viewToMove = newActivatedState.splice(idx, 1)[0];
        viewToMove.isActive = false;

        setActiveGridState({
          ...activeGridState,
          viewcollections: {
            data: newActivatedState,
            total: newActivatedState.length
          },
          selectedViews: []
        });

        setDeactiveGridState({
          ...deactiveGridState,
          viewcollections: {
            data: [...deactiveGridState.viewcollections.data, viewToMove],
            total: deactiveGridState.viewcollections.data.length + 1
          },
          selectedViews: []
        });
      });
      toast.success('View Deactivated');
    }
  }
  // Handles All Button and Row Click Actions
  const actionHandler = (action, data) => {
    if (data[0]?.createdBy === userObj.emailAddress) {
      setActionType(action)
      setShowModal(true);
      return;
    }
    return toast.error('You do not own this view.')
  };

  useEffect(() => {
    updateGridState();
  }, [selectedProject]);

  useEffect(() => {
    setLoading(true);
    updateGridState();
    setLoading(false);
    setSkipProcessing(page.skip);
  }, [filter, sort, page]); // eslint-disable-line

  return (
    <>
      <div
        style={{
          height: 'auto',
          margin: '16px',
          marginLeft: 'auto',
          marginRight: 'auto',
          padding: '2%'
        }}
      >
        <DeactivateModal showModal={showModal} setShowModal={setShowModal} deleteFn={handleActivate} actionType={actionType} />
        <h2>Active Views</h2>
        <KendoGridBase
          data={activeGridState.viewcollections.data || []}
          gridColumns={gridColumns}
          setGridFilters={handleGridFilterChange}
          setGridSort={handleGridSortChange}
          onSelectionChange={selectionChange}
          onRowSingleClick={selectionChange}
          onPageChange={handlePageChange}
          updateGridData={gridSelectedDataActions}
          sorter={sort}
          rowHeight={60}
          skip={page.skip}
          take={page.take}
          total={
            activeGridState.viewcollections
              ? activeGridState.viewcollections.total
              : 0
          }
          pageSize
          selectable="selected"
          pageable={{
            pageSizes: [10, 25, 50, 75, 100],
            messages: {
              empty: 'no data to display'
            }
          }}
          groupable
          sortable
        />
        <div className="container-fluid p-0 d-flex justify-content-between mt-2">
          <div></div>
          <div>
            <button
              className="btn btn-danger btn-sm text-white"
              type="button"
              onClick={() => {
                actionHandler('Deactivate', activeGridState.selectedViews);
              }}
              disabled={
                !activeGridState.selectedViews.length || activeGridState.selectedViews.length > 1
              }
            >
              <i className="bi bi-trash me-2" />
              Deactivate
            </button>
          </div>
        </div>
      </div>

      <div
        style={{
          height: 'auto',
          margin: '16px',
          marginLeft: 'auto',
          marginRight: 'auto',
          padding: '2%'
        }}
      >
        <h2>Inactive Views</h2>
        <KendoGridBase
          data={deactiveGridState.viewcollections.data || []}
          gridColumns={gridColumns}
          setGridFilters={handleGridFilterChange}
          setGridSort={handleGridSortChange}
          onSelectionChange={deactivatedSelectionChange}
          onRowSingleClick={deactivatedSelectionChange}
          onPageChange={handlePageChange}
          updateGridData={gridSelectedDataActions}
          sorter={sort}
          rowHeight={60}
          skip={page.skip}
          take={page.take}
          total={
            deactiveGridState.viewcollections
              ? deactiveGridState.viewcollections.total
              : 0
          }
          pageSize
          selectable="selected"
          pageable={{
            pageSizes: [10, 25, 50, 75, 100],
            messages: {
              empty: 'no data to display'
            }
          }}
          groupable
          sortable
        />
        <div className="container-fluid p-0 d-flex justify-content-between mt-2">
          <div></div>
          <div>
            <button
              className="btn btn-success btn-sm text-white"
              type="button"
              onClick={() => {
                actionHandler('Activate', deactiveGridState.selectedViews);
              }}
              disabled={
                !deactiveGridState.selectedViews.length ||
                deactiveGridState.selectedViews.length > 1
              }
            >
              <i className="bi bi-arrow-counterclockwise me-2" />
              Activate
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

ViewCollectionGrid.propTypes = {
  setRefresh: PropTypes.func,
  getViews: PropTypes.func,
  refresh: PropTypes.bool
};

export default ViewCollectionGrid;
