/**
 * @summary NodeCollectionPage.js
 * @file Wrapper component for the node grid on tableview page
 * @returns {JSX}
 * @usedBy tableviewLayout.js
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState } from 'react';
import NodeCollectionGrid from './NodeCollectionGrid';
import PropTypes from 'prop-types';

// headers come from API call in tableviewLayout.js for attribute columns, and setShow handles showing branch modal
const NodeCollectionPage = ({ headers }) => {
  // state for triggering re-renders if required
  const [refresh, setRefresh] = useState(false);
  return (
    <div>
      <NodeCollectionGrid
        setRefresh={setRefresh}
        refresh={refresh}
        headers={headers}
      />
    </div>
  );
};

NodeCollectionPage.propTypes = {
  setShow: PropTypes.func,
  headers: PropTypes.object
};

export default NodeCollectionPage;
