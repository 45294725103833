/**
 * @summary Node Reducer
 * @file NodeReducer.js
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import {
  CLEAR_NODES,
  CREATE_NODE,
  EDIT_NODES,
  GET_NODES,
  GRID_DATA_ACTIONS_SELECTIONS_NODES_FULLFILLED,
  GET_NODE,
  UPDATE_NODES_BY_NEIGHBORHOOD,
  ADD_NODE_MEDIA,
  SET_NEW_NODE,
  UPDATE_COMMENT,
  CLEAR_SELECTED_NODES,
  ADD_COMMENT,
  DELETE_COMMENT,
  DELETE_NODE,
  DELETE_MULTIPLE_NODES
} from './NodeTypes';

const nodes = {
  selectedData: []
};

/**
 * @summary
 * This redux reducer is used for node CRUD based on the action type
 *
 * @param {nodes} state
 * @param {reduxAction} action
 */
let copyNode;
let copyComments;
let commentIdx;

const nodeReducer = (state = nodes, action) => {
  switch (action.type) {
    case GET_NODES:
      return {
        selectedData: [],
        // keep all incoming nodes inside a single level
        ...action.nodes.reduce((newObj, node) => {
          newObj[node.id] = node;
          return newObj;
        }, {})
      };

    case GET_NODE:
      return {
        ...state,
        selectedData: action.selectedData
      };

    case CREATE_NODE:
      // block to update correct node properties
      const updatedSelectedData = [...state.selectedData];
      const idx = updatedSelectedData.findIndex((node) => node.id === -1);
      const nodeToUpdate = updatedSelectedData[idx];
      nodeToUpdate.id = action.meta.id;
      nodeToUpdate.name = action.meta.name;
      nodeToUpdate.nodeKey = action.meta.nodeKey;
      nodeToUpdate.lastUpdated = action.meta.lastUpdated;

      return {
        ...state,
        [action.meta.id]: {
          ...action.node,
          nodeKey: action.node.nodeKey,
          id: action.meta.id
        },
        selectedData: [action.meta]
      };

    case EDIT_NODES:
      return {
        ...state,
        [action.meta.id]: {
          ...state[action.meta.id],
          ...action.node,
          id: action.meta.id
        }
      };

    case GRID_DATA_ACTIONS_SELECTIONS_NODES_FULLFILLED:
      let copySelectedData = [...state.selectedData];
      const isArr = Array.isArray(action.node.data);
      if (isArr && action.node.data[0]?.selected) {
        copySelectedData = action.node.data;
      } else if (!isArr) {
        const idx = copySelectedData.findIndex(
          (node) => node.id === action.node.data.id
        );
        idx < 0
          ? copySelectedData.push(action.node.data)
          : copySelectedData.splice(idx, 1);
      } else {
        copySelectedData = [];
      }

      return {
        ...state,
        selectedData: copySelectedData
      };

    case CLEAR_NODES:
      return {
        selectedData: []
      };

    case UPDATE_NODES_BY_NEIGHBORHOOD:
      let newNodes = { ...state };
      let newSelection = [...state.selectedData];
      action.nodeIds.map((id) => {
        if (!newNodes[id].checkedOutBy) {
          newNodes[id] = {
            ...newNodes[id],
            selected: !newNodes[id].selected
          };
          const index = newSelection.findIndex((node) => node.id === id);
          if (index < 0) {
            newSelection.push(newNodes[id]);
          } else {
            newSelection.splice(index, 1);
          }
        }
      });

      return {
        ...state,
        ...newNodes,
        selectedData: newSelection
      };

    case ADD_NODE_MEDIA:
      const updatedNode = { ...state.selectedData[0] };
      updatedNode.nodeMedia.push(action.media);
      return {
        ...state,
        selectedData: [updatedNode]
      };

    case SET_NEW_NODE:
      return {
        ...state,
        selectedData: [
          {
            id: -1,
            name: '',
            tags: [],
            source: '',
            status: 'Draft',
            nodeKey: '',
            nodeMediaIds: [],
            nodeMedia: [],
            description: '',
            nodeSettings: [],
            selectedValue: null,
            lastUpdated: null
          }
        ]
      };

    case CLEAR_SELECTED_NODES:
      return {
        ...state,
        selectedData: []
      };

    case ADD_COMMENT:
      copyNode = { ...state[action.comment.nodeId] };
      copyComments = [...copyNode.comments];
      copyComments.push(action.comment);
      copyNode.comments = copyComments;
      return {
        ...state,
        [action.comment.nodeId]: {
          ...copyNode
        }
      };

    case UPDATE_COMMENT:
      copyNode = { ...state[action.comment.nodeId] };
      copyComments = [...copyNode.comments];
      commentIdx = copyComments.findIndex(
        (comment) => comment.id === action.commentId
      );
      if (commentIdx > -1) {
        copyComments.splice(commentIdx, 1, {
          ...action.comment,
          id: action.commentId
        });
        copyNode.comments = copyComments;
      }
      return {
        ...state,
        [action.comment.nodeId]: {
          ...copyNode
        }
      };

    case DELETE_COMMENT:
      copyNode = { ...state[action.comment.nodeId] };
      copyComments = [...copyNode.comments];
      commentIdx = copyComments.findIndex(
        (comment) => comment.id === action.commentId
      );
      if (commentIdx > -1) {
        copyComments.splice(commentIdx, 1);
        copyNode.comments = copyComments;
      }
      return {
        ...state,
        [action.comment.nodeId]: {
          ...copyNode
        }
      };

    case DELETE_NODE:
      copyNode = { ...state };
      delete copyNode[action.node.id];
      return {
        ...copyNode,
        selectedData: []
      };

    case DELETE_MULTIPLE_NODES:
      copyNode = { ...state };
      action.nodeIds.forEach((id) => {
        delete copyNode[id];
      });
      return {
        ...copyNode,
        selectedData: []
      };

    default:
      return state;
  }
};
export default nodeReducer;
