/**
 * @summary NodeConnectionSettings.js
 * @file connection settings in new/edit node form
 * @returns {JSX}
 * @usedBy NodeForm.js
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import KendoGridBase from '../../../shared/ui/kendoGridBase/KendoGridBase';
import {
  getConnections,
  gridSelectedDataActions
} from 'store/connections/ConnectionActions';
import store from '../../../store/store';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import NewConnectionModal from '../modal/newConnectionModal';
import { formatDateTime } from 'utility/formatDateTime';

const ConnectionsGrid = ({ setRefresh }) => {
  const [gridState, setGridState] = useState({
    connections: { data: [], total: 0 },
    dataState: { take: 20, skip: 1 },
    gridDynamicColumns: [
      {
        field: 'selected',
        show: true,
        filterable: false
      },
      {
        field: 'relationship',
        title: 'Relationship',
        show: true,
        filterable: false,
        filter: 'text',
        width: '200px'
      },
      {
        field: 'nodeKey',
        title: 'Node ID',
        show: true,
        filterable: false,
        filter: 'numeric',
        width: '200px'
      },
      {
        field: 'nodeName',
        title: 'Node Name',
        show: true,
        filterable: false,
        filter: 'text',
        width: '200px'
      },
      {
        field: 'connectionType',
        title: 'Connection Type',
        show: true,
        filterable: false,
        filter: 'text',
        width: '200px'
      },
      {
        field: 'lastUpdated',
        title: 'Last Updated',
        show: true,
        filterable: false,
        filter: 'text',
        width: '200px'
      },
      {
        field: 'Created By',
        show: true,
        filterable: false,
        filter: 'text',
        width: '200px'
      }
    ]
  });
  const [filter, setFilter] = useState({});
  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 100 });
  const [, setLoading] = useState(false);
  const [, setSkipProcessing] = useState(0);
  const [selectedRowsStateArray, setSelectedStateArray] = useState([]);
  const [show, setShow] = useState(false);

  const messageOptions = {
    messsageType: {
      success: 'Success'
    },
    messages: {
      successOnDelete: 'Form Deleted',
      errorOnDelete: 'Unable to Delete Connection',
      errorOnFetch: 'Unable to Load Connections',
      underConstruction: 'This Function is Under Construction'
    }
  };

  const { userObj, node, selectedProject } = useSelector((state) => ({
    userObj: state.authReducer.userObj,
    node: state.nodeReducer.selectedData[0],
    selectedProject: state.authReducer.userObj.selectedProject[0]
  }));

  const canEdit = node?.checkedOutBy === userObj.screenName;

  const handlePageChange = (e) => {
    e.page.skip = isNaN(e.page.skip) ? 1 : e.page.skip;
    setPage(e.page);
  };

  const handleGridFilterChange = (colFilter) => {
    setRefresh(true);
    setFilter(colFilter || {});
  };

  const handleGridSortChange = (obj) => {
    setRefresh(true);
    setSort(obj);
  };

  const gridColumns = [
    {
      field: 'selected',
      show: true,
      filterable: false
    },
    {
      field: 'relationship',
      title: 'Relationship',
      show: true,
      filterable: false,
      filter: 'text',
      width: '200px'
    },
    {
      field: 'nodeKey',
      title: 'Node ID',
      show: true,
      filterable: false,
      filter: 'numeric',
      width: '200px'
    },
    {
      field: 'nodeName',
      title: 'Node Name',
      show: true,
      filterable: false,
      filter: 'text',
      width: '200px'
    },
    {
      field: 'connectionType',
      title: 'Connection Type',
      show: true,
      filterable: false,
      filter: 'text',
      width: '200px'
    },
    {
      field: 'lastUpdated',
      title: 'Last Updated',
      show: true,
      filterable: false,
      filter: 'text',
      width: '200px'
    },
    {
      field: 'createdUserName',
      title: 'Created By',
      show: true,
      filterable: false,
      filter: 'text',
      width: '200px'
    }
  ];

  const updateGridState = () => {
    // wrapped in promise purpose: allow async actions to finish prior to populating state
    if (selectedProject && userObj.selectedBranch[0].id) {
      store
        .dispatch(
          getConnections(selectedProject.id, userObj.selectedBranch[0].id)
        )
        .then(() => {
          const connectionState = { ...store.getState().connectionReducer };
          delete connectionState.selectedData;
          const gridDynamicColumnsArr = [];
          const connections = {
            data: Object.values(connectionState).filter(Boolean),
            total: Object.values(connectionState).length
          };
          if (connections.data.length > 0) {
            //filters connections to match selected node name
            connections.data = connections.data.filter(
              (connection) =>
                connection.sourceNodeKey === node?.nodeKey ||
                connection.destinationNodeKey === node?.nodeKey
            );
            //reorganizes filtered connections into correct columns
            const sortedConnections = connections.data.map((x) => {
              const container = {};
              if (x.sourceNodeKey === node?.nodeKey) {
                container.nodeName = x.destinationNodeName;
                container.nodeKey = x.destinationNodeKey;
                container.connectionType = x.connectionType;
                container.createdUserName = x.createdUserName;
                container.lastUpdated = formatDateTime(new Date(x.lastUpdated));
                container.relationship = 'Destination';
                container.name = x.name;
                container.settings = {};
              }
              if (x.destinationNodeKey === node?.nodeKey) {
                container.nodeName = x.sourceNodeName;
                container.nodeKey = x.sourceNodeKey;
                container.connectionType = x.connectionType;
                container.createdUserName = x.createdUserName;
                container.lastUpdated = formatDateTime(new Date(x.lastUpdated));
                container.name = x.name;
                container.relationship = 'Origin';
                container.settings = {};
              }
              return container;
            });
            connections.data = sortedConnections;

            connections.data.forEach((item) => {
              Object.keys(item.settings).forEach((key) => {
                item[key] = item.settings[key];
              });
            });

            if (connections.data[0]) {
              Object.keys(connections.data[0].settings).forEach((key) => {
                gridDynamicColumnsArr.push({
                  field: key,
                  title: key,
                  show: true,
                  filterable: false,
                  filter: 'text',
                  width: '200px'
                });
              });
            }
            const gridDynamicColumns = [
              ...gridColumns,
              ...gridDynamicColumnsArr
            ];
            connections.total = connections.data.length;
            setGridState({
              ...gridState,
              connections,
              dataState: { take: 20, skip: 0 },
              gridDynamicColumns
            });
          }
        });
    }
  };

  // When a Row is Selected...
  const selectionChange = (e) => {
    const data = gridState.connections.data.map((collection) => {
      // Adds Selected Data to an Array to Control Button Visibility
      if (collection === e.dataItem) {
        collection.selected = !e.dataItem.selected;
        if (collection.selected === true) {
          setSelectedStateArray((selectedRowsStateArray) => [
            ...selectedRowsStateArray,
            collection
          ]);
        } else {
          const filteredSelectedState = selectedRowsStateArray.filter(
            (selecteCheck) => selecteCheck.selected === true
          );
          setSelectedStateArray(filteredSelectedState);
        }
      }
      return collection;
    });

    const gridObjs = { ...gridState.connections };
    gridObjs.data = data;
    store.dispatch(gridSelectedDataActions(e.dataItem));
    setGridState({ ...gridState, connections: gridObjs });
  };

  // Handles All Button and Row Click Actions
  const actionHandler = (action, selectedCollections) => {
    if (action === 'addConnection' && canEdit) {
      setShow(!show);
      return;
    }
    if (action === 'delete') {
      return toast.success(messageOptions.messages.underConstruction);
    }
    return toast.error(`Node not checked out by ${userObj.screenName}`);
  };
  useEffect(() => {
    updateGridState();
  }, [selectedProject]);

  useEffect(() => {
    updateGridState();
  }, []);

  useEffect(() => {
    setLoading(true);
    setLoading(false);
    setSkipProcessing(page.skip);
  }, [filter, sort, page, selectedProject]); // eslint-disable-line

  return (
    <div className="container-fluid">
      <NewConnectionModal show={show} setShow={setShow} />
      <div className="row">
        <div
          style={{
            margin: '16px',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <KendoGridBase
            data={gridState.connections.data || []}
            gridColumns={gridState.gridDynamicColumns}
            setGridFilters={handleGridFilterChange}
            setGridSort={handleGridSortChange}
            updateGridData={gridSelectedDataActions}
            onSelectionChange={selectionChange}
            onRowSingleClick={selectionChange}
            onPageChange={handlePageChange}
            sorter={sort}
            rowHeight={40}
            skip={page.skip}
            take={page.take}
            total={gridState.connections ? gridState.connections.total : 0}
            pageSize={100}
            selectable="selected"
            pageable
            sortable
            filterable
          />
          <div className="container-fluid p-0 d-flex justify-content-between mt-2">
            <div>
              {node?.id > 0 && (
                <button
                  className="btn btn-success btn-sm text-white"
                  type="button"
                  onClick={() => {
                    actionHandler('addConnection', '(need function)');
                  }}
                >
                  <i className="bi bi-plus me-2" />
                  Add Connection
                </button>
              )}
            </div>
            <div>
              <button
                className="btn btn-danger btn-sm text-white"
                type="button"
                onClick={() => {
                  actionHandler('delete', selectedRowsStateArray);
                }}
                disabled={!selectedRowsStateArray.length}
              >
                <i className="bi bi-trash me-2" />
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
ConnectionsGrid.propTypes = {
  setRefresh: PropTypes.func,
  getConnections: PropTypes.func,
  gridSelectedDataActions: PropTypes.func
};

export default ConnectionsGrid;
