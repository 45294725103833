/**
 * @summary DeleteConfirmation.jsx
 * @file Modal that asks for user confirmation on deletion. Once confirmed, the modal will run the deleteFn that was passed down by the parent component
 * @returns {JSX}
 * @usedBy ConnectionCollectionGrid.js, ConnectionFormHeader.js, NeighborhoodCollectionGrid.js, NeighborhoodFormHeader.js, NeighborhoodNodesSettings, NodeCollectionGrid.js, NodeFormHeader.js, ViewCollectionGrid.js
 * @author Sam Lee
 * @since 2/17/2023
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import PropTypes from 'prop-types';

const DeleteConfirmation = ({ showModal, setShowModal, deleteFn }) => {
  return (
    <div>
      {showModal && (
      <Dialog title="Please confirm" onClose={() => setShowModal(false)}>
        <p style={{ margin: '25px', textAlign: 'center' }}>
          Are you sure you want to delete?
        </p>
        <DialogActionsBar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setShowModal(false)}
          >
            No
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => {
              deleteFn()
              setShowModal(false)
            }}
          >
            <i className="bi bi-trash me-2" />
            Yes
          </button>
        </DialogActionsBar>
      </Dialog>
    )}
    </div>
  )
}

export default DeleteConfirmation;

DeleteConfirmation.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  deleteFn: PropTypes.func,
}