/**
 * @summary tableviewLayout.js
 * @file Provides a table layout for the node, connection, and neighborhood collection pages
 * @returns {JSX}
 * @usedBy routes.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import NodeCollectionPage from 'pages/project/nodes/NodeCollectionPage';
import ConnectionCollectionPage from 'pages/project/connections/ConnectionCollectionPage';
import NeighborhoodCollectionPage from 'pages/project/neighborhoods/NeighborhoodCollectionPage';
import BranchModal from 'pages/project/modal/branchModal';
import { updateUserTab } from 'store/auth/AuthActions';
import store from 'store/store';
import axios from 'axios';

const TableViewLayout = () => {
  const [show, setShow] = useState(false);
  const [headers, setHeaders] = useState({})

  const { selectedProject, selectedBranch } = useSelector((state) => ({
    selectedBranch: state.authReducer.userObj.selectedBranch[0],
    selectedProject: state.authReducer.userObj.selectedProject[0]
  }));

  const userTab = useSelector((state) => state.authReducer.userTab);

  const getHeaders = async () => {
    const resp = await axios.get(`/api/${selectedProject.id}/branch/${selectedBranch.id}/getTableHeaders`)
    setHeaders(resp.data)
  }
  
  useEffect(() => {
    getHeaders()
  }, [selectedProject, selectedBranch])

  return (
    <div className="container-fluid tableview-tabs">
      <BranchModal show={show} setShow={setShow} />
      <div className="d-flex flex-row-reverse">
        <button
          className="btn btn-dark btn-sm text-white me-2 d-flex"
          type="button"
          onClick={() => {
            setShow(true);
          }}
          disabled={selectedBranch.branchStatus !== 'published'}
        >
          <i className="bi bi-plus me-2" />
          Create Branch
        </button>
      </div>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${userTab === 'nodes' ? 'active' : null}`}
            id="node-tab"
            data-bs-toggle="tab"
            data-bs-target="#nodes"
            type="button"
            role="tab"
            onClick={() => {
              store.dispatch(updateUserTab('nodes'));
            }}
            aria-controls="nodes"
            aria-selected="true"
          >
            Nodes
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${
              userTab === 'connections' ? 'active' : null
            }`}
            id="connections-tab"
            data-bs-toggle="tab"
            data-bs-target="#connections"
            type="button"
            role="tab"
            onClick={() => {
              store.dispatch(updateUserTab('connections'));
            }}
            aria-controls="profile"
            aria-selected="false"
          >
            Connections
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${
              userTab === 'neighborhoods' ? 'active' : null
            }`}
            id="neighborhoods-tab"
            data-bs-toggle="tab"
            data-bs-target="#neighborhoods"
            type="button"
            role="tab"
            onClick={() => {
              store.dispatch(updateUserTab('neighborhoods'));
            }}
            aria-controls="neighborhoods"
            aria-selected="false"
          >
            Neighborhoods
          </button>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          className={`tab-pane fade show ${
            userTab === 'nodes' ? 'active' : null
          }`}
          id="nodes"
          role="tabpanel"
          aria-labelledby="node-tab"
        >
          <NodeCollectionPage setShow={setShow} headers={headers.nodes} />
        </div>
        <div
          className={`tab-pane fade show ${
            userTab === 'connections' ? 'active' : null
          }`}
          id="connections"
          role="tabpanel"
          aria-labelledby="connections-tab"
        >
          <ConnectionCollectionPage headers={headers.connections} />
        </div>
        <div
          className={`tab-pane fade show ${
            userTab === 'neighborhoods' ? 'active' : null
          }`}
          id="neighborhoods"
          role="tabpanel"
          aria-labelledby="neighborhoods-tab"
        >
          <NeighborhoodCollectionPage headers={headers.neighborhoods} />
        </div>
      </div>
    </div>
  );
};

export default TableViewLayout;
