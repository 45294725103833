/**
 * @summary MergeModal.jsx
 * @file Modal component that handles merging logic based on user's action
 * @returns {JSX}
 * @usedBy BranchListGrid.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useEffect } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ProgressBar } from "@progress/kendo-react-progressbars";
import PropTypes from 'prop-types';


const MergeConfirmation = ({ showModal, setShowModal, mergeFn, modalType, deactivateFn, isLoading, useInterval }) => {
  const { value, start, stop, reset } = useInterval(50);
  
  if (isLoading) {
    start();
  }
  if (showModal) {
    return (
      <div>
        {!isLoading ? (
        <Dialog title="Please confirm" onClose={() => setShowModal(false)}>
          <p style={{ margin: '25px', textAlign: 'center' }}>
            Are you sure you want to {modalType}?
          </p>
          <DialogActionsBar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setShowModal(false)}
            >
              No
            </button>
            <button
              type="button"
              className={modalType === 'deactivate' ? ("btn btn-danger") : ("btn btn-primary")}
              onClick={() => {
                reset();
                if (modalType === 'deactivate') {
                deactivateFn()
                } else {
                mergeFn();
                }
              }}
            >
              {modalType === 'deactivate' ? (<i className="bi bi-file-minus me-2" />) : (<i className="bi bi-file-plus me-2" />)}
              Yes
            </button>
          </DialogActionsBar>
        </Dialog>
      ) : (
        <>
          <Dialog title="Merging" onClose={() => setShowModal(false)}>
          <p style={{ margin: '25px', textAlign: 'center' }}>
            Merge Compiling
          </p>
          <ProgressBar
            max={99}
            value={value}
          />
        </Dialog>
      </>
      )}
      </div>
    )
  }
  return null;
}

export default MergeConfirmation;

MergeConfirmation.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  mergeFn: PropTypes.func,
  modalType: PropTypes.string,
  deactivateFn: PropTypes.func,
  isLoading: PropTypes.bool,
  useInterval: PropTypes.func,
}