/**
 * @summary ForgotPW.jsx
 * @file Page component that helps users recover their password
 * @returns {JSX}
 * @usedBy routes.js
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';
import { ForgotPassword } from 'ats-react-login';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

export default function ForgotPw() {
  const navigate = useNavigate();

  return (
    <ForgotPassword
      logo={process.env.PUBLIC_URL + '/kite-logo.svg'}
      background={`${process.env.PUBLIC_URL}/Kite_Login_BG.svg`}
      onSubmit={(event, email) => {
        event.preventDefault();
        axios
          .post('/api/requestPasswordReset', {
            email
          })
          .then((res) => {
            const statusCode = res.status;
            if (statusCode === 200) {
              // redirect to the reset password page
              // add the email to the location for access on the reset password page
              navigate({
                pathname: '/resetPw',
                data: { email }
              });
            } else {
              dispatch(
                forgotPasswordError(
                  'Forgot Password recieved status other than 200 but was not caught.'
                )
              );
              navigate('/forgotPassword');
            }
          })
          .catch((error) => {
            toast('Check your email for reset validation code.');
            navigate({ pathname: '/resetpw', data: { email: email } });
          });
      }}
    />
  );
}
