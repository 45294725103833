/**
 * @summary NeighborhoodAttributeSettings.js
 * @file allows for changes in the neighborhood's attributes
 * @returns {JSX}
 * @usedBy NeighborhoodForm.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useEffect, useRef } from 'react';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import {
  getNeighborhood,
  getNeighborhoodSettings
} from 'store/neighborhoods/NeighborhoodActions';
import store from '../../../store/store';
import PropTypes from 'prop-types';
import { normalizeName } from 'utility/updateFields';
import { renderHTMLElement } from '../utils/renderHTML';
import {
  clearContentCodes,
  loadContentCodes
} from 'store/metadata/MetadataAction';

const NeighborhoodAttributeSettings = ({
  userObj,
  neighborhood,
  neighborhoodDetails,
  setNeighborhoodDetails,
  contentMetadata,
  setContentMetadata,
  taxonomiesDimensions,
  setTaxonomiesDimensions,
  attrsLoaded,
  setAttrsLoaded
}) => {
  const updateValues = (e, key) => {
    const { value } = e;
    getUpdatedValues(value, key);
  };
  
  const didFetchRef = useRef(null);

  //can be refactored into util fn
  const setPossibleValueObject = (data, metaDataId, selectedData, name) => {
    if (name !== 'content_codes') {
      if (metaDataId.length > 0) {
        let output = metaDataId.map((id, value) => ({ id, name: data[value] }));
        return output;
      } else {
        const neighborhoodValue = (val) => {
          const foundVal = selectedData.find((obj) => obj.val === val);
          return foundVal?.valueId || undefined;
        };

        return data.map((val, idx) => {
          return {
            id: idx,
            neighborhoodValueId:
              selectedData.length > 0 ? neighborhoodValue(val) : undefined,
            name: val
          };
        });
      }
    } else {
      const selectedContentCodes =
        store.getState().metadataReducer.selectedContentCodes;
      if (selectedContentCodes && selectedContentCodes.length > 0) {
        const CCArray = selectedContentCodes[0].map((element) => ({
          id: element.id,
          name: element.content_code
        }));
        if (CCArray.length > 0) {
          return CCArray;
        } else {
          const neighborhoodValue = (val) => {
            const foundVal = selectedData.find((obj) => obj.val === val);
            return foundVal?.valueId || undefined;
          };
          return selectedContentCodes[0].map((val, idx) => {
            return {
              id: idx,
              neighborhoodValueId:
                selectedData.length > 0 ? neighborhoodValue(val) : undefined,
              name: val
            };
          });
        }
      }
    }
  };

  //can be refactored into util fn
  const applySettingToState = (setting, updatedList) => {
    //need a check for the format of the content code bs
    setting.possibleValue = setPossibleValueObject(
      setting.value,
      setting.metaDataId,
      setting.selectedValue,
      setting.name
    );

    if (setting.category === 'Neighborhood Details') {
      setNeighborhoodDetails((neighborhoodDetails) => [
        ...neighborhoodDetails,
        setting
      ]);
    }
    if (setting.category === 'Content Metadata') {
      if (setting.name === 'content_codes') {
        if (setting.possibleValue) {
          setting.possibleValue.forEach((e) => {
            setting.metaDataId.push(e.id);
          });
        }
        if (updatedList) {
          const oldSelectedCC = updatedList.filter(
            (element) => element.name === 'content_codes'
          );
          if (oldSelectedCC.length >= 1) {
            const oldData = updatedList;
            const pos = oldData.map((e) => e.name).indexOf('content_codes');
            oldData.splice(pos, 1);
            setContentMetadata(oldData);
          }
        }
        if (!setting.possibleValue) {
          return;
        } else {
          setting.possibleValue.forEach((element) => {
            setting.value = [element.name];
          });
        }
      }
      setContentMetadata((contentMetadata) => [...contentMetadata, setting]);
    }
    if (setting.category === 'Taxonomies and Dimensions') {
      setTaxonomiesDimensions((taxonomiesDimensions) => [
        ...taxonomiesDimensions,
        setting
      ]);
    }
    if (setting.category === 'Accessibility Flags') {
      setAccessibilityFlags((accessibilityFlags) => [
        ...accessibilityFlags,
        setting
      ]);
    }
  };

  //can be refactored into util fn
  const setAttributeSelectableValues = (settingsData) => {
    settingsData.forEach((setting) => {
      applySettingToState(setting);
    });
  };

  //can be refactored into util fn
  const setExistingNeighborhoodSettings = (neighborhoodData) => {
    const neighborhoodValues = neighborhoodData.settings.map((setting) => {
      setting.selectedValue = [];
      if (neighborhoodData.selectedValue) {
        const foundSelection = neighborhoodData.selectedValue?.filter(
          (value) => setting.attributeId === value.attrId
        );
        if (foundSelection) {
          setting.selectedValue = foundSelection?.map((value, idx) => {
            const attr = value.values[0];
            return({
            id:
              attr.selectedId ||
              setting.value.indexOf(attr.selectedValue),
            neighborhoodValueId: attr.valueId,
            name: attr.selectedValue ? attr.selectedValue : '',
            neighborhoodAttrId: foundSelection[idx].neighborhoodAttrId
          })
        })
        }
      }
      return setting;
    });
    setAttributeSelectableValues(neighborhoodValues);
  };

  //can be refactored into util fn
  const setBaseSelections = (neighborhoodData) => {
    const neighborhoodValues = neighborhoodData.map((setting) => ({
      ...setting,
      selectedValue: []
    }));
    setAttributeSelectableValues(neighborhoodValues);
  };

  //can be refactored into util fn
  const getAttributeSettings = async () => {
    if (neighborhood.id < 1) {
      store.dispatch(clearContentCodes());
      const NeighSettings = await store.dispatch(
        getNeighborhoodSettings(
          userObj.selectedProject[0].id,
          userObj.selectedBranch[0].id
        )
      );
      for (const element of NeighSettings) {
        if (element.name === 'content_codes') {
          applySettingToState(element);
        }
      }
      if (!attrsLoaded) {
        setAttrsLoaded(true);
        setBaseSelections(NeighSettings);
      }
    } else {
      // editing a neighborhood
      store.dispatch(clearContentCodes());
      const getSelectedNeighborhood = await store.dispatch(
        getNeighborhood(
          userObj.selectedProject[0].id,
          userObj.selectedBranch[0].id,
          neighborhood?.id
        )
      );
      if (!attrsLoaded) {
        setAttrsLoaded(true);
        const neighborhoodData = await store.getState().neighborhoodReducer
          .selectedData[0];
        const foundSubject = await neighborhoodData.settings.find(
          (element) => element.name === 'subject'
        );
        if (neighborhoodData.selectedValue && foundSubject) {
          const foundSubjectAttr = await neighborhoodData.selectedValue.find(
            (element) => element.attrId === foundSubject.attributeId
          );
          const subjectId = foundSubjectAttr?.values[0]?.selectedId;
          if (subjectId) {
            await store.dispatch(
              loadContentCodes(
                subjectId,
                userObj.selectedProject[0]?.id,
                userObj.selectedBranch[0]?.id,
                'neighborhood'
              )
            );
          }
        }
        setExistingNeighborhoodSettings(neighborhoodData);
      }
    }
  };

  const getUpdatedValues = (values, key) => {
    if (key.category === 'Neighborhood Details') {
      if (key.dataType === 'Text') {
        let updatedList = neighborhoodDetails.map((item) => {
          if (item.name === key.name) {
            const newObj = { name: values };
            return { ...item, selectedValue: [newObj] };
          }
          return item;
        });
        setNeighborhoodDetails(updatedList);
      }
      if (key.dataType === 'Drop-Down') {
        let updatedList = neighborhoodDetails.map((item) => {
          if (item.name === key.name) {
            const value = parseInt(values);
            const newSelectedValue = item.possibleValue.find(
              (element) => element.id === value
            );
            return { ...item, selectedValue: [newSelectedValue] };
          }
          return item;
        });
        setNeighborhoodDetails(updatedList);
      }
      if (key.dataType === 'Multi-Select') {
        let updatedList = neighborhoodDetails.map((item) => {
          if (item.name === key.name) {
            return { ...item, selectedValue: values };
          }
          return item;
        });
        setNeighborhoodDetails(updatedList);
      }
    }
    if (key.category === 'Content Metadata') {
      if (key.dataType === 'Text') {
        let updatedList = neighborhoodDetails.map((item) => {
          if (item.name === key.name) {
            const newObj = { name: values };
            return { ...item, selectedValue: [newObj] };
          }
          return item;
        });
        setContentMetadata(updatedList);
      }
      if (key.dataType === 'Drop-Down') {
        if (key.name === 'subject') {
          let updatedList = contentMetadata.map((item) => {
            if (item.name === key.name) {
              const value = parseInt(values);
              const newSelectedValue = item.possibleValue.find(
                (element) => element.id === value
              );
              return { ...item, selectedValue: [newSelectedValue] };
            }
            return item;
          });
          setContentMetadata(updatedList);
          const found = updatedList.find(
            (element) => element.name === 'subject'
          );
          const loadCC = async () => {
            if (found.selectedValue[0] !== undefined) {
              await store.dispatch(
                loadContentCodes(
                  found.selectedValue[0].id,
                  userObj.selectedProject[0]?.id,
                  userObj.selectedBranch[0]?.id,
                  'neighborhood'
                )
              );
            }
            const NeighSettings = await store.dispatch(
              getNeighborhoodSettings(
                userObj.selectedProject[0].id,
                userObj.selectedBranch[0].id
              )
            );
            for (const element of NeighSettings) {
              if (element.name === 'content_codes') {
                applySettingToState(element, updatedList);
              }
            }
          };
          loadCC();
        } else {
          let updatedList = contentMetadata.map((item) => {
            if (item.name === key.name) {
              const value = parseInt(values);
              const newSelectedValue = item.possibleValue.find(
                (element) => element.id === value
              );
              return { ...item, selectedValue: [newSelectedValue] };
            }
            return item;
          });
          setContentMetadata(updatedList);
        }
      }
      if (key.dataType === 'Multi-Select') {
        let updatedList = contentMetadata.map((item) => {
          if (item.name === key.name) {
            return { ...item, selectedValue: values };
          }
          return item;
        });
        setContentMetadata(updatedList);
      }
      if (key.dataType === 'Custom') {
        let updatedList = contentMetadata.map((item) => {
          if (item.name === key.name) {
            return { ...item, selectedValue: values };
          }
          return item;
        });
        setContentMetadata(updatedList);
      }
    }
    if (key.category === 'Taxonomies and Dimensions') {
      if (key.dataType === 'Text') {
        let updatedList = taxonomiesDimensions.map((item) => {
          if (item.name === key.name) {
            const newObj = { name: values };
            return { ...item, selectedValue: [newObj] };
          }
          return item;
        });
        setTaxonomiesDimensions(updatedList);
      }
      if (key.dataType === 'Drop-Down') {
        let updatedList = taxonomiesDimensions.map((item) => {
          if (item.name === key.name) {
            const value = parseInt(values);
            const newSelectedValue = item.possibleValue.find(
              (element) => element.id === value
            );
            return { ...item, selectedValue: [newSelectedValue] };
          }
          return item;
        });
        setTaxonomiesDimensions(updatedList);
      }
      if (key.dataType === 'Multi-Select') {
        let updatedList = taxonomiesDimensions.map((item) => {
          if (item.name === key.name) {
            return { ...item, selectedValue: values };
          }
          return item;
        });
        setTaxonomiesDimensions(updatedList);
      }
    }
  };

  useEffect(() => {
    if (didFetchRef.current) return; 
    getAttributeSettings();
    didFetchRef.current = true;
  }, []);
  
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col p-2 justify-content-between">
          <fieldset className="bg-light p-3 rounded">
            <legend>Content Metadata</legend>
            {!contentMetadata.length ? (
              <p>no attributes selected</p>
            ) : (
              contentMetadata.map((key, idx) => (
                <>
                  <div className="col d-flex p-2 justify-content-between">
                    <div className="col-3 text-end">
                      <label
                        className="form-label"
                        htmlFor={`metadata-attribute-${key.name}-${idx}`}
                      >
                        {normalizeName(key.name)}
                      </label>
                    </div>
                    <div className="col-8">
                      {renderHTMLElement(
                        key,
                        updateValues,
                        `metadata-attribute-${key.name}-${idx}`
                      )}
                    </div>
                  </div>
                </>
              ))
            )}
          </fieldset>
        </div>
        <div className="col p-2 justify-content-between">
          <fieldset className="bg-light p-3 rounded">
            <legend>Taxonomies and Dimensions</legend>
            {!taxonomiesDimensions.length ? (
              <p>no attributes selected</p>
            ) : (
              taxonomiesDimensions.map((key, idx) => (
                <>
                  <div className="col d-flex p-2 justify-content-between">
                    <div className="col-3 text-end">
                      <label
                        className="form-label"
                        htmlFor={`taxonomy-attribute-${key.name}-${idx}`}
                      >
                        {normalizeName(key.name)}
                      </label>
                    </div>
                    <div className="col-8">
                      {renderHTMLElement(
                        key,
                        updateValues,
                        `taxonomy-attribute-${key.name}-${idx}`
                      )}
                    </div>
                  </div>
                </>
              ))
            )}
          </fieldset>
        </div>

        <div className="col p-2 justify-content-between">
          <fieldset className="bg-light p-3 rounded">
            <legend>Tags</legend>
            <div className="col d-flex p-2 justify-content-between">
              <div className="col-2 text-end">
                <label className="form-label" htmlFor="tags">
                  Keywords:
                </label>
              </div>
              <div className="col-9">
                <MultiSelect id="tags" name="tags" />
              </div>
            </div>
          </fieldset>
        </div>
      </div>

      <div className="row">
        <div className="col p-2">
          <fieldset className="bg-light p-3 rounded">
            <legend>Neighborhood Details</legend>
            {!neighborhoodDetails.length ? (
              <p>no attributes selected</p>
            ) : (
              neighborhoodDetails.map((key, idx) => (
                <>
                  <div className="col d-flex p-2 justify-content-between">
                    <div className="col-1 text-end">
                      <label
                        className="form-label"
                        htmlFor={`details-attribute-${key.name}-${idx}`}
                      >
                        {normalizeName(key.name)}
                      </label>
                    </div>
                    <div className="col-10">
                      {renderHTMLElement(
                        key,
                        updateValues,
                        `details-attribute-${key.name}-${idx}`
                      )}
                    </div>
                  </div>
                </>
              ))
            )}
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export default NeighborhoodAttributeSettings;

NeighborhoodAttributeSettings.propTypes = {
  getNeighborhood: PropTypes.func,
  neighborhoodDetails: PropTypes.array,
  setNeighborhoodDetails: PropTypes.func,
  contentMetadata: PropTypes.array,
  setContentMetadata: PropTypes.func,
  taxonomiesDimensions: PropTypes.array,
  setTaxonomiesDimensions: PropTypes.func,
  neighborhood: PropTypes.object,
  userObj: PropTypes.object,
  getNeighborhoodSettings: PropTypes.func,
  setAttrsLoaded: PropTypes.func,
  attrsLoaded: PropTypes.bool
};
