/**
 * @summary CycleCheckInputs.js
 * @file Input Options for Cycle Check Report. This report is currently not active until we can solve it. Should eventually return a list of all closed connection cycles in the map.
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react'
import { getViews } from 'store/views/ViewActions'
import { useSelector } from 'react-redux'
import store from 'store/store';

import PropTypes from 'prop-types';
import axios from 'axios'
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const CycleCheckInputs = ({
    generateReport,
    project,
    userObj,
}) => {
    
    // USE SELECTOR: Pulls Branch and Project Data from Redux
    const { branches, views } = useSelector((state) => {
        const branchesList = { ...state.branchesDetailsReducer.activeBranches };
        const views = { ...state.viewReducer };
        delete branchesList?.error;
        delete branchesList?.selectedData;
        delete views?.selectedData;
        return {
            project: state.authReducer.userObj.selectedProject[0],
            branches: Object.values(branchesList).filter(Boolean),
            views: Object.values(views).filter((view) => view.isActive),
            userObj: state.authReducer.userObj,
        }
    })
    // USE EFFECT: Gets VIews for the Use Selector (for Menus)
    useEffect(() => {
        store.dispatch(getViews(userObj.selectedProject[0].id))
    }, [])

    /////////////////////////////////////////////////////////
    // BUILDS MAP DROPDOWN OPTIONS WITH IDENTIFYING VALUES //
    /////////////////////////////////////////////////////////
    useEffect(() => {
        if(branches.length > 0 || views.length > 0){
            let branchOptions = branches.map(branch => {
                if(branch.name === "published"){
                    let branchObj = {
                        id: branch.id,
                        menuId: "branch" + branch.id.toString(),
                        menuName: "Published Branch",
                        name: branch.name,
                        type: "published branch"
                    }
                    return branchObj
                } else {
                    let branchObj = {
                        id: branch.id,
                        menuId: "branch" + branch.id.toString(),
                        menuName: "Branch: " + branch.name,
                        name: branch.name,
                        type: "branch"
                    }  
                    return branchObj
                }
            })
            let viewOptions = views.map(view => {
                let viewObj = {
                    id: view.id,
                    menuId: "view" + view.id.toString(),
                    menuName: "View: " + view.name,
                    name: view.name,
                    type: "view"
                }
                return viewObj
            })
            let mapOptions = branchOptions.concat(viewOptions)
            setMapDropdownOptions(mapOptions)
        } else {
            setMapDropdownOptions([])
        }
    }, [project])
    // SELECTS MAP FROM DROPDOWN OPTIONS: Branch or View //
    const handleMapSelection = (selectedMenuId) => {
        let selection = mapDropdownOptions.find(branch => branch.menuId === selectedMenuId)
        setSelectedMap(selection)    
    }

    /////////////////////////
    // PRIMARY STATE HOOKS //
    /////////////////////////
    // Map Dropdown States (Full and Selected)
    const [mapDropdownOptions, setMapDropdownOptions] = useState([])
    const [selectedMap, setSelectedMap] = useState(null)

    const onClickCycleCheck = () => {
        let sendData = {
            project,
            branchSelection: selectedMap
        }
        generateReport(sendData)
    }
    
    return(
    <div>
        <div
            style={{
                width: "1000px",
                minHeight: "175px",
                paddingBottom: "20px"
            }}
        >
        <div>
            This report will detect for any cycles in which a series of connections loops back to a starting node. The output of this process will be a list of all nodes that are reachable on the shortest path between any node and itself, followed by a list of nodes along that path.
        </div>
        <div>
            <div className='reporting-section'>
                <select
                    className="form-select form-select-sm"
                    aria-label=".form-select-sm example"
                    value={selectedMap?.menuId}
                    style={{width: '250px'}}
                    onChange={(e) => {
                        handleMapSelection(e.target.value)
                    }}
                    name="mapSelection"
                >
                    <option value="0">Select Full or Filtered Map</option>
                    {mapDropdownOptions.map((map) => (
                    <option key={map.menuId} value={map.menuId}>
                        {map.menuName}
                    </option>
                    ))}
                </select>
            </div>
        </div>
        </div>
        <div className='reporting-section'>
            <button
                type="button"
                className="k-button k-primary generate-report-button"
                onClick={onClickCycleCheck}
                disabled={selectedMap === null}
            >
                Generate Report
            </button>
        </div>
        
    </div>
    )
}

export default CycleCheckInputs;

CycleCheckInputs.propTypes = {
    generateReport: PropTypes.func,
    project: PropTypes.obj,
    userObj: PropTypes.obj
};