/**
 * @summary newForm.js
 * @file form component for new/edit project page
 * @returns {JSX}
 * @usedBy routes.js
 * @author Dj Ritchey
 * @since 02/17/2021
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import store from '../../../store/store';
import {
  createProject,
  updateProject
} from '../../../store/projects/ProjectActions';
import {
  resetProjectSetup,
  updateProjectAssessmentProgram,
  hydrateProject,
  hydrateCloneProject
} from '../../../store/ProjectSetup/ProjectSetupActions';
import { getThemes } from '../../../store/themes/ThemeActions';
import { updateUser } from '../../../store/auth/AuthActions';
import FormHeader from './formHeader';
import Accordion from '../../../shared/accordian/Wrapper';
import Panel from '../../../shared/accordian/Panel';
import ProjectSettings from './projectSettings';
import NodeSettings from './nodeSettings';
import ConnectionSettings from './connectionSettings';
import NeighborhoodSettings from './neighborhoodSettings';
import { clearNodes } from 'store/nodes/NodeActions';
import { clearConnections } from 'store/connections/ConnectionActions';
import { clearNeighborhoods } from 'store/neighborhoods/NeighborhoodActions';
import { clearViews } from 'store/views/ViewActions';
import { clearLinkageLevels } from 'store/linkageLevels/LinkageLevelActions';
import validator from 'store/validation/validator';
import newProjectSchema from 'store/validation/newProject.schema';
import ValidationMsg from 'store/validation/ValidationMsg';

const messageOptions = {
  messsageType: {
    success: 'Success saving project',
    successUpdate: 'Success updating project'
  },
  messages: {
    successOnDelete: 'Project Deleted',
    errorOnDelete: 'Unable to Delete Project',
    errorOnSave: 'Unable to Save Project',
    underConstruction: 'This Function is Under Construction'
  }
};

const ProjectForm = () => {
  const isMounted = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { userObj, project, selectedProject } = useSelector((state) => ({
    userObj: state.authReducer.userObj,
    project: state.projectSetupReducer,
    selectedProject: state.authReducer.userObj.selectedProject[0]
  }));

  const loadProject = () => {
    const isExistingProject = location.pathname.includes('setup');
    if (isExistingProject) {
      // need this to load data for project in projectSetup
      store.dispatch(hydrateProject(userObj));
    } else {
      // need this to clear out project to defaults
      store.dispatch(resetProjectSetup());
      store.dispatch(
        updateProjectAssessmentProgram(userObj.currentAssessmentProgram)
      );
    }
  };

  const loadCloneProject = (project, branch) => {
    store
      .dispatch(hydrateCloneProject(userObj, project, branch))
      .then((res) => {
        if (res) {
          return toast.error(res);
        }
      });
  };

  async function saveProject() {
    // VALIDATOR CONFIRMS WHETHER SCHEMA MATCHES PROJECT SAVE
    validator(newProjectSchema, project).then((resp) => {
      if (resp) {
        if (project.id < 1) {
          store
            .dispatch(createProject())
            .then((resp) => {
              if (resp.status === 201) {
                const newUserData = {
                  ...userObj,
                  selectedProject: [resp.data.data],
                  selectedBranch: [
                    resp.data.branches.find((b) => b.branchStatus === 'draft')
                  ]
                };
                // clear redux of previous project data
                store.dispatch(clearNodes());
                store.dispatch(clearConnections());
                store.dispatch(clearNeighborhoods());
                store.dispatch(clearViews());
                store.dispatch(clearLinkageLevels());
                store.dispatch(getThemes());
                axios
                  .post('/api/saveUserPreference', newUserData)
                  .then(() => {
                    store.dispatch(updateUser(newUserData));
                    navigate('/setup');
                  })
                  .catch((err) => {
                    toast.error('Error Selecting Project');
                  });
                toast.success(messageOptions.messsageType.success);
              }
            })
            .catch((errors) => {
              // RETURNS ANY ERRORS FROM THE BACKENDF THAT PREVENT SAVING
              const errorMessages = errors.response.data;
              toast.error(
                <ValidationMsg
                  errorsArray={errorMessages}
                  message={errors.message}
                />,
                { autoClose: 5000 }
              );
            });
        } else {
          store
            .dispatch(updateProject())
            .then((res) => {
              if (res.status === 200) {
                if (res.data.message_type !== 'success') {
                  if (res.data === 'current User does not own this project') {
                    toast.error(
                      `${userObj.screenName} does not own Project: ${userObj.selectedProject[0].name}`
                    );
                  } else if (
                    res.data === 'current User does not own this branch'
                  ) {
                    toast.error(
                      `${userObj.screenName} does not own Branch: ${userObj.selectedBranch[0].name}`
                    );
                  } else if (res.data === 'branch passed in is not a draft') {
                    toast.error(
                      `Branch ${userObj.selectedBranch[0].name} not in "draft" status`
                    );
                  } else {
                    toast.error('An unexpected error occurred making changes');
                  }
                }
              } else {
                const newUserData = {
                  ...userObj,
                  selectedProject: [project]
                };
                store.dispatch(updateUser(newUserData));
                return toast.success(messageOptions.messsageType.successUpdate);
              }
            })
            .catch((errors) => {
              // RETURNS ANY ERRORS FROM THE BACKENDF THAT PREVENT SAVING
              const errorMessages = errors.response.data;
              toast.error(
                <ValidationMsg
                  errorsArray={errorMessages}
                  message={errors.message}
                />,
                { autoClose: 5000 }
              );
            });
        }
      }
    });
  }

  const deleteProject = () => {
    // TODO: ADD store call to project action for delete
  };

  // if user changes assessment program or url location will need to reload project, and user profile
  useEffect(() => {
    store.dispatch(getThemes());
    loadProject();
    store.dispatch(
      updateProjectAssessmentProgram(userObj.currentAssessmentProgram)
    );
  }, [userObj.currentAssessmentProgram, location]);

  return (
    <>
      <FormHeader onSave={saveProject} onClone={loadCloneProject} />
      <Accordion>
        <Panel
          title="Project Settings"
          help_code={'PROJECT'}
          panel_type={'project_settings'}
          body={<ProjectSettings />}
        />
        <Panel
          title="Node Settings"
          help_code={'NODE'}
          panel_type={'node_settings'}
          body={<NodeSettings />}
        />
        <Panel
          title="Connection Settings"
          help_code={'CONNECTION'}
          panel_type={'connection_settings'}
          body={<ConnectionSettings />}
        />
        <Panel
          title="Neighborhood Settings"
          help_code={'NEIGHBORHOOD'}
          panel_type={'neighborhood_settings'}
          body={<NeighborhoodSettings />}
        />
      </Accordion>
    </>
  );
};

export default ProjectForm;
