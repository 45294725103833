/**
 * @summary NodeMediaGrid.js
 * @file grid component for media panel in new/edit node form
 * @returns {JSX}
 * @usedBy NodeMedia.js
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 06/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import KendoGridBase from '../../../shared/ui/kendoGridBase/KendoGridBase';
import { gridSelectedDataActions } from '../../../store/nodes/NodeActions';
import store from '../../../store/store';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import axios from 'axios';
import formattedCell from '../../../shared/ui/kendoGridBase/Grid/FormattedCell';

import MediaModal from '../modal/MediaModal';
import { formatDateTime } from '../../../utility/formatDateTime';

const NodeCollectionGrid = ({ setRefresh, updateMedia }) => {
  const selectedProject = useSelector(
    (state) => state.authReducer.userObj.selectedProject[0]
  );
  const node = useSelector((state) => state.nodeReducer.selectedData);

  // allows user to open media in a new tab
  const downloadExportedPackage = (e, params) => {
    e.preventDefault();
    axios
      .post('/api/signedgeturl', params)
      .then((res) => {
        if (res.data.success) {
          const dlink = document.createElement('a');
          dlink.target = '_blank';
          dlink.href = res.data.data;
          dlink.onclick = () => window.URL.revokeObjectURL(res.data.data);
          dlink.click();
          dlink.remove();
          return toast.success(messageOptions.messages.successfulDownload);
        }
      })
      .catch((error) => {
        if (error?.response?.data) {
          return toast.error(messageOptions.messages.errorOnDownload);
        } else {
          return toast.error(messageOptions.messages.errorOnDownload);
        }
      });
  };

  // cell will be file name, not required for this fn
  const handleDownloadPackage = (cell, row) => {
    return (
      <>
        {row?.dataItem?.filelocation !== 'undefined' && (
          <a
            href="#"
            onClick={(e) => {
              const params = {
                params: {
                  mediaPath: row?.dataItem?.filelocation
                }
              };
              downloadExportedPackage(e, params, row?.dataItem);
            }}
          >
            <u style={{ color: 'blue' }}>{row?.dataItem?.name}</u>
          </a>
        )}
      </>
    );
  };

  const [gridState, setGridState] = useState({
    mediacollections: { data: [], total: 0 },
    dataState: { take: 20, skip: 1 },
    gridDynamicColumns: [
      {
        field: 'selected',
        show: true,
        filterable: false
      },
      {
        field: 'name',
        title: 'Name',
        show: true,
        filterable: false,
        filter: 'text',
        width: '300px',
        cell: formattedCell(handleDownloadPackage),
        cellType: 'link'
      },
      {
        field: 'filetype',
        title: 'Format',
        show: true,
        filterable: false,
        filter: 'text',
        cellType: 'text',
        width: '200px'
      },
      {
        field: 'createddate',
        title: 'Created',
        show: true,
        filterable: false,
        filter: 'text',
        cellType: 'text',
        width: '300px'
      }
    ]
  });
  const [filter, setFilter] = useState({});
  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 30 });
  const [, setLoading] = useState(false);
  const [, setSkipProcessing] = useState(0);
  const [selectedRowsStateArray, setSelectedStateArray] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const messageOptions = {
    messsageType: {
      success: 'Success'
    },
    messages: {
      successOnDelete: 'Form Deleted',
      errorOnDelete: 'Unable to Delete Media',
      errorOnFetch: 'Unable to Load Media',
      underConstruction: 'This Function is Under Construction',
      uploadSuccess: 'upload successful',
      uploadError: 'upload successful'
    }
  };

  const handlePageChange = (e) => {
    e.page.skip = isNaN(e.page.skip) ? 1 : e.page.skip;
    setPage(e.page);
  };

  const handleGridFilterChange = (colFilter) => {
    setRefresh(true);
    setFilter(colFilter || {});
  };

  const handleGridSortChange = (obj) => {
    setRefresh(true);
    setSort(obj);
  };

  const updateGridState = () => {
    const media = node[0];
    media.nodeMedia?.forEach((m) => {
      m.createddate = formatDateTime(new Date(m?.createddate));
    });
    setGridState({
      ...gridState,
      mediacollections: {
        data: media.nodeMedia || [],
        total: media.nodeMedia?.length || 0
      },
      dataState: { take: page.take, skip: page.skip }
    });
  };

  // When a Row is Selected...
  const selectionChange = (e) => {
    const data = gridState.mediacollections.data.map((collection) => {
      // Adds Selected Data to an Array to Control Button Visibility
      if (collection === e.dataItem) {
        collection.selected = !e.dataItem.selected;
        if (collection.selected === true) {
          setSelectedStateArray((selectedRowsStateArray) => [
            ...selectedRowsStateArray,
            collection
          ]);
        } else {
          const filteredSelectedState = selectedRowsStateArray.filter(
            (selecteCheck) => selecteCheck.selected === true
          );
          setSelectedStateArray(filteredSelectedState);
        }
      }
      return collection;
    });

    const gridObjs = { ...gridState.mediacollections };
    gridObjs.data = data;
    store.dispatch(gridSelectedDataActions(e.dataItem));
    setGridState({ ...gridState, mediacollections: gridObjs });
  };

  // Handles All Button and Row Click Actions
  const actionHandler = (action, selectedCollections) => {
    if (action === 'edit' || action === 'doubleclick') {
      navigate(
        `/projects/${selectedProject.id}/nodes/${selectedCollections.id}/edit`
      );
    }
    if (action === 'addMedia') {
      setShowModal(!showModal);
    }
    if (action === 'delete') {
      return toast.success(messageOptions.messages.underConstruction);
    }
  };

  useEffect(() => {
    updateGridState();
  }, [selectedProject]);

  useEffect(() => {
    updateGridState();
  }, []);

  useEffect(() => {
    setLoading(true);
    setLoading(false);
    setSkipProcessing(page.skip);
  }, [filter, sort, page, selectedProject]);

  const uploadToastTrigger = (id) =>
    id
      ? toast.success(messageOptions.messages.uploadSuccess)
      : toast.error(messageOptions.messages.uploadError);

  return (
    <div className="container-fluid">
      <div
        // Can I Add a CSS Class?
        style={{
          height: '61vh',
          width: '100%',
          margin: '16px',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
      >
        <KendoGridBase
          data={gridState.mediacollections.data || []}
          gridColumns={gridState.gridDynamicColumns}
          setGridFilters={handleGridFilterChange}
          setGridSort={handleGridSortChange}
          updateGridData={gridSelectedDataActions}
          onSelectionChange={selectionChange}
          onRowSingleClick={selectionChange}
          onPageChange={handlePageChange}
          sorter={sort}
          rowHeight={40}
          skip={page.skip}
          take={page.take}
          total={
            gridState.mediacollections ? gridState.mediacollections.total : 0
          }
          pageSize={100}
          selectable="selected"
          pageable
          sortable
          filterable
        />
        <MediaModal
          show={showModal}
          setShow={setShowModal}
          setMedia={updateMedia}
          uploadToastTrigger={uploadToastTrigger}
          parentObj={'node'}
        />
        <div className="container-fluid p-0 d-flex justify-content-between mt-2">
          <div>
            {/* do this need to be removed below */}
            {/* <button
              className="btn btn-primary btn-sm me-2"
              type="button"
              onClick={() => {
                actionHandler('edit', gridState.nodecollections.data && (gridState.nodecollections.data).filter((dataItem) => dataItem.selected === true)[0]);
                actionHandler('edit', nodes.selectedData[0]);
              }}
            >
              <i className="bi bi-pencil me-2" />
                  Edit
            </button> */}
            <button
              className="btn btn-success btn-sm text-white me-2"
              type="button"
              onClick={() => {
                actionHandler('addMedia', '(need function)');
              }}
            >
              <i className="bi bi-plus me-2" />
              Add Media
            </button>
          </div>
          <div>
            <button
              className="btn btn-danger btn-sm text-white"
              type="button"
              onClick={() => {
                actionHandler('delete', selectedRowsStateArray);
              }}
              disabled={!selectedRowsStateArray.length}
            >
              <i className="bi bi-trash me-2" />
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
NodeCollectionGrid.propTypes = {
  setRefresh: PropTypes.func,
  gridSelectedDataActions: PropTypes.func,
  setShow: PropTypes.func,
  updateMedia: PropTypes.func
};

export default NodeCollectionGrid;
