/**
 * @summary Neighborhood Reducer
 * @file NeighborhoodReducer.js
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import {
  CLEAR_NEIGHBORHOODS,
  CREATE_NEIGHBORHOOD,
  EDIT_NEIGHBORHOODLINKAGELEVELS,
  EDIT_NEIGHBORHOODS,
  GET_NEIGHBORHOOD,
  GET_NEIGHBORHOODLINKAGELEVELS,
  GET_NEIGHBORHOODS,
  GRID_DATA_ACTIONS_SELECTIONS_NEIGHBORHOODS_FULLFILLED,
  ADD_NEIGHBORHOOD_MEDIA,
  SELECT_ALL_NEIGHBORHOOD,
  ADD_LINKAGELEVEL_MEDIA,
  GET_NEIGHBORHOOD_NODE_IDS,
  SET_NEW_NEIGHBORHOOD,
  ADD_NODES_TO_NEIGHBORHOOD,
  UPDATE_SELECTED_LINKAGE_LEVELS,
  DELETE_NEIGHBORHOOD,
  REMOVE_NODES_FROM_NEIGHBORHOOD,
  REMOVE_NODES_FROM_NEIGHBORHOODS_MUTLIPLE,
  DELETE_MULTIPLE_NEIGHBORHOOD,
  DELETE_NEIGHBORHOOD_NODES
} from './NeighborhoodTypes';

const neighborhoods = {
  selectedData: []
};

/**
 * @summary
 * This redux reducer is used for node CRUD based on the action type
 *
 * @param {nodes} state
 * @param {reduxAction} action
 */

let copyNeighborhood;
let copyNeighborhoods;

const neighborhoodReducer = (state = neighborhoods, action) => {
  switch (action.type) {
    case GET_NEIGHBORHOODS:
      return {
        selectedData: [],
        // keep all incoming nodes inside a single level
        ...action.neighborhoods.reduce((newObj, neighborhood) => {
          // neighborhood.nodes = [];
          newObj[neighborhood.id] = neighborhood;
          return newObj;
        }, {})
      };

    case GET_NEIGHBORHOODLINKAGELEVELS:
      return {
        ...state,
        selectedLinkageLevels: action.selectedLinkageLevels
      };

    case GET_NEIGHBORHOOD:
      const selectedData = { ...action.selectedNeighborhood };
      selectedData.nodeIds = selectedData.nodes.map((node) => node?.id);
      return {
        ...state,
        selectedData: [selectedData]
      };

    case CREATE_NEIGHBORHOOD:
      const updatedSelectedData = [...state.selectedData];
      const idx = updatedSelectedData.findIndex(
        (neighborhood) => neighborhood.id === -1
      );
      const neighborhoodToUpdate = updatedSelectedData[idx];
      neighborhoodToUpdate.id = action.meta.id;
      neighborhoodToUpdate.name = action.meta.name;
      neighborhoodToUpdate.lastUpdated = action.meta.lastUpdated;
      // set defaults if nothing returns from API
      neighborhoodToUpdate.nodes = neighborhoodToUpdate.nodes || [];
      neighborhoodToUpdate.linkageLevels =
        neighborhoodToUpdate.linkageLevels || [];

      return {
        ...state,
        [action.meta.id]: {
          ...action.neighborhood,
          id: action.meta.id
        },
        // does not support multi create at this time
        selectedData: [
          ...state.selectedData,
          (state.selectedData[idx] = neighborhoodToUpdate)
        ]
      };

    case EDIT_NEIGHBORHOODS:
      return {
        ...state,
        [action.meta.id]: {
          ...action.neighborhoods,
          id: action.meta.id
        }
      };

    case EDIT_NEIGHBORHOODLINKAGELEVELS:
      return {
        ...state,
        [action.meta.id]: {
          ...action.neighborhoods,
          id: action.meta.id
        }
      };

    case ADD_LINKAGELEVEL_MEDIA:
      const updatedLinkageLevel = { ...state.selectedData[0] };
      const foundLL = updatedLinkageLevel?.linkageLevels.find(
        (ll) => ll.id === action.parentId
      );
      foundLL.linkageLevelMedia.push(action.media);
      return {
        ...state,
        selectedData: [updatedLinkageLevel]
      };

    case GRID_DATA_ACTIONS_SELECTIONS_NEIGHBORHOODS_FULLFILLED:
      const copySelectedData = [...state.selectedData];
      const isArr = Array.isArray(action.neighborhoods.data);
      if (isArr && action.neighborhoods.data[0].selected) {
        copySelectedData = action.neighborhoods.data;
      } else if (!isArr) {
        const idx = copySelectedData.findIndex(
          (neigh) => neigh.id === action.neighborhoods.data.id
        );
        idx < 0
          ? copySelectedData.push(action.neighborhoods.data)
          : copySelectedData.splice(idx, 1);
      } else {
        copySelectedData = [];
      }

      return {
        ...state,
        selectedData: copySelectedData,
        error: null
      };

    case SELECT_ALL_NEIGHBORHOOD:
      let selected = action.neighborhoods.data;
      if (action.neighborhoods.data.length === state.selectedData.length) {
        selected = [];
      }
      return {
        ...state,
        selectedData: selected,
        error: null
      };

    case CLEAR_NEIGHBORHOODS:
      return {
        selectedData: []
      };

    case ADD_NEIGHBORHOOD_MEDIA:
      const updatedNeighborhood = { ...state.selectedData[0] };
      updatedNeighborhood.neighborhoodMedia.push(action.media);
      return {
        ...state,
        selectedData: [updatedNeighborhood]
      };

    case GET_NEIGHBORHOOD_NODE_IDS:
      const newState = { ...state };
      action.neighborhoodNodes.forEach((nodeData) => {
        if (newState[nodeData.neighborhood_id]) {
          newState[nodeData.neighborhood_id].nodeIds = nodeData.nodes;
        }
      });
      return {
        ...newState
      };

    case SET_NEW_NEIGHBORHOOD:
      return {
        ...state,
        selectedData: [
          {
            id: -1,
            name: '',
            description: '',
            nodes: [],
            nodeIds: [],
            linkageLevels: [],
            lastUpdated: null,
            neighborhoodMediaIds: [],
            linkageLevelMediaIds: [],
            neighborhoodMedia: [],
            settings: {
              details: [],
              metadata: [],
              taxonomies: [],
              flags: []
            }
          }
        ]
      };

    case ADD_NODES_TO_NEIGHBORHOOD:
      return {
        ...state,
        selectedData: [
          {
            ...state.selectedData[0],
            nodeIds: [...state.selectedData[0].nodeIds, ...action.nodes]
          }
        ]
      };

    case REMOVE_NODES_FROM_NEIGHBORHOOD:
      copyNeighborhoods = { ...state };
      Object.values(copyNeighborhoods).forEach((neighborhood) => {
        const foundIdx = neighborhood.nodeIds?.findIndex(
          (id) => id === action.node.id
        );
        if (foundIdx > -1) {
          neighborhood.nodeIds.splice(foundIdx, 1);
        }
      });
      return {
        ...state,
        ...copyNeighborhoods
      };

    case REMOVE_NODES_FROM_NEIGHBORHOODS_MUTLIPLE:
      copyNeighborhoods = { ...state };
      Object.values(copyNeighborhoods).forEach((neighborhood) => {
        action.nodeIds.forEach((nodeId) => {
          const foundIdx = neighborhood.nodeIds?.findIndex(
            (id) => id === nodeId
          );
          if (foundIdx > -1) {
            neighborhood.nodeIds.splice(foundIdx, 1);
          }
        });
      });
      return {
        ...state,
        ...copyNeighborhoods
      };

    case UPDATE_SELECTED_LINKAGE_LEVELS:
      return {
        ...state,
        selectedData: [
          {
            ...state.selectedData[0],
            linkageLevels: action.linkageLevelData
          }
        ]
      };

    case DELETE_NEIGHBORHOOD:
      copyNeighborhood = { ...state };
      delete copyNeighborhood[action.neighborhood.id];
      return {
        ...copyNeighborhood,
        selectedData: []
      };

    case DELETE_MULTIPLE_NEIGHBORHOOD:
      copyNeighborhood = { ...state };
      action.neighborhoodIds.forEach((id) => {
        delete copyNeighborhood[id];
        return {
          ...copyNeighborhood,
          selectedData: []
        };
      });

    case DELETE_NEIGHBORHOOD_NODES:
      // { projectId: selectedProject.id, branchId: selectedBranch.id,
      //   id: selectedNeighborhood.id, nodeIds: selectedRowsStateArray.map(obj => obj.id)}
      copyNeighborhood = { ...state };
      const neighborhood = { ...state.selectedData[0] };
      const copyNodes = [...action.neighborhood.nodes];

      copyNodes.forEach((node) => {
        const foundIdx = neighborhood.nodes.findIndex(
          (neighborhoodNode) => neighborhoodNode.id === node.id
        );

        if (foundIdx > -1) {
          neighborhood.nodes.splice(foundIdx, 1);
        }
      });
      neighborhood.nodeIds = neighborhood.nodes.map((node) => node.id);

      return {
        ...state,
        [action.neighborhood.id]: neighborhood,
        selectedData: [neighborhood]
      };

    default:
      return state;
  }
};
export default neighborhoodReducer;
