/**
 * @summary  Reducer for auth action types
 * @file AuthReducer.jsx
 * @returns {String}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNOUT_FAILURE = 'SIGNOUT_FAILURE';
export const FORGOT_PWD_FAILURE = 'FORGOT_PWD_FAILURE';
export const RESET_PWD_FAILURE = 'RESET_PWD_FAILURE';
export const FORGOT_PWD_SUCCESS = 'FORGOT_PWD_SUCCESS';
export const RESET_PWD_SUCCESS = 'RESET_PWD_SUCCESS';
export const UPDATE_SELECTED_PROGRAM = 'UPDATE_SELECTED_PROGRAM';
export const NEW_PWD_FAILURE = 'NEW_PWD_FAILURE';
export const NEW_PWD_SUCCESS = 'NEW_PWD_SUCCESS';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const MODE_CHANGE = 'MODE_CHANGE';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_TAB = 'UPDATE_USER_TAB';
