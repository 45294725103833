/**
 * @summary MediaActions.js
 * @file Media actions
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import axios from 'axios';
import {
  CREATE_MEDIA,
  GET_MEDIAS,
  DELETE_MEDIA,
  GRID_MEDIA_DATA_ACTIONS_SELECTIONS_FULLFILLED,
  CLEAR_SELECTION,
  CLEAR_MEDIAS,
  UPDATE_MEDIA,
  ADD_MEDIA
} from './MediaTypes';
import { ADD_NODE_MEDIA } from 'store/nodes/NodeTypes';
import {
  ADD_NEIGHBORHOOD_MEDIA,
  ADD_LINKAGELEVEL_MEDIA
} from 'store/neighborhoods/NeighborhoodTypes';
import mime from 'mime-types';
import { formatDateTime } from 'utility/formatDateTime';
import { toast } from 'react-toastify';

export const addNewMedia =
  (media, parentObj, parentId, linkageLevelId) =>
  async (dispatch, getState) => {
    delete media.getRawFile;

    if (parentObj === 'node') {
      await dispatch({
        type: ADD_NODE_MEDIA,
        media: media
      });
    } else if (parentObj === 'neighborhood') {
      await dispatch({
        type: ADD_NEIGHBORHOOD_MEDIA,
        media: media
      });
    } else if (parentObj === 'linkageLevel') {
      await dispatch({
        type: ADD_LINKAGELEVEL_MEDIA,
        media: media,
        parentId,
        linkageLevelId,
        linkageLevels: getState().linkageLevelReducer.linkageLevels
      });
    }
  };

export const createMedia =
  (mediaData, userObj) => async (dispatch, getState) => {
    // currently supporting only single uploads!
    mediaData.forEach((file) => {
      file.file_name = file.name.split('.')[0];
      file.file_type = mediaData[0].extension.split('.').pop();
      file.alternate_text = file.name;
      file.user_id = userObj.id;
      file.project_id = userObj.selectedProject[0].id;
      file.branch_id = userObj.selectedBranch[0].id;
    });

    const newMedia = { ...mediaData[0] };
    const file = newMedia.getRawFile();

    await axios
      .post('/api/media/upload/attachment/path', mediaData)
      .then(async (s3signed) => {
        if (s3signed.data && s3signed.data.attachment_file) {
          newMedia.id = s3signed.data.id;
          newMedia.attachmentId = s3signed.data.attachment_id;
          newMedia.filePath = s3signed.data.attachment_file;
          newMedia.filelocation = newMedia.filePath;
          newMedia.filetype = newMedia.file_type;
          newMedia.name = newMedia.file_name;
          newMedia.createddate = formatDateTime(new Date());
          newMedia.mediacontentid = s3signed.data.id;
          await axios
            .post('/api/signedputurl', {
              params: {
                filePath: s3signed.data.attachment_file,
                fileType: mediaData[0].extension.split('.').pop()
              }
            })
            .then(async (response) => {
              const signedRequest = response.data.data.signedRequest;
              const url = response.data.data.url;
              const success = response.data.success;
              if (success) {
                const data = {};
                data.url = url;
                const options = {
                  headers: {
                    'Content-Type': mime.contentType(mediaData[0].extension)
                  }
                };
                await axios.put(signedRequest, file, options);
              }
              await response;
            })
            .catch((error) => {
              toast.error('Invalid path');
            });
        }
      })
      .catch((error) => {
        toast.error('Error uploading');
      });
    return newMedia;
  };

export const addMedia = (media) => {
  return {
    type: ADD_MEDIA,
    media
  };
};

export const updateMedia = (mediaData) => async (dispatch, getState) => {
  const user = getState().authReducer.userObj;
  const resp = await axios.put(
    `/api/${projectSetup.assessmentProgramId}/projects/${projectSetup.id}/branch/${user.selectedBranch[0]?.id}`,
    { ...projectSetup }
  );
  dispatch({
    type: UPDATE_MEDIA,
    media: mediaData,
    meta: resp.data
  });
  return resp;
};

export function dispatchGetMedias(mediaList) {
  return {
    type: GET_MEDIAS,
    mediaList
  };
}

export const getMedias = (assessmentProgramId, params) => async (dispatch) => {
  const { data } = await axios.get(
    `/api/${assessmentProgramId}/projects`,
    params
  );
  dispatch(dispatchGetMedias(data));
};

// master media grid
const updateMediaSelectedGrid = (data) => ({
  type: GRID_MEDIA_DATA_ACTIONS_SELECTIONS_FULLFILLED,
  media: {
    data
  }
});

export const mediaGridSelectedDataActions = (data) => (dispatch) => {
  dispatch(updateMediaSelectedGrid(data));
};

export const clearSelectedMedia = () => ({
  type: CLEAR_SELECTION
});

// node media grid

const updateNodeSelectedMedia = (data) => ({
  type: GRID_DATA_ACTIONS_SELECTIONS_FULLFILLED,
  media: {
    data
  }
});

export const nodeMediaGridSelectedDataActions = (data) => (dispatch) => {
  dispatch(updateNodeSelectedMedia(data));
};

export const clearNodeSelectedMedia = () => ({
  type: CLEAR_SELECTION
});

// neighborhood media grid

const updateNeighborhoodSelectedMedia = (data) => ({
  type: GRID_DATA_ACTIONS_SELECTIONS_FULLFILLED,
  media: {
    data
  }
});

export const neighborhoodMediaGridSelectedDataActions =
  (data) => (dispatch) => {
    dispatch(updateNeighborhoodSelectedMedia(data));
  };

export const clearNeighborhoodSelectedMedia = () => ({
  type: CLEAR_SELECTION
});

// linkage level media grid

const updateLinkageLevelSelectedMedia = (data) => ({
  type: GRID_DATA_ACTIONS_SELECTIONS_FULLFILLED,
  media: {
    data
  }
});

export const linkageLevelMediaGridSelectedDataActions =
  (data) => (dispatch) => {
    dispatch(updateLinkageLevelSelectedMedia(data));
  };

export const clearLinkageLevelSelectedMedia = () => ({
  type: CLEAR_SELECTION
});

export const deleteMedia = (MediaId) => async (dispatch) => {
  // TODO add dispatch call to back end when route avail
};

export const clearAllMedias = () => ({
  type: CLEAR_MEDIAS
});
