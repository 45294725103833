/**
 * @summary SIngle Wrapper reducer
 * @file RootReducer.js
 * @returns {Redux}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { combineReducers } from 'redux';
import metadataReducer from './metadata/MetadataReducer';
import authReducer from './auth/AuthReducer';
import projectReducer from './projects/ProjectReducer';
import nodeUploadReducer from './uploads/MapUploadReducer';
import nodeReducer from './nodes/NodeReducer';
import neighborhoodReducer from './neighborhoods/NeighborhoodReducer';
import connectionReducer from './connections/ConnectionReducer';
import viewReducer from './views/ViewReducer';
import branchesDetailsReducer from './branchesDetails/branchesDetailsReducer';
import frameworkDetailsReducer from './frameworks/FrameworkReducer';
import projectSetupReducer from './ProjectSetup/ProjectSetupReducer';
import themeReducer from './themes/ThemeReducer';
import linkageLevelReducer from './linkageLevels/LinkageLevelReducer';
import mediaReducer from './media/MediaReducer';
import linkagelLevelNeighborhoodReducer from './linkageLevelNeighborhoods/LinkageLevelNeighborhoodsReducer';

/**
 * @summary
 * This combinedReducers holds all the reducers used in the application.
 */
const rootReducer = combineReducers({
  authReducer,
  metadataReducer,
  projectReducer,
  nodeUploadReducer,
  nodeReducer,
  neighborhoodReducer,
  connectionReducer,
  viewReducer,
  branchesDetailsReducer,
  frameworkDetailsReducer,
  projectSetupReducer,
  themeReducer,
  linkageLevelReducer,
  mediaReducer,
  linkagelLevelNeighborhoodReducer
});

export default rootReducer;
