/**
 * @summary shortestPathUtil.js
 * @file Function computes the distance of each node from the starting node (findDistances()), looks for the shortest path (findShortedPath()), produces a collection of all paths (collectAllPaths()), then returns an object of start/destination node and an array of node path
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { formatMapData } from "../formatMapData";
import { toast } from 'react-toastify';

export const getShortestPath = (startingNode, destinationNode, nodes, connections, branchType) => {
    const {nodeDataArray, linkDataArray} = formatMapData(nodes, connections)

    const $ = go.GraphObject.make;
    const diagram = $(go.Diagram, // no div!
        {
          model: $(go.GraphLinksModel,
            {   
                nodeKeyProperty: 'nodeKey',
                linkKeyProperty: 'key' 
            })
        });
    
    // need to merge in the data since the props are immutable and GoJS normally adds some internal properties
    diagram.model.commit(m => {
        m.mergeNodeDataArray(nodeDataArray);
      if (linkDataArray !== undefined && m instanceof go.GraphLinksModel) {
        m.mergeLinkDataArray(linkDataArray);
      }
    })
    let node1 = diagram.findNodeForKey(startingNode)
    let node2 = diagram.findNodeForKey(destinationNode)
    
    // There are three bits of functionality here:
  // 1: findDistances(Node) computes the distance of each Node from the given Node.
  //    This function is used by showDistances to update the model data.
  // 2: findShortestPath(Node, Node) finds a shortest path from one Node to another.
  //    This uses findDistances.  This is used by highlightShortestPath.
  // 3: collectAllPaths(Node, Node) produces a collection of all paths from one Node to another.
  //    This is used by listAllPaths.  The result is remembered in a global letiable
  //    which is used by highlightSelectedPath.  This does not depend on findDistances.

  // Returns a Map of Nodes with distance values from the given source Node.
  // Assumes all links are directional.
  if(node1 !== null && node2 !== null){
    function findDistances(source) {
      let diagram = source.diagram;
      // keep track of distances from the source node
      let distances = new go.Map(/*go.Node, "number"*/);
      // all nodes start with distance Infinity
      let nit = diagram.nodes;
      while (nit.next()) {
        let n = nit.value;
        distances.set(n, Infinity);
      }
      // the source node starts with distance 0
      distances.set(source, 0);
      // keep track of nodes for which we have set a non-Infinity distance,
      // but which we have not yet finished examining
      let seen = new go.Set(/*go.Node*/);
      seen.add(source);

      // keep track of nodes we have finished examining;
      // this avoids unnecessary traversals and helps keep the SEEN collection small
      let finished = new go.Set(/*go.Node*/);
      while (seen.count > 0) {
        // look at the unfinished node with the shortest distance so far
        let least = leastNode(seen, distances);
        let leastdist = distances.get(least);
        // by the end of this loop we will have finished examining this LEAST node
        seen.delete(least);
        finished.add(least);
        // look at all Links connected with this node
        let it = least.findLinksOutOf();
        while (it.next()) {
          let link = it.value;
          let neighbor = link.getOtherNode(least);
          // skip nodes that we have finished
          if (finished.has(neighbor)) continue;
          let neighbordist = distances.get(neighbor);
          // assume "distance" along a link is unitary, but could be any non-negative number.
          let dist = leastdist + 1;  //Math.sqrt(least.location.distanceSquaredPoint(neighbor.location));
          if (dist < neighbordist) {
            // if haven't seen that node before, add it to the SEEN collection
            if (neighbordist === Infinity) {
              seen.add(neighbor);
            }
            // record the new best distance so far to that node
            distances.set(neighbor, dist);
          }
        }
      }

      return distances;
    }

    // This helper function finds a Node in the given collection that has the smallest distance.
    function leastNode(coll, distances) {
      let bestdist = Infinity;
      let bestnode = null;
      let it = coll.iterator;
      while (it.next()) {
        let n = it.value;
        let dist = distances.get(n);
        if (dist < bestdist) {
          bestdist = dist;
          bestnode = n;
        }
      }
      return bestnode;
    }

    // Recursively walk the graph starting from the BEGIN node;
    // when reaching the END node remember the list of nodes along the current path.
    // Finally return the collection of paths, which may be empty.
    // This assumes all links are directional.
    // function collectAllPaths(begin, end) {
    //   var stack = new go.List(/*go.Node*/);
    //   var coll = new go.List(/*go.List*/);

    //   function find(source, end) {
    //     source.findNodesOutOf().each(n => {
    //       if (n === source) return;  // ignore reflexive links
    //       if (n === end) {  // success
    //         var path = stack.copy();
    //         path.add(end);  // finish the path at the end node
    //         coll.add(path);  // remember the whole path
    //       } else if (!stack.has(n)) {  // inefficient way to check having visited
    //         stack.add(n);  // remember that we've been here for this path (but not forever)
    //         find(n, end);
    //         stack.removeAt(stack.count - 1);
    //       }  // else might be a cycle
    //     });
    //   }

    //   stack.add(begin);  // start the path at the begin node
    //   find(begin, end);
    //   return coll;
    // }


    // Find a path that is shortest from the BEGIN node to the END node.
    // (There might be more than one, and there might be none.)
    if(node1 && node2){
      // let paths = collectAllPaths(node1, node2)
      // function findShortestPath(begin, end) {
      // compute and remember the distance of each node from the BEGIN node
      let distances = findDistances(node1);
      if(distances.count > 0){
      // now find a path from END to BEGIN, always choosing the adjacent Node with the lowest distance
      let path = new go.List();
      path.add(node2);
      while (node2 !== null) {
        let next = leastNode(node2.findNodesInto(), distances);
        if (next !== null) {
          if (distances.get(next) < distances.get(node2)) {
            path.add(next);  // making progress towards the beginning
          } else {
            next = null;  // nothing better found -- stop looking
          }
        }
        node2 = next;
      }
      // reverse the list to start at the node closest to BEGIN that is on the path to END
      // NOTE: if there's no path from BEGIN to END, the first node won't be BEGIN!
      path.reverse();
      if(path._dataArray.length){
        if(path.count > 1){
          let nodeArray = path._dataArray.map(node => {
            let nodeData = {
              key: node.data.key,
              nodeKey: node.data.nodeKey,
              title: node.data.title,
              description: node.data.description,
              position: path._dataArray.indexOf(node) + 1
            }
            return nodeData
          })

          let shortestPathObj = {
            startingNode,
            destinationNode,
            nodeArray,
            errorMessage: null
          }

          return shortestPathObj;
        } else {
          if(branchType === "branch" || branchType === "published branch"){
            return({errorMessage: "These nodes are not connected. Open this branch in Canvas View to see a map of the connection structure."})
          } else {
            return({errorMessage: "These nodes are not connected. Open this branch in Canvas and load the view to see a map of its connection structure."})
          }
        }
      } else {
        return({errorMessage: "You found a way to trigger this error. Please contact ATS to have it corrected."})
      }
      } else {
        if(branchType === "branch" || branchType === "published branch"){
          return({errorMessage: "These nodes are not connected. Open this branch in Canvas View to see a map of the connection structure."})
        } else {
          return({errorMessage: "These nodes are not connected. Open this branch in Canvas and load the view to see a map of its connection structure."})
        }      
      }
    }
  } else {
    if(branchType === "branch" || branchType === "published branch"){
      if(node1 == null && node2 === null){
        return({errorMessage: "These node keys do not exist in this branch. You can view this branch's node keys on the Table View page."})
      } else if(node1 === null && node2 !== null) {
        return({errorMessage: "This starting node key does not exist in this branch. You can view node keys on the Table View page."})
      } else if(node2 === null && node1 !== null) {
        return({errorMessage: "This destination node key does not exist in this branch. You can view node keys on the Table View page."})
      } else {
        return({errorMessage: "An unexpected error has occurred. Refresh your page and try again."})
      }
    } else {
      if(node1 == null && node2 === null){
        return({errorMessage: "These node keys do not exist in this view. You can see what's available in this view by loading it in Canvas."})
      } else if(node1 === null && node2 !== null) {
        return({errorMessage: "This starting node key does not exist in this view. You can see what's available in this view by loading it in Canvas."})
      } else if(node2 === null && node1 !== null) {
        return({errorMessage: "This destination node key does not exist in this view. You can see what's available in this view by loading it in Canvas."})
      } else {
        return({errorMessage: "An unexpected error has occurred. Refresh your page and try again."})
      }
    }
    
  }
}
