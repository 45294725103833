/**
 * @summary NeighborhoodFormHeader.js
 * @file Form header component that allows you to edit neighborhood Name/Description and save & delete the changes
 * @returns {JSX}
 * @usedBy NeighborhoodForm.js
 * @author Sam Lee
 * @since 2/17/2023
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import CKEditor from 'shared/ckEditor/CKEditor';
import { formatDateTime } from '../../../utility/formatDateTime';
import DeleteConfirmation from '../modal/DeleteConfirmation';
import { deleteNeighborhood } from 'store/neighborhoods/NeighborhoodActions';
import store from 'store/store';

const NeighborhoodFormHeader = ({
  onSave,
  neighborhoodName,
  setNeighborhoodName,
  neighborhoodDesc,
  setNeighborhoodDesc,
  neighborhood,
  neighborhoodEE,
  setNeighborhoodEE
}) => {
  const { userObj } = useSelector(
    (state) => ({
      userObj: state.authReducer.userObj
    }),
    shallowEqual
  );

  const navigate = useNavigate();
  
  const [showModal, setShowModal] = useState(false);

  const currentProject = userObj.selectedProject[0].name;
  
  const deleteNeighborhoodHandler = async () => {
    const resp = await store.dispatch(deleteNeighborhood({...neighborhood, projectId: userObj.selectedProject[0].id, branchId: userObj.selectedBranch[0].id}))
    if (resp.status === 201) {
      navigate('/tableview');
    } else {
      toast.error('Not able to delete Neighborhood')
    }
  }

  const handleClose = () => {
    navigate('/tableview');
  };

  const onDeleteClick = () => {
    setShowModal(true);
  };

  const handleSave = () => {
    onSave();
  };

  const onChange = (fieldName, value) => {
    setNeighborhoodDesc(value);
  };

  return (
    <div className="container-fluid locked-header">
      <DeleteConfirmation showModal={showModal} setShowModal={setShowModal}  deleteFn={deleteNeighborhoodHandler}/>
      {/* TOP ROW */}
      <div className="row row-cols-2 pt-2 pe-2 row-cols-xl-5 row-cols-lg-4 row-cols-md-3">
        <div className="col pt-1 pb-1 hstack gap-2">
          <label className="form-label">Project Name:</label>
          <label className="form-label">{currentProject}</label>
        </div>

        <div className="col pt-1 pb-1 hstack gap-2">
          <label className="form-label">Neighborhood ID:</label>
          {neighborhood?.id > 0 && (
            <label className="form-label">{neighborhood?.id}</label>
          )}
        </div>

        <div className="col pt-1 pb-1 hstack gap-2"></div>

        <div className="col pt-1 pb-1 hstack gap-2" />

        {/* TOP ROW ACTION BUTTONS */}
        <div className="col-12 pt-1 pb-1 col-md-8 col-lg-12 hstack gap-2 justify-content-end">
          <button
            type="button"
            className="btn btn-primary btn-sm"
            disabled={userObj.selectedBranch[0].branchStatus === 'published'}
            onClick={handleSave}
          >
            <i className="bi bi-save2"></i> Save
          </button>
          <button
            type="button"
            disabled={neighborhood?.id === ''}
            className="btn btn-danger btn-sm text-white"
            onClick={onDeleteClick}
          >
            {' '}
            <i className="bi bi-trash"></i> Delete
          </button>
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={handleClose}
          >
            <i className="bi bi-x"></i> Close
          </button>
        </div>
      </div>

      {/* BOTTOM ROW */}
      <div className="row row-cols-2 pb-2 pe-2 row-cols-xl-5 row-cols-lg-4 row-cols-md-3">
        <div className="col pt-1 pb-1 hstack gap-2">
          <label className="form-label">Branch:</label>
          <label className="form-label">
            {userObj.selectedBranch[0].name || ''}
          </label>
        </div>
        <div className="col pt-1 pb-1 hstack gap-2">
          {neighborhood?.id && (
            <>
              <label className="form-label text-nowrap">Last Saved:</label>
              {neighborhood?.id > 0 && (
                <label className="form-label col-md-8">
                  {formatDateTime(new Date(neighborhood?.lastUpdated)) || ''}{' '}
                </label>
              )}
            </>
          )}
        </div>

        <div className="col pt-1 pb-1 hstack gap-2"></div>

        <div className="col pt-1 pb-1 hstack gap-2"></div>
      </div>

      <div className="pb-1 gap-2">
        <label className="form-label hstack gap-0" htmlFor="form-header-name">
          Neighborhood Name:
          <i className="text-danger ms-1">*</i>
        </label>
        <input
          type="text"
          id="form-header-name"
          value={neighborhoodName}
          name="name"
          className="form-control form-control-sm"
          onChange={(e) => setNeighborhoodName(e.target.value)}
        />
        <label className="form-label hstack gap-0" htmlFor="form-header-desc">
          Neighborhood Description:
        </label>
        <CKEditor
          fieldName={neighborhoodDesc}
          data={neighborhoodDesc}
          onChange={onChange}
        />
      </div>
      
      {/* BOTTOM ROW ACTION BUTTONS */}
      <div className="pb-1 gap-2">
        <label className="form-label hstack gap-0" htmlFor="form-header-name">
          Associated EE:
        </label>
        <input
            type="text"
            id="form-header-name"
            value={neighborhoodEE}
            name="name"
            className="form-control form-control-sm"
            onChange={(e) => setNeighborhoodEE(e.target.value)}
          />
      </div>
    </div>
  );
};

export default NeighborhoodFormHeader;

NeighborhoodFormHeader.propTypes = {
  onSave: PropTypes.func.isRequired,
  neighborhoodName: PropTypes.string,
  setNeighborhoodName: PropTypes.func,
  neighborhoodDesc: PropTypes.string,
  setNeighborhoodDesc: PropTypes.func,
  neighborhood: PropTypes.object,
  neighborhoodEE: PropTypes.string,
  setNeighborhoodEE: PropTypes.func
};
