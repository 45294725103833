/**
 * @summary GlobalSidebarResults.js
 * @file Renders Filter (or all map) Results Based on the Element Selection User Makes at the Top of GlobalSidebar.js
 * @usedBy GlobalSideBar.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import * as React from 'react';
import PropTypes from 'prop-types';

// ICONS
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import { AiFillEye } from 'react-icons/ai';
import { BsFillSunFill } from 'react-icons/bs';
import { TbSunOff } from 'react-icons/tb'
import { BiSolidRightArrow } from 'react-icons/bi'
import { BiSolidDownArrow } from 'react-icons/bi'

const GlobalSidebarResults = ({
    globalSidebarMenuSelection, 
    invisibleNodeArray, 
    visibleNeighborhoodArray,
    highlightElementsArray, 
    highlightLinksArray,
    openElementsArray, 
    handleVisibilityChange, 
    handleHighlightChange, 
    handleOpenElementDetails, 
    globalNodes,
    globalNeighborhoods,
    globalConnections,
}) => {
    if(globalSidebarMenuSelection === "Node"){
        // IF NODES EXIST, DISPLAY THEM; ELSE, PROVIDE A MESSAGE SAYING NO NODES
        if(globalNodes.length > 0){
            return(
            <>
            {globalNodes.map(node => {
                // This Attribute Mapper Enables Us to Seperate Strings and Boolean Attributes...
                // so that impoortant strings display first, and boolean attributes are grouped together second more readably 
                let stringAttributes = []
                let booleanAttributes = [] 
                node?.settings?.attributes?.forEach(attribute => {
                    let attributeObj = {
                        key: node.nodeKey.toString() + "-" + Object.keys(attribute).toString(),
                        name: Object.keys(attribute)[0].replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
                        value: attribute[Object.keys(attribute)].map(obj => obj.value.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()))
                    }
                    if(attributeObj?.value[0] === "True" || attributeObj?.value[0] === "False"){
                        booleanAttributes.push(attributeObj)
                    } else {
                        stringAttributes.push(attributeObj)
                    }
                    return attributeObj
                })
                let allAttributes = stringAttributes.concat(booleanAttributes)
                return(
                    <div key={node.nodeKey} className='row'>
                        <div className='global-sidebar-element'>
                            <div 
                                className="global-filter-checkbox-button" 
                            >
                                <div
                                    onClick={() => handleVisibilityChange(node.nodeKey, "node")}
                                    className={!invisibleNodeArray.includes(node.nodeKey) ? "global-filter-checkbox-on" : "global-filter-checkbox-off"}
                                >
                                    {!invisibleNodeArray.includes(node.nodeKey) ?
                                        <AiFillEye icon={AiFillEye} size={16}/> : 
                                        <AiOutlineEyeInvisible icon={AiOutlineEyeInvisible} size={16}/>
                                    }
                                </div>
                            </div>
                            <div 
                                className="global-filter-highlight-button" 
                            >
                                <div
                                    onClick={() => handleHighlightChange(node.nodeKey, "node")}
                                    className={highlightElementsArray.includes(node.nodeKey) ? "global-filter-highlight-checkbox-on" : "global-filter-checkbox-off"}
                                >
                                    {highlightElementsArray.includes(node.nodeKey) ?
                                        <BsFillSunFill icon={BsFillSunFill} size={16}/> :
                                        <TbSunOff style={{ transform: 'rotate(90deg)'}} icon={AiFillEye} size={16}/> 
                                    }
                                </div>
                            </div>
                            <div 
                                className="global-filter-sidebar-element" 
                            >
                                <div className='global-filter-sidebar-string'>{node.nodeKey}</div>
                            </div>
                            <div 
                                onClick={() => handleOpenElementDetails(node.nodeKey)}
                                className="global-filter-inquery-button" 
                            >
                                {openElementsArray.includes(node.nodeKey) ?
                                    <BiSolidDownArrow icon={BiSolidDownArrow} size={12}/> : 
                                    <BiSolidRightArrow icon={BiSolidRightArrow} size={12}/>
                                }
                            </div>
                            {openElementsArray.includes(node.nodeKey) ?
                                <div className='row global-sidebar-open-details-container'>
                                    <div className='global-sidebar-open-details'>
                                        <span className="global-sidebar-title-label">Title: </span><span className="global-sidebar-details-content">{node.title}</span>
                                    </div>   
                                    <div className='global-sidebar-open-details'>
                                        <span className="global-sidebar-title-label">Description: </span><span className="global-sidebar-details-content">{node.description}</span>
                                    </div>
                                    <div className='global-sidebar-open-details'>
                                        <span className="global-sidebar-title-label">Neighborhoods: </span>
                                        {!globalNeighborhoods.find(neighborhood => neighborhood._members.includes(node.nodeKey)) ?
                                            <span className="global-sidebar-details-content">None</span>
                                            :
                                            globalNeighborhoods.map(neighborhood => {
                                                return(
                                                    <>
                                                    {neighborhood._members.find(member => member === node.nodeKey) ? 
                                                        <div key={neighborhood.key} className='global-sidebar-details-list'>
                                                            <li>
                                                                <span className='global-sidebar-details-list-item'>{neighborhood.key}</span>
                                                            </li>
                                                        </div> 
                                                        : null
                                                    }
                                                    </>
                                                )
                                            })                                                 
                                        }
                                    </div>  
                                    <div className='global-sidebar-open-details'>
                                        <span className="global-sidebar-title-label">Attributes:</span> 
                                        {!node?.settings?.attributes?.length ?
                                            <span className="global-sidebar-details-content">None</span>
                                            :
                                            allAttributes?.map(attribute => {
                                                return(
                                                    <>
                                                        <div key={attribute.key} className='global-sidebar-details-list'>
                                                            <li>
                                                                <span className='global-sidebar-details-attribute-name'>{attribute.name}</span>: <span className='global-sidebar-details-attribute-value'>{attribute.value}</span>
                                                            </li>
                                                        </div> 
                                                    </>
                                                )
                                            })     
                                        }
                                    </div>   
                                    
                                    <span className="global-sidebar-title-label">ID: {node.nodeKey}</span>           
                                </div> 
                                : null
                            }
                        </div>
                    </div>
                )
            })}
            </>
            )
        } else {
            return(
            <div 
                className="global-filter-sidebar-element" 
            >
                <div className='global-filter-sidebar-string'>No Nodes Match this Query</div>
            </div>
            )
        }
    } else if (globalSidebarMenuSelection === "Neighborhood"){
        // IF NEIGHBORHOODS EXIST, DISPLAY THEM; ELSE, PROVIDE A MESSAGE SAYING NO NODES
        if(globalNeighborhoods.length > 0){
            return(
            globalNeighborhoods.map(neighborhood => {
                return(
                    <div key={neighborhood.key} className='row'>
                        <div className='global-sidebar-element'>
                            <div 
                                className="global-filter-checkbox-button" 
                            >
                                <div
                                    onClick={() => handleVisibilityChange(neighborhood.title, "neighborhood")}
                                    className={visibleNeighborhoodArray.includes(neighborhood.title) ? "global-filter-checkbox-on" : "global-filter-checkbox-off"}
                                >
                                    {visibleNeighborhoodArray.includes(neighborhood.title) ?
                                        <AiFillEye icon={AiFillEye} size={16}/> : 
                                        <AiOutlineEyeInvisible icon={AiOutlineEyeInvisible} size={16}/>
                                    }
                                </div>
                            </div>
                            <div 
                                className="global-filter-highlight-button" 
                            >
                                <div
                                    onClick={() => handleHighlightChange(neighborhood.title, "neighborhood")}
                                    className={highlightElementsArray.includes(neighborhood.title) ? "global-filter-highlight-checkbox-on" : "global-filter-checkbox-off"}
                                >
                                    {highlightElementsArray.includes(neighborhood.title) ?
                                        <BsFillSunFill icon={BsFillSunFill} size={16}/> :
                                        <TbSunOff style={{ transform: 'rotate(90deg)'}} icon={AiFillEye} size={16}/> 
                                    }
                                </div>
                            </div>
                            <div 
                                className="global-filter-sidebar-element" 
                            >
                                <div className='global-filter-sidebar-string'>{neighborhood.title}</div>
                            </div>
                            <div 
                                onClick={() => handleOpenElementDetails(neighborhood.key)}
                                className="global-filter-inquery-button" 
                            >
                                {openElementsArray.includes(neighborhood.key) ?
                                    <BiSolidDownArrow icon={BiSolidDownArrow} size={12}/> : 
                                    <BiSolidRightArrow icon={BiSolidRightArrow} size={12}/>
                                }
                            </div>
                            {openElementsArray.includes(neighborhood.key) ?
                                <div className='row global-sidebar-open-details-container'>
                                    <div className='col-12'>
                                        <div className='global-sidebar-open-details'>
                                            <span className="global-sidebar-title-label">Title: </span><span className="global-sidebar-details-content">{neighborhood.title}</span>
                                        </div>   
                                        <div className='global-sidebar-open-details'>
                                            <span className="global-sidebar-title-label">Description: </span><span className="global-sidebar-details-content">{neighborhood.description}</span>
                                        </div>   
                                        <div className='global-sidebar-open-details'>
                                            <span className="global-sidebar-title-label">Member Nodes: </span>
                                        </div>   
                                        {neighborhood?._members.map(memberNode => {
                                            return(
                                            <div key={memberNode} className='global-sidebar-details-list'>
                                                <li>
                                                    <span className='global-sidebar-details-list-item'>{memberNode}</span>
                                                </li>
                                            </div>   
                                        )})}
                                        <div className='global-sidebar-open-details'>
                                            <span className="global-sidebar-title-label">ID: {neighborhood.key}</span>
                                        </div> 
                                        
                                    </div>               
                                </div> 
                                : null
                            }
                        </div>
                    </div>
                )
            }))
        } else {
            return(
            <div className="global-filter-sidebar-element">
                <div className='global-filter-sidebar-string'>No Neighborhoods Match this Query</div>
            </div>
            )
        }
    } else if (globalSidebarMenuSelection === "Connection"){
        // IF CONNECTIONS EXIST, DISPLAY THEM; ELSE, PROVIDE A MESSAGE SAYING NO NODES
        if(globalConnections.length > 0){
            return(
                globalConnections.map(connection => {
                    return(
                        <div key={connection.id} className='row'>
                            <div className='global-sidebar-element'>
                                <div 
                                    className="global-filter-checkbox-button" 
                                >
                                    <div
                                        onClick={() => handleVisibilityChange(connection.id, "connection")}
                                        className={!invisibleNodeArray.includes(connection.id) ? "global-filter-checkbox-on" : "global-filter-checkbox-off"}
                                        disabled={true}
                                    >
                                        {!invisibleNodeArray.includes(connection.id) ?
                                            <AiFillEye icon={AiFillEye} size={16}/> : 
                                            <AiOutlineEyeInvisible icon={AiOutlineEyeInvisible} size={16}/>
                                        }
                                    </div>
                                </div>
                                <div 
                                    className="global-filter-highlight-button" 
                                >
                                    <div
                                        onClick={() => handleHighlightChange(connection.id, "connection")}
                                        className={highlightLinksArray.includes(connection.id) ? "global-filter-highlight-checkbox-on" : "global-filter-checkbox-off"}
                                    >
                                        {highlightLinksArray.includes(connection.id) ?
                                            <BsFillSunFill icon={BsFillSunFill} size={16}/> :
                                            <TbSunOff style={{ transform: 'rotate(90deg)'}} icon={AiFillEye} size={16}/> 
                                        }
                                    </div>
                                </div>
                                <div 
                                    className="global-filter-sidebar-element" 
                                >
                                    <div className='global-filter-sidebar-string'>{connection.name}</div>
                                </div>
                                <div 
                                    onClick={() => handleOpenElementDetails(connection.id)}
                                    className="global-filter-inquery-button" 
                                >
                                    {openElementsArray.includes(connection.id) ?
                                        <BiSolidDownArrow icon={BiSolidDownArrow} size={12}/> : 
                                        <BiSolidRightArrow icon={BiSolidRightArrow} size={12}/>
                                    }
                                </div>
                                {openElementsArray.includes(connection.id) ?
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='global-sidebar-details-title'>
                                                <span className="global-sidebar-connection-source-label">Source: </span><span className="global-sidebar-details-content"> {connection.from}</span>
                                            </div>   
                                        </div>
                                        <div className='col-12'>
                                            <div className='global-sidebar-details-title'>
                                                <span className="global-sidebar-connection-destination-label">Destination: </span><span className="global-sidebar-details-content"> {connection.to}</span>
                                            </div>   
                                        </div>             
                                    </div> 
                                    : null
                                }
                            </div>
                        </div>
                    )
                })
            )
        } else {
            return(
                <div className="global-filter-sidebar-element">
                    <div className='global-filter-sidebar-string'>No Connections Match this Query</div>
                </div>
                )
        }
    }
}

export default GlobalSidebarResults;

GlobalSidebarResults.propTypes = {
    globalSidebarMenuSelection: PropTypes.string,
    invisibleNodeArray: PropTypes.array,
    visibleNeighborhoodArray: PropTypes.array,
    highlightElementsArray: PropTypes.array,
    highlightLinksArray: PropTypes.array,
    openElementsArray: PropTypes.array,
    handleVisibilityChange: PropTypes.func,
    handleHighlightChange: PropTypes.func,
    handleOpenElementDetails: PropTypes.func,
    globalNodes: PropTypes.array,
    globalNeighborhoods: PropTypes.array,
    globalConnections: PropTypes.array,
}