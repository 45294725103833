/**
 * @summary routes.js
 * @file Provides protected routes to all parts of the application
 * @returns {JSX}
 * @usedBy App.js
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useEffect } from 'react';
import ViewCollectionPage from 'pages/project/views/ViewCollectionPage';
import BranchListPage from 'pages/revisions/listGrid/BranchListPage';
import TableViewLayout from 'pages/project/tableviewLayout';
import { Routes, Route, useNavigate } from 'react-router-dom';
import NewForm from '../../../pages/project/form/newForm';
import HomePage from 'pages/home/HomePage';
import MapUpload from 'pages/project/uploads/MapUpload';
import MapDownload from 'pages/project/downloads/MapDownload';
import ConnectionForm from 'pages/project/connections/ConnectionForm';
import NodeForm from 'pages/project/nodes/NodeForm';
import NeighborhoodForm from 'pages/project/neighborhoods/NeighborhoodForm';
import Commit from 'pages/revisions/commit/Commit';
import ConflictResolution from 'pages/revisions/conflictResolution';
import CanvasWrapper from 'pages/canvas/CanvasWrapper';
import ReportPage from 'pages/reports/ReportPage';
import Login from 'pages/login/Login';
import ForgotPw from 'pages/login/ForgotPw';
import ResetPw from 'pages/login/ResetPw';
import NewPw from 'pages/login/NewPw';
import ProtectedRoute from '../navigation/ProtectedRoute';

// Currently routes are setup to display in desending order
// Do not move from order unless you are certain
const AllRoutes = () => {
  const navigate = useNavigate();
useEffect(() => {
  // on initial render, and browser refresh, force redirect to homapage
  navigate("/", { replace: true });
}, []);

return (
<>
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/dashboard"
        element={
          <ProtectedRoute>
            <h1>dashboard</h1>
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/reports"
        element={
          <ProtectedRoute>
            <ReportPage />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/reviews"
        element={
          <ProtectedRoute>
            <h1>reviews</h1>
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/workflow"
        element={
          <ProtectedRoute>
            <h1>workflow</h1>
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/revisions/branchlist"
        element={
          <ProtectedRoute>
            <BranchListPage />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/help"
        element={
          <ProtectedRoute>
            <h1>help</h1>
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/projects"
        element={
          <ProtectedRoute>
            <h1>projects container</h1>
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/projects/new"
        element={
          <ProtectedRoute>
            <NewForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/upload"
        element={
          <ProtectedRoute>
            <MapUpload />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/download"
        element={
          <ProtectedRoute>
            <MapDownload />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/canvas"
        element={
          <ProtectedRoute>
            <CanvasWrapper />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/setup"
        element={
          <ProtectedRoute>
            <NewForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/nodes/edit"
        element={
          <ProtectedRoute>
            <NodeForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/nodes/new"
        element={
          <ProtectedRoute>
            <NodeForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/connections/new"
        element={
          <ProtectedRoute>
            <ConnectionForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/connections/edit"
        element={
          <ProtectedRoute>
            <ConnectionForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/neighborhoods/new"
        element={
          <ProtectedRoute>
            <NeighborhoodForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/neighborhoods/edit"
        element={
          <ProtectedRoute>
            <NeighborhoodForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/tableview"
        element={
          <ProtectedRoute>
            <TableViewLayout />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/conflicts"
        element={
          <ProtectedRoute>
            <ConflictResolution />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/views"
        element={
          <ProtectedRoute>
            <ViewCollectionPage />
          </ProtectedRoute>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/forgotpw" element={<ForgotPw />} />
      <Route path="/resetpw" element={<ResetPw />} />
      <Route path="/newPassword" element={<NewPw />} />
    </Routes>
  </>
)};

export default AllRoutes;
