/**
 * @summary MediaModal.js
 * @file Modal component that is responsible for uploading user media in details page
 * @returns {JSX}
 * @usedBy LinkageLevelMediaGrid.js, NeighborhoodMediaGrid.js, NodeMediaGrid.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import {
  VALID_FILE_NAME_REGEX,
  IMAGE_TYPES,
  VIDEO_TYPES,
  AUDIO_TYPES
} from 'app_code/fileTypeConstants';
import { Upload } from '@progress/kendo-react-upload';
import { allowedExtensions } from './utility/file-extensions';
import store from '../../../store/store';
import { createMedia, addNewMedia } from 'store/media/MediaActions';

import validator from 'store/validation/validator';
import newMediaSchema from 'store/validation/newMedia.schema';
import ValidationMsg from 'store/validation/ValidationMsg';

const MediaModal = ({
  show,
  setShow,
  setMedia,
  uploadToastTrigger,
  parentObj,
  incomingLinkageLevel,
  updateLinkageLevels,
  setReload
}) => {
  const [files, setFiles] = useState([]);
  const metadata = useSelector((store) => store.metadataReducer);
  const userObj = useSelector((store) => store.authReducer.userObj);

  const onAdd = (event) => {
    const customValidationErrors = [];
    const fileName = event.newState[0].name.slice(
      0,
      event.newState[0].name.lastIndexOf('.')
    );
    const medias = event.newState.map((file) => {
      file.name = file.name.replaceAll(' ', '_');
      if (
        `${file.name.match(VALID_FILE_NAME_REGEX)}`.length !== file.name.length
      ) {
        customValidationErrors.push('invalidFiles');
        file.validationErrors = customValidationErrors;
      }
      return file;
    });
    setFiles(medias);
  };

  const onProgress = (event) => {
    let custNewState = event.newState;
    if (event.newState && event.newState.length > 0) {
      custNewState = event.newState.map((file) => {
        if (file.progress === 100 && file.status === 3)
          return { ...file, status: 4 };
        return file;
      });
    }
    setFiles(custNewState);
  };

  const onStatusChange = (event) => {
    setFiles(event.newState);
  };

  const onRemove = (event) => {
    setFiles(event.newState);
  };

  const allowed = allowedExtensions(files, metadata);

  const handleSubmit = (e) => {
    // VALIDATOR CONFIRMS WHETHER SCHEMA MATCHES JSON (NODE) SAVE
    validator(newMediaSchema, files).then((resp) => {
      if (resp) {
        store
          .dispatch(
            createMedia(
              files,
              userObj,
            )
          )
          .then((media) => {
            setShow(!show);
            setFiles([]);
            uploadToastTrigger(media.id);
            if (parentObj === 'linkageLevel') {
              setReload(true);
              updateLinkageLevels(incomingLinkageLevel);
            } else {
              setMedia(media.id);
            }
            store.dispatch(addNewMedia(media, parentObj, incomingLinkageLevel?.id, incomingLinkageLevel?.linkageLevelId))
          })
      }
    });
  };

  return (
    <>
      <Modal show={show} backdrop="static" centered size="lg">
        <Modal.Header>
          <Modal.Title>Add Media</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="col-xs-12 col-sm-4">
              <h4
                style={{
                  color: '#1560b7',
                  fontSize: '16px',
                  fontWeight: '600'
                }}
              >
                Supported File Types
              </h4>
              <>
                <p>
                  Image/Graphic:
                  {IMAGE_TYPES.toString()}
                </p>
                <p>
                  Audio:
                  {AUDIO_TYPES.toString()}
                </p>
                <p>
                  Video:
                  {VIDEO_TYPES.toString()}
                </p>
                <p>Text: pdf, html, doc, dox, txt</p>
              </>
              <h4
                style={{
                  color: '#1560b7',
                  fontSize: '16px',
                  fontWeight: '600'
                }}
              >
                Supported file name characters
              </h4>
              <p>
                <strong>Alphanumeric characters</strong>
              </p>
              <p>0-9 a-z A-Z</p>
              <p>
                <strong>Special characters</strong>
              </p>
              <p>
                Exclamation point (!), Hyphen (-), Underscore (_), Period (.),
                Asterisk (*), Open parenthesis ((), Close parenthesis ())
              </p>
            </div>
            <div className="col-xs-12 col-sm-4">
              <Upload
                restrictions={{
                  allowedExtensions: allowed
                }}
                onAdd={onAdd}
                onRemove={onRemove}
                files={files}
                multiple={false}
                onProgress={onProgress}
                showActionButtons={false}
                onStatusChange={onStatusChange}
                autoUpload={false}
              />
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger btn-sm text-white"
            onClick={() => setShow(false)}
          >
            Close
          </button>
          <button
            className="btn btn-primary btn-sm text-white"
            onClick={handleSubmit}
            disabled={
              files.length === 0 ||
              files.some((f) => f.validationErrors?.length > 0)
            }
          >
            Upload Media
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

MediaModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  setMedia: PropTypes.func,
  uploadToastTrigger: PropTypes.func,
  parentObj: PropTypes.string,
  incomingLinkageLevel: PropTypes.object,
  setReload: PropTypes.func,
  updateLinkageLevels: PropTypes.func
};

export default MediaModal;
