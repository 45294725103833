/**
 * @summary GlobalSidebar.js
 * @file Container for Components that Renders Filter Results in a List Format that Users Can Easily Inspect. Also Enables Users to Change Element Visibility and Highlight Elements
 * @returns {JSX}
 * @usedBy CanvasPage.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { ImFilter } from 'react-icons/im'

// ICONS
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import { AiFillEye } from 'react-icons/ai';
import { BsFillSunFill } from 'react-icons/bs';
import { TbSunOff } from 'react-icons/tb'
import { BiSolidRightArrow } from 'react-icons/bi'
import { BiSolidDownArrow } from 'react-icons/bi'


// RESULTS COMPS
import GlobalSidebarResults from './GlobalSidebarResults'

const GlobalSidebar = ({
    renderMapData,
    handleFilterDisplay,
    handleGlobalSidebarDisplay,
    invisibleNodeArray,
    setInvisibleNodeArray,
    visibleNeighborhoodArray,
    setVisibleNeighborhoodArray,
    highlightElementsArray,
    setHighlightElementsArray,
    highlightLinksArray,
    setHighlightLinksArray,
    openElementsArray,
    setOpenElementsArray,
    globalKeywordInput,
}) => {
    const [globalSidebarMenuSelection, setGlobalSidebarMenuSelection] = useState("Node");

    // This function Sorts Nodes and Neighborhoods Alphabetically and Numerically by Key for Nodes and Title for Neighborhoods
    function sortKey(nodeKey) {
        const parts = nodeKey.split(/[^a-zA-Z0-9]+/);
        for (let i = 0; i < parts.length; i++) {
            const part = parts[i];
            if (/^\d+$/.test(part)) {
            parts[i] = parseInt(part, 10);
            }
        }
        return parts;
    }
    let unsortedNodes = renderMapData.nodeDataArray.filter(node => node.category !== "Super")
    let globalNodes = unsortedNodes.sort((a, b) => {
        const keyA = sortKey(a.nodeKey);
        const keyB = sortKey(b.nodeKey);
        for (let i = 0; i < Math.min(keyA.length, keyB.length); i++) {
          if (keyA[i] < keyB[i]) {
            return -1;
          } else if (keyA[i] > keyB[i]) {
            return 1;
          }
        }
        return keyA.length - keyB.length;
      });

    let unsortedNeighborhoods = renderMapData.nodeDataArray.filter(node => node.category === "Super")
    let globalNeighborhoods = unsortedNeighborhoods.sort((a, b) => {
        const keyA = sortKey(a.title);
        const keyB = sortKey(b.title);
        for (let i = 0; i < Math.min(keyA.length, keyB.length); i++) {
          if (keyA[i] < keyB[i]) {
            return -1;
          } else if (keyA[i] > keyB[i]) {
            return 1;
          }
        }
        return keyA.length - keyB.length;
      });
    

    function handleMenuSelectionChange(event) {
        setGlobalSidebarMenuSelection(event.target.value);
    }

    function handleVisibilityChange(id, element) {
        if(element === "node"){
            if(!invisibleNodeArray.includes(id)){
                let newVisibilityArray = cloneDeep(invisibleNodeArray)
                newVisibilityArray.push(id)
                setInvisibleNodeArray(newVisibilityArray)
            } else {
                let newVisibilityArray = cloneDeep(invisibleNodeArray)
                let visibilityArrayWithElementRemoved = newVisibilityArray.filter(function(item) {
                    return item !== id;
                });
                setInvisibleNodeArray(visibilityArrayWithElementRemoved)
            }
        } else if (element === "neighborhood"){
            if(!visibleNeighborhoodArray.includes(id)){
                let newVisibilityArray = cloneDeep(visibleNeighborhoodArray)
                newVisibilityArray.push(id)
                setVisibleNeighborhoodArray(newVisibilityArray)
            } else {
                let newVisibilityArray = cloneDeep(visibleNeighborhoodArray)
                let visibilityArrayWithElementRemoved = newVisibilityArray.filter(function(item) {
                    return item !== id;
                });
                setVisibleNeighborhoodArray(visibilityArrayWithElementRemoved)
            }
        } 
    }

    function handleHighlightChange(id, element) {
            if(element !== "connection"){
                if(!highlightElementsArray.includes(id)){
                    let newHighlightArray = cloneDeep(highlightElementsArray)
                    newHighlightArray.push(id)
                    setHighlightElementsArray(newHighlightArray)  
                } else {
                    let newHighlightArray = cloneDeep(highlightElementsArray)
                    let highlightArrayWithElementRemoved = newHighlightArray.filter(function(item) {
                        return item !== id;
                    });
                    setHighlightElementsArray(highlightArrayWithElementRemoved)
                }
            } else {
                if(!highlightLinksArray?.includes(id)){
                    let newHighlightArray = cloneDeep(highlightLinksArray)
                    newHighlightArray.push(id)
                    setHighlightLinksArray(newHighlightArray)  
                } else {
                    let newHighlightArray = cloneDeep(highlightLinksArray)
                    let highlightArrayWithElementRemoved = newHighlightArray.filter(function(item) {
                        return item !== id;
                    });
                    setHighlightElementsArray(highlightArrayWithElementRemoved)
                }
            }
    }

    function handleOpenElementDetails(id) {
        if(!openElementsArray.includes(id)){
            let newOpenArray = cloneDeep(openElementsArray)
            newOpenArray.push(id)
            setOpenElementsArray(newOpenArray)
        } else {
            let newOpenArray = cloneDeep(openElementsArray)
            let openArrayWithElementRemoved = newOpenArray.filter(function(item) {
                return item !== id;
            });
            setOpenElementsArray(openArrayWithElementRemoved)
        }
    }

    function handleVisibilityChangeAll () {
        if(globalSidebarMenuSelection === "Node"){
            if(invisibleNodeArray.length !== globalNodes.length){
                const allNodeKeys = globalNodes.map(node => node.nodeKey)
                setInvisibleNodeArray(allNodeKeys)
                setHighlightElementsArray([])
            } else {
                setInvisibleNodeArray([])
            }
        } else if (globalSidebarMenuSelection === "Neighborhood") {
            if(visibleNeighborhoodArray.length < globalNeighborhoods.length){
                const allNeighborhoodKeys = globalNeighborhoods.map(node => node.title)
                setVisibleNeighborhoodArray(allNeighborhoodKeys)
            } else {
                setVisibleNeighborhoodArray([])
            }
        } 
    }

    function handleHighlightChangeAll() {
        if(globalSidebarMenuSelection === "Node"){
            if(highlightElementsArray.length !== globalNodes.length){
                const allNodeKeys = globalNodes.map(node => node.nodeKey)
                setHighlightElementsArray(allNodeKeys)
            } else {
                setHighlightElementsArray([])
            }
        } else if (globalSidebarMenuSelection === "Neighborhood") {
            if(highlightElementsArray.length !== globalNeighborhoods.length){
                const allNeighborhoodKeys = globalNeighborhoods.map(node => node.title)
                setHighlightElementsArray(allNeighborhoodKeys)
            } else {
                setHighlightElementsArray([])
            }
        } else if (globalSidebarMenuSelection === "Connection") {
            if(highlightLinksArray.length !== renderMapData.linkDataArray.length){
                const allConnectionKeys = renderMapData.linkDataArray.map(node => node.id)
                setHighlightLinksArray(allConnectionKeys)
            } else {
                setHighlightLinksArray([])
            }
        } 
    }

    function handleOpenElementDetailsAll(){
        if(globalSidebarMenuSelection === "Node"){
            if(openElementsArray.length !== globalNodes.length){
                const allNodeKeys = globalNodes.map(node => node.nodeKey)
                setOpenElementsArray(allNodeKeys)
            } else {
                setOpenElementsArray([])
            }
        } else if (globalSidebarMenuSelection === "Neighborhood") {
            if(openElementsArray.length !== globalNeighborhoods.length){
                const allNeighborhoodKeys = globalNeighborhoods.map(node => node.key)
                setOpenElementsArray(allNeighborhoodKeys)
            } else {
                setOpenElementsArray([])
            }
        } else if (globalSidebarMenuSelection === "Connection") {
            if(openElementsArray.length !== renderMapData.linkDataArray.length){
                const allConnectionKeys = renderMapData.linkDataArray.map(connection => connection.id)
                setOpenElementsArray(allConnectionKeys)
            } else {
                setOpenElementsArray([])
            }
        } 
    }

    function handleFilterReset() {
        handleFilterDisplay(true)
    }

    let quotedKeywordInput = `"` + globalKeywordInput + `"`

    const GlobalSidebarLegend = () => {
        if(globalSidebarMenuSelection === "Node"){
            return(
                <div className='row'>
                    <div className='global-sidebar-legend'>
                        <div className="global-filter-checkbox-button">
                            <div
                                onClick={() => handleVisibilityChangeAll()}
                                className={invisibleNodeArray.length === 0 ? "global-filter-checkbox-on" : "global-filter-checkbox-off"}
                            >
                                {invisibleNodeArray.length === 0 ?
                                    <AiFillEye icon={AiFillEye} size={16}/> : 
                                    <AiOutlineEyeInvisible icon={AiOutlineEyeInvisible} size={16}/>
                                }
                            </div>
                        </div>
                        <div className="global-filter-highlight-button">
                            <div
                                onClick={() => handleHighlightChangeAll()}
                                className={highlightElementsArray.length === globalNodes.length ? "global-filter-checkbox-on" : "global-filter-checkbox-off"}
                            >
                                {highlightElementsArray.length === globalNodes.length ?
                                    <BsFillSunFill icon={BsFillSunFill} size={16}/> :
                                    <TbSunOff style={{ transform: 'rotate(90deg)'}} icon={AiFillEye} size={16}/> 
                                }
                            </div>
                        </div>
                        <div className="global-filter-legend-header">
                            <div id="legend" className='global-filter-sidebar-string'>All Node Results</div>
                        </div>
                        <div 
                            className="global-filter-inquery-button"
                            onClick={() => handleOpenElementDetailsAll()}
                        >
                            {openElementsArray.length !== globalNodes.length ?
                                <BiSolidRightArrow icon={BiSolidRightArrow} size={12}/> :
                                <BiSolidDownArrow icon={BiSolidDownArrow} size={12}/> 
                            }
                        </div>
                    </div>
                </div>
            )
        } else if(globalSidebarMenuSelection === "Neighborhood") {
            return(
                <div className='row'>
                    <div className='global-sidebar-legend'>
                        <div className="global-filter-checkbox-button">
                            <div
                                onClick={() => handleVisibilityChangeAll()}
                                className={visibleNeighborhoodArray.length === globalNeighborhoods.length ? "global-filter-checkbox-on" : "global-filter-checkbox-off"}
                            >
                                {visibleNeighborhoodArray.length === globalNeighborhoods.length ?
                                    <AiFillEye icon={AiFillEye} size={16}/> : 
                                    <AiOutlineEyeInvisible icon={AiOutlineEyeInvisible} size={16}/>
                                }
                            </div>
                        </div>
                        <div className="global-filter-highlight-button">
                            <div
                                onClick={() => handleHighlightChangeAll()}
                                className={highlightElementsArray.length === globalNeighborhoods.length ? "global-filter-checkbox-on" : "global-filter-checkbox-off"}
                            >
                                {highlightElementsArray.length === globalNeighborhoods.length ?
                                    <BsFillSunFill icon={BsFillSunFill} size={16}/> :
                                    <TbSunOff style={{ transform: 'rotate(90deg)'}} icon={AiFillEye} size={16}/> 
                                }
                            </div>
                        </div>
                        <div className="global-filter-legend-header">
                            <div id="legend" className='global-filter-sidebar-string'>All Neighborhood Results</div>
                        </div>
                        <div 
                            className="global-filter-inquery-button"
                            onClick={() => handleOpenElementDetailsAll()}
                        >
                            {openElementsArray.length !== globalNeighborhoods.length ?
                                <BiSolidRightArrow icon={BiSolidRightArrow} size={12}/> :
                                <BiSolidDownArrow icon={BiSolidDownArrow} size={12}/> 
                            }
                        </div>
                    </div>
                </div>
            )
        } else { // Connections
            return(
                <div className='row'>
                    <div className='global-sidebar-legend'>
                        <div className="global-filter-checkbox-button">
                            <div
                                onClick={() => handleVisibilityChangeAll()}
                                className={invisibleNodeArray.length === 0 ? "global-filter-checkbox-on" : "global-filter-checkbox-off"}
                            >
                                {invisibleNodeArray.length !== globalNodes.length ?
                                    <AiFillEye icon={AiFillEye} size={16}/> : 
                                    <AiOutlineEyeInvisible icon={AiOutlineEyeInvisible} size={16}/>
                                }
                            </div>
                        </div>
                        <div className="global-filter-highlight-button">
                            <div
                                onClick={() => handleHighlightChangeAll()}
                                className={highlightLinksArray.length === renderMapData.linkDataArray.length ? "global-filter-checkbox-on" : "global-filter-checkbox-off"}
                            >
                                {highlightLinksArray.length === renderMapData.linkDataArray.length ?
                                    <BsFillSunFill icon={BsFillSunFill} size={16}/> :
                                    <TbSunOff style={{ transform: 'rotate(90deg)'}} icon={AiFillEye} size={16}/> 
                                }
                            </div>
                        </div>
                        <div className="global-filter-legend-header">
                            <div id="legend" className='global-filter-sidebar-string'>All Connection Results</div>
                        </div>
                        <div 
                            className="global-filter-inquery-button"
                            onClick={() => handleOpenElementDetailsAll()}
                        >
                            {openElementsArray.length !== renderMapData.linkDataArray.length ?
                                <BiSolidRightArrow icon={BiSolidRightArrow} size={12}/> :
                                <BiSolidDownArrow icon={BiSolidDownArrow} size={12}/> 
                            }
                        </div>
                    </div>
                </div>
            )
        }
    }    
    return(
        <div className='global-sidebar-container'>
            <div className="global-sidebar-header">
                {renderMapData.filterType || "All"} Results
            </div>
            <div
                className="bi bi-x submenu-close-x"
                onClick={handleGlobalSidebarDisplay}
              >
            </div>
            <div className="global-sidebar-keyword-input-section">

                <button
                    className='sidebar-search-button'
                    onClick={handleFilterReset}
                >
                    <ImFilter size={16} />
                </button>
                {renderMapData.filterType ?
                
                    renderMapData.filterType === "Global Filter" ? 
                    <div
                        onClick={handleFilterReset}
                        className='global-sidebar-keyword-input'
                    >
                        {globalKeywordInput ? quotedKeywordInput : ""}
                    </div>
                    : 
                    <div className='global-sidebar-keyword-input'>
                        {renderMapData.filterType}
                    </div>
                    :
                    <div className='global-sidebar-keyword-input'>
                        Full Map
                    </div>

                }
            </div>
            <div className='global-sidebar-dropdown-section'>
                <form>
                    <select
                        id="filterContext"
                        className="global-filter-dropdown"
                        onChange={handleMenuSelectionChange}
                        value={globalSidebarMenuSelection}
                    >
                        <option value="Node">Nodes</option>
                        <option value="Neighborhood">Neighborhoods</option>
                        <option value="Connection">Connections</option>
                        {/* <option value="attributes">Attributes</option> */}
                    </select>
                </form>
            </div>
            <div className='global-sidebar-legend-section'>
                <GlobalSidebarLegend/>
            </div>
            <div className='global-sidebar-results-section'>
                
                {globalNodes.length || globalNeighborhoods.length ?
                    <GlobalSidebarResults
                        globalSidebarMenuSelection={globalSidebarMenuSelection}
                        invisibleNodeArray={invisibleNodeArray}
                        visibleNeighborhoodArray={visibleNeighborhoodArray}
                        highlightElementsArray={highlightElementsArray}
                        highlightLinksArray={highlightLinksArray}
                        openElementsArray={openElementsArray}
                        handleVisibilityChange={handleVisibilityChange}
                        handleHighlightChange={handleHighlightChange}
                        handleOpenElementDetails={handleOpenElementDetails}
                        globalNodes={globalNodes}
                        globalNeighborhoods={globalNeighborhoods}
                        globalConnections={renderMapData.linkDataArray} 
                    /> 
                    : null
                }
            </div>
            {renderMapData?.nodeDataArray.length < 1200 ? 
                <div className="global-sidebar-footer">
                   {globalSidebarMenuSelection === "Node" ?
                        <>
                            <div>{globalNodes.length} Nodes ({globalNodes.length - invisibleNodeArray.length} Visible)</div>
                        </>
                        : null}
                    {globalSidebarMenuSelection === "Neighborhood" ?
                        <>
                            <div>{globalNeighborhoods.length} Neighborhoods ({visibleNeighborhoodArray.length} Visible)</div>
                        </>
                        : null}
                    {globalSidebarMenuSelection === "Connection" ?
                        <>
                            <div>Connections: {renderMapData.linkDataArray.length}</div>
                        </>
                        : null}
                </div>
                : 
                <div className="global-sidebar-footer-warning">
                    Content Maximum Exceeded 
                    <span 
                        title={`This project has ${globalNodes.length} Nodes and ${globalNeighborhoods.length} neighborhoods (${globalNodes.length + globalNeighborhoods.length} Total Elements). You will need to reduce this total to under 1200 to render a map.`}
                        style={{marginLeft:"5px"}} 
                        className="bi bi-question-circle-fill">
                    </span>
                </div>
            }
        </div>

    )
}

export default GlobalSidebar

GlobalSidebar.propTypes = {
    renderMapData: PropTypes.object,
    handleFilterDisplay: PropTypes.func,
    handleGlobalSidebarDisplay: PropTypes.func,
    invisibleNodeArray: PropTypes.array,
    setInvisibleNodeArray: PropTypes.func,
    visibleNeighborhoodArray: PropTypes.array,
    setVisibleNeighborhoodArray: PropTypes.func,
    highlightElementsArray:  PropTypes.array,
    setHighlightElementsArray: PropTypes.func,
    highlightLinksArray: PropTypes.array,
    setHighlightLinksArray: PropTypes.func,
    openElementsArray: PropTypes.array,
    setOpenElementsArray: PropTypes.func,
    globalKeywordInput: PropTypes.string,
}