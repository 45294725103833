/**
 * @summary Login.jsx
 * @file Component allows users to login to the application
 * @returns {JSX}
 * @usedBy routes.js
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useEffect } from 'react';
import axios from 'axios';
import { LogIn } from 'ats-react-login';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { receiveLogin } from '../../store/auth/AuthActions';

export default function Login() {
  const dispatch = useDispatch(); // make available Redux/Saga dispatches in component
  const navigate = useNavigate(); // use to change route and pass email and password to New Password page
  
  const { user } = useSelector((store) => ({
    user: store.authReducer.userObj
  }));

  useEffect(() => {
    if (user?.id) {
      navigate('/');
    }
  }, [user]);

  return (
    <div>
      <LogIn
        resetLink={
          <Link id="forgotPassword" to="/forgotpw">
            Forgot Password?
          </Link>
        }
        logo={process.env.PUBLIC_URL + '/Kite_Content_Portal.svg'}
        background={`${process.env.PUBLIC_URL}/Kite_Login_BG.svg`}
        onSubmit={(e, email, password) => {
          toast.info('Validating login credentials');
          e.preventDefault();
          // add post request
          axios
            .post('/api/validateUser', {
              email,
              password
            })
            .then((res) => {
              const statusCode = res.status;
              if (statusCode === 200) {
                if (
                  res.data.reason &&
                  res.data.reason === 'newPasswordRequired'
                ) {
                  // handle newPasswordRequired pathway
                  toast.error(
                    'You are required to create a new password to continue.'
                  );
                  navigate({
                    pathname: '/newPassword',
                    data: { email, oldPassword: res.data.oldPassword }
                  });
                } else {
                  // redirect to the home page
                  dispatch(receiveLogin(res.data));
                  // Persist validated user in sessionStorage
                  const sessionStorage = window.sessionStorage;
                  sessionStorage.setItem('user', JSON.stringify(res.data));
                  toast.success(`Welcome, User`);
                  navigate('/');
                }
              } else {
                toast.error('Invalid username/password');
              }
            })
            .catch((err) => {
              toast.error('Invalid username/password');
            });
        }}
      />
    </div>
  );
}
