/**
 * @summary LinkageLevelMediaGrid.js
 * @file responsible for getting selected linkage levels and rendering them into the grid
 * @returns {JSX}
 * @usedBy LinkageLevelsSettings.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import KendoGridBase from '../../../shared/ui/kendoGridBase/KendoGridBase';
import { gridSelectedDataActions } from '../../../store/nodes/NodeActions';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import MediaModal from '../modal/MediaModal';
import formattedCell from '../../../shared/ui/kendoGridBase/Grid/FormattedCell';
import { formatDateTime } from 'utility/formatDateTime';

const LinkageLevelMediaGrid = ({
  setRefresh,
  updateMedia,
  incomingLinkageLevel,
  updateSelectedLinkageLevels,
  updateLinkageLevels
}) => {
  const selectedProject = useSelector(
    (state) => state.authReducer.userObj.selectedProject[0]
  );

  const { linkageLevel } = useSelector((state) => {
    const linkageLevel =
      state.neighborhoodReducer.selectedData[0].linkageLevels?.find(
        (ll) => ll.id === incomingLinkageLevel.id
      );
    return {
      linkageLevel: linkageLevel || incomingLinkageLevel
    };
  });

  const getSelectedDataLinkageLevel = () => {
    updateGridState(linkageLevel);
  };

  const downloadExportedPackage = (e, params) => {
    e.preventDefault();
    axios
      .post('/api/signedgeturl', params)
      .then((res) => {
        if (res.data.success) {
          const dlink = document.createElement('a');
          dlink.href = res.data.data;
          dlink.target = '_blank';
          dlink.onclick = () => window.URL.revokeObjectURL(res.data.data);
          dlink.click();
          dlink.remove();
          return toast.success(messageOptions.messages.successfulDownload);
        }
      })
      .catch((error) => {
        if (error?.response?.data) {
          return toast.error(messageOptions.messages.errorOnDownload);
        } else {
          return toast.error(messageOptions.messages.errorOnDownload);
        }
      });
  };

  const handleDownloadPackage = (cell, row) => {
    return (
      <>
        {row?.dataItem?.filelocation !== 'undefined' && (
          <a
            href="#"
            onClick={(e) => {
              const params = {
                params: {
                  mediaPath: row?.dataItem?.filelocation
                }
              };
              downloadExportedPackage(e, params);
            }}
          >
            <u style={{ color: 'blue' }}>{row?.dataItem?.name}</u>
          </a>
        )}
      </>
    );
  };

  const [gridState, setGridState] = useState({
    mediacollections: { data: [], total: 0 },
    dataState: { take: 20, skip: 1 },
    gridDynamicColumns: [
      {
        field: 'selected',
        show: true,
        filterable: false
      },
      {
        field: 'name',
        title: 'Name',
        show: true,
        filterable: false,
        filter: 'text',
        width: '300px',
        cell: formattedCell(handleDownloadPackage),
        cellType: 'link'
      },
      {
        field: 'filetype',
        title: 'Format',
        show: true,
        filterable: false,
        filter: 'text',
        cellType: 'text',
        width: '200px'
      },
      {
        field: 'createddate',
        title: 'Created',
        show: true,
        filterable: false,
        filter: 'text',
        cellType: 'text',
        width: '300px'
      }
    ]
  });
  const [filter, setFilter] = useState({});
  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 30 });
  const [, setLoading] = useState(false);
  const [, setSkipProcessing] = useState(0);
  const [selectedRowsStateArray, setSelectedStateArray] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);

  const messageOptions = {
    messsageType: {
      success: 'Success'
    },
    messages: {
      successOnDelete: 'Form Deleted',
      errorOnDelete: 'Unable to Delete Media',
      errorOnFetch: 'Unable to Load Media',
      underConstruction: 'This Function is Under Construction',
      uploadSuccess: 'upload successful',
      uploadError: 'upload successful'
    }
  };

  const handlePageChange = (e) => {
    e.page.skip = isNaN(e.page.skip) ? 1 : e.page.skip;
    setPage(e.page);
  };

  const handleGridFilterChange = (colFilter) => {
    setRefresh(true);
    setFilter(colFilter || {});
  };

  const handleGridSortChange = (obj) => {
    setRefresh(true);
    setSort(obj);
  };

  const updateGridState = (linkagelevel) => {
    linkageLevel.linkageLevelMedia.forEach((m) => {
      if (m.createddate !== 'n/a') {
        m.createddate = formatDateTime(new Date(m?.createddate));
      }
    });
    setGridState({
      ...gridState,
      mediacollections: {
        data: linkagelevel?.linkageLevelMedia || [],
        total: linkagelevel?.linkageLevelMedia?.length || 0
      },
      dataState: { take: page.take, skip: page.skip }
    });
  };

  // When a Row is Selected...
  const selectionChange = (e) => {
    const data = gridState.mediacollections.data.map((collection) => {
      // Adds Selected Data to an Array to Control Button Visibility
      if (collection === e.dataItem) {
        collection.selected = !e.dataItem.selected;
        if (collection.selected === true) {
          setSelectedStateArray((selectedRowsStateArray) => [
            ...selectedRowsStateArray,
            collection
          ]);
        } else {
          const filteredSelectedState = selectedRowsStateArray.filter(
            (selecteCheck) => selecteCheck.selected === true
          );
          setSelectedStateArray(filteredSelectedState);
        }
      }
      return collection;
    });

    const gridObjs = { ...gridState.mediacollections };
    gridObjs.data = data;
    store.dispatch(gridSelectedDataActions(e.dataItem));
    setGridState({ ...gridState, mediacollections: gridObjs });
  };

  // Handles All Button and Row Click Actions
  const actionHandler = (action) => {
    if (action === 'edit' || action === 'doubleclick') {
      return toast.success(messageOptions.messages.underConstruction);
    }
    if (action === 'addMedia') {
      setShowModal(!showModal);
    }
    if (action === 'delete') {
      return toast.success(messageOptions.messages.underConstruction);
    }
  };

  useEffect(() => {
    getSelectedDataLinkageLevel();
  }, [selectedProject]);

  useEffect(() => {
    getSelectedDataLinkageLevel();
  }, [reload]);

  useEffect(() => {
    setLoading(true);
    setLoading(false);
    setSkipProcessing(page.skip);
  }, [filter, sort, page, selectedProject]);

  const uploadToastTrigger = (id) =>
    id
      ? toast.success(messageOptions.messages.uploadSuccess)
      : toast.error(messageOptions.messages.uploadError);

  return (
    <div className="container-fluid">
      <div
        style={{
          height: '61vh',
          width: '100%',
          margin: '16px',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
      >
        <KendoGridBase
          data={gridState.mediacollections.data || []}
          gridColumns={gridState.gridDynamicColumns}
          setGridFilters={handleGridFilterChange}
          setGridSort={handleGridSortChange}
          onSelectionChange={selectionChange}
          onRowSingleClick={selectionChange}
          onPageChange={handlePageChange}
          rowHeight={40}
          total={
            gridState.mediacollections ? gridState.mediacollections.total : 0
          }
          pageSize={100}
          selectable="selected"
          pageable
          sortable
          filterable
        />
        <MediaModal
          show={showModal}
          setShow={setShowModal}
          setMedia={updateMedia}
          incomingLinkageLevel={linkageLevel}
          uploadToastTrigger={uploadToastTrigger}
          updateLinkageLevels={updateLinkageLevels}
          parentObj={'linkageLevel'}
          updateSelectedLinkageLevels={updateSelectedLinkageLevels}
          setReload={setReload}
        />
        <div className="container-fluid p-0 d-flex justify-content-between mt-2">
          <div>
            {/* <button
              className="btn btn-primary btn-sm me-2"
              type="button"
              onClick={() => {
                // actionHandler('edit', gridState.nodecollections.data && (gridState.nodecollections.data).filter((dataItem) => dataItem.selected === true)[0]);
                // actionHandler('edit', nodes.selectedData[0]);
              }}
              // disabled={nodes.selectedData.length !== 1}
            >
              <i className="bi bi-pencil me-2" />
              Edit
            </button> */}
            <button
              className="btn btn-success btn-sm text-white me-2"
              type="button"
              disabled={linkageLevel.nodes.length < 1}
              onClick={() => {
                actionHandler('addMedia', '(need function)');
              }}
            >
              <i className="bi bi-plus me-2" />
              Add Media
            </button>
          </div>
          <div>
            <button
              className="btn btn-danger btn-sm text-white"
              type="button"
              onClick={() => {
                actionHandler('delete', selectedRowsStateArray);
              }}
              disabled={!selectedRowsStateArray.length}
            >
              <i className="bi bi-trash me-2" />
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
LinkageLevelMediaGrid.propTypes = {
  getLinkageLevel: PropTypes.func,
  setRefresh: PropTypes.func,
  gridSelectedDataActions: PropTypes.func,
  setShow: PropTypes.func,
  updateMedia: PropTypes.func,
  incomingLinkageLevel: PropTypes.object,
  userObj: PropTypes.object,
  neighborhood: PropTypes.object,
  updateSelectedLinkageLevels: PropTypes.func,
  updateLinkageLevels: PropTypes.func
};

export default LinkageLevelMediaGrid;
