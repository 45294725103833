/**
 * @summary NodeFormHeader.js
 * @file header component for new/edit nodes
 * @returns {JSX}
 * @usedBy NodeForm.js
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { formatDateTime } from '../../../utility/formatDateTime';
import DeleteConfirmation from '../modal/DeleteConfirmation';
import store from 'store/store';
import { deleteNode } from 'store/nodes/NodeActions';
import { toast } from 'react-toastify';
import CKEditor from 'shared/ckEditor/CKEditor';

const NodeFormHeader = ({
  node,
  onSave,
  nodeName,
  nodeKey,
  setNodeKey,
  setNodeName,
  nodeDesc,
  setNodeDesc
}) => {
  const { userObj } = useSelector((state) => ({
    userObj: state.authReducer.userObj
  }));
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const currentProject = userObj.selectedProject[0].name;

  const handleClose = () => {
    navigate('/tableview');
  };

  const deleteNodeHandler = async () => {
    // only handle single delete for now
    const resp = await store.dispatch(
      deleteNode({
        ...node,
        projectId: userObj.selectedProject[0].id,
        branchId: userObj.selectedBranch[0].id
      })
    );
    if (resp.status === 201) {
      navigate('/tableview');
    } else {
      toast.error('Not able to delete Node');
    }
  };

  const onDeleteClick = () => {
    setShowModal(true);
  };

  const handleSave = () => {
    onSave();
  };

  const onChange = (fieldName, value) => {
    setNodeDesc(value);
  };

  return (
    <div className="container-fluid locked-header">
      {/* TOP ROW */}
      <DeleteConfirmation
        showModal={showModal}
        setShowModal={setShowModal}
        deleteFn={deleteNodeHandler}
      />
      <div className="row row-cols-2 pt-2 pe-2 row-cols-xl-5 row-cols-lg-4 row-cols-md-3">
        <div className="col pt-1 pb-1 hstack gap-2">
          <label className="form-label">Project Name:</label>
          <label className="form-label">{currentProject}</label>
        </div>

        <div className="col pt-1 pb-1 hstack gap-2">
          <label className="form-label" htmlFor="form-header-key">
            Node key:
            <i className="text-danger"> *</i>
          </label>
          {node?.nodeKey ? (
            <label className="form-label">{node?.nodeKey || ''}</label>
          ) : (
            <input
              type="text"
              id="form-header-key"
              value={nodeKey}
              name="nodeKey"
              className="form-control form-control-sm"
              onChange={(e) => setNodeKey(e.target.value)}
            />
          )}
        </div>

        <div className="col pt-1 pb-1 hstack gap-2"></div>

        <div className="col pt-1 pb-1 hstack gap-2" />

        {/* TOP ROW ACTION BUTTONS */}
        <div className="col-12 pt-1 pb-1 col-md-8 col-lg-12 hstack gap-2 justify-content-end">
          <button
            type="button"
            className="btn btn-primary btn-sm"
            disabled={userObj.selectedBranch[0].branchStatus === 'published'}
            onClick={handleSave}
          >
            <i className="bi bi-save2"></i> Save
          </button>
          <button
            type="button"
            disabled={node?.id === ''}
            className="btn btn-danger btn-sm text-white"
            onClick={onDeleteClick}
          >
            {' '}
            <i className="bi bi-trash"></i> Delete
          </button>
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={handleClose}
          >
            <i className="bi bi-x"></i> Close
          </button>
        </div>
      </div>

      {/* BOTTOM ROW */}
      <div className="row row-cols-2 pb-2 pe-2 row-cols-xl-5 row-cols-lg-4 row-cols-md-3">
        <div className="col pt-1 pb-1 hstack gap-2">
          <label className="form-label">Branch:</label>
          <label className="form-label">
            {userObj.selectedBranch[0].name || ''}
          </label>
        </div>
        <div className="col pt-1 pb-1 hstack gap-2">
          {node?.id > 0 && (
            <div>
              <label className="form-label text-nowrap">Last Saved:</label>
              <label className="form-label col-md-8">
                {node.lastUpdated
                  ? formatDateTime(new Date(node?.lastUpdated))
                  : ''}{' '}
              </label>
            </div>
          )}
        </div>

        <div className="col pt-1 pb-1 hstack gap-2"></div>

        <div className="col pt-1 pb-1 hstack gap-2"></div>
      </div>

      <div className="pb-1 gap-2">
        <label className="form-label hstack gap-0" htmlFor="form-header-name">
          Node Name:
          <i className="text-danger ms-1">*</i>
        </label>
        <input
          type="text"
          id="form-header-name"
          value={nodeName}
          name="name"
          className="form-control form-control-sm"
          onChange={(e) => setNodeName(e.target.value)}
        />
        <label className="form-label hstack gap-0" htmlFor="form-header-desc">
          Node Description:
        </label>
        <CKEditor fieldName={nodeDesc} data={nodeDesc} onChange={onChange} />
      </div>

      {/* BOTTOM ROW ACTION BUTTONS */}
      <div className="col-12 pt-1 pb-1 col-md-8 col-lg-12 hstack gap-2 justify-content-end">
        <button type="button" className="btn btn-secondary btn-sm text-white">
          Version History
        </button>
      </div>
    </div>
  );
};

export default NodeFormHeader;

NodeFormHeader.propTypes = {
  node: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  nodeName: PropTypes.string,
  nodeKey: PropTypes.string,
  setNodeName: PropTypes.func,
  nodeDesc: PropTypes.string,
  setNodeDesc: PropTypes.func,
  setNodeKey: PropTypes.func
};
