/**
 * @summary Memberships.js
 * @file Memberships panel in new/edit node form
 * @returns {JSX}
 * @usedBy NodeForm.js
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import KendoGridBase from '../../../shared/ui/kendoGridBase/KendoGridBase';
import store from '../../../store/store';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import { setNewNeighborhood } from 'store/neighborhoods/NeighborhoodActions';
import { normalizeName } from 'utility/normalizeName';

const Memberships = ({ setRefresh, refresh }) => {
  const gridColumns = [
    {
      field: 'neighborhoodName',
      title: 'Name',
      show: true,
      filterable: true,
      filter: 'text',
      width: '200px'
    },
    {
      field: 'description',
      title: 'Description',
      show: true,
      filterable: true,
      filter: 'text',
      cellType: 'ckeditor_content',
      width: '300px'
    },
    {
      field: 'checkedOutBy',
      title: 'Checked Out',
      show: true,
      filterable: true,
      filter: 'text',
      width: '200px'
    },
    {
      field: 'lastUpdated',
      title: 'Last Updated',
      show: true,
      filterable: true,
      filter: 'text',
      width: '200px'
    },
    {
      field: 'subGroup',
      title: 'Sub Group',
      show: true,
      filterable: true,
      filter: 'text',
      width: '200px'
    }
  ];

  const [gridState, setGridState] = useState({
    neighborhoods: { data: [], total: 0 },
    dataState: { take: 20, skip: 1 },
    gridDynamicColumns: gridColumns
  });

  const [filter, setFilter] = useState({});
  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 100 });
  const [, setLoading] = useState(false);
  const [, setSkipProcessing] = useState(0);
  const [selectedRowsStateArray, setSelectedStateArray] = useState([]);

  const navigate = useNavigate();

  const messageOptions = {
    messsageType: {
      success: 'Success'
    },
    messages: {
      successOnDelete: 'Form Deleted',
      errorOnDelete: 'Unable to Delete Connection',
      errorOnFetch: 'Unable to Load Connections',
      underConstruction: 'This Function is Under Construction'
    }
  };

  const { userObj, node, selectedProject, neighborhoods } = useSelector(
    (state) => ({
      userObj: state.authReducer.userObj,
      node: state.nodeReducer.selectedData[0],
      selectedProject: state.authReducer.userObj.selectedProject[0],
      neighborhoods: state.neighborhoodReducer
    })
  );

  const handlePageChange = (e) => {
    e.page.skip = isNaN(e.page.skip) ? 1 : e.page.skip;
    setPage(e.page);
  };

  const updateGridState = async () => {
    if (node.memberships?.length) {
      const { data } = await axios.get(
        `/api/projects/${userObj.selectedProject[0].id}/branch/${userObj.selectedBranch[0].id}/nodes/${node.id}/memberships/linkageLevels`
      );
      let gridDynamicColumnsArr = [];
      if (data.length) {
        gridDynamicColumnsArr = Object.values(data[0].settings).map((attr) => {
          return {
            field: attr.name,
            title: normalizeName(attr.name),
            show: true,
            filterable: true,
            filter: 'text',
            width: '150px'
          };
        });
      }

      const gridDynamicColumns = [...gridColumns, ...gridDynamicColumnsArr];

      setGridState({
        ...gridState,
        neighborhoods: {
          data,
          total: data?.length
        },
        dataState: { take: 20, skip: 0 },
        gridDynamicColumns
      });
    }
  };

  // When a Row is Selected...
  const selectionChange = (e) => {
    const data = gridState.neighborhoods.data.map((collection) => {
      // Adds Selected Data to an Array to Control Button Visibility
      if (collection === e.dataItem) {
        collection.selected = !e.dataItem.selected;
        if (collection.selected === true) {
          setSelectedStateArray((selectedRowsStateArray) => [
            ...selectedRowsStateArray,
            collection
          ]);
        } else {
          const filteredSelectedState = selectedRowsStateArray.filter(
            (selecteCheck) => selecteCheck.selected === true
          );
          setSelectedStateArray(filteredSelectedState);
        }
      }
      return collection;
    });

    const gridObjs = { ...gridState.neighborhoods };
    gridObjs.data = data;
    // store.dispatch(gridSelectedDataActions(e.dataItem));
    setGridState({ ...gridState, neighborhoods: gridObjs });
  };

  // Handles All Button and Row Click Actions
  const actionHandler = (action, selectedCollections) => {
    if (action === 'addNeighborhood') {
      store.dispatch(setNewNeighborhood());
      navigate('/neighborhoods/new');
      return;
    }
    if (action === 'delete') {
      return toast.success(messageOptions.messages.underConstruction);
    }
    return toast.error(`Node not checked out by ${userObj.screenName}`);
  };

  const handleGridFilterChange = (colFilter) => {
    setRefresh(true);
    setFilter(colFilter || {});
  };

  const handleGridSortChange = (obj) => {
    setRefresh(!refresh);
    // set initial sort order
    if (sort.length === 0 || sort[0].field !== obj[0].field) {
      setSort(obj);
      return;
    }

    // check if we should apply a desc, or reset sort completely
    if (sort[0].field === obj[0].field && sort[0].dir === 'asc') {
      sort[0].dir = 'desc';
      setSort(sort);
    } else if (sort[0].field === obj[0].field && sort[0].dir === 'desc') {
      setSort([]);
    }
  };

  useEffect(() => {
    updateGridState();
  }, [selectedProject]);

  useEffect(() => {
    updateGridState();
  }, []);

  useEffect(() => {
    setLoading(true);
    setLoading(false);
    setSkipProcessing(page.skip);
  }, [filter, sort, page, selectedProject]); // eslint-disable-line

  return (
    <div className="container-fluid">
      <KendoGridBase
        data={gridState.neighborhoods.data || []}
        gridColumns={gridState.gridDynamicColumns}
        setGridFilters={handleGridFilterChange}
        setGridSort={handleGridSortChange}
        onSelectionChange={selectionChange}
        onRowSingleClick={selectionChange}
        onPageChange={handlePageChange}
        sorter={sort}
        rowHeight={40}
        skip={page.skip}
        take={page.take}
        total={gridState.neighborhoods ? gridState.neighborhoods.total : 0}
        pageSize={100}
        selectable="selected"
        pageable
        sortable
        filterable
      />
      <div className="container-fluid p-0 d-flex justify-content-between mt-2">
        <div>
          {node?.id > 0 && (
            <button
              className="btn btn-success btn-sm text-white"
              type="button"
              onClick={() => {
                actionHandler('addNeighborhood', '(need function)');
              }}
            >
              <i className="bi bi-plus me-2" />
              Add Neighborhood
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
Memberships.propTypes = {
  setRefresh: PropTypes.func,
  refresh: PropTypes.bool,
  getConnections: PropTypes.func,
  gridSelectedDataActions: PropTypes.func
};

export default Memberships;
