/**
 * @summary addNodeModal.js
 * @file A modal which allows users to add nodes into a neighborhood
 * @returns {JSX}
 * @usedBy NeighbordhoodNodesSettings.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import store from '../../../store/store';
import { addNodesToNeighborhood } from 'store/neighborhoods/NeighborhoodActions';

const options = {
  messsageType: {
    success: 'Success'
  },
  messages: {
    successOnSave: 'Branch saved Successfully',
    errorOnSave: 'Unable to Save Branch'
  }
};

const AddNodeModal = ({ show, setShow }) => {
  const { nodes } = useSelector((state) => {
    const nodes = { ...state.nodeReducer };
    delete nodes?.selectedData;
    return {
      nodes: Object.values(nodes).filter(
        (node) =>
          !state.neighborhoodReducer.selectedData[0].nodeIds.includes(node.id) && node.branchId === state.authReducer.userObj.selectedBranch[0].id
      ),
      userObj: state.authReducer.userObj
    };
  });

  const [selectedNodes, setSelectedNodes] = useState([]);

  const gridRef = useRef();

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    const nodeIds = selectedRows.map((node) => node.id);
    setSelectedNodes(nodeIds);
  }, []);

  const [nodeCols] = useState([
    {
      field: 'name',
      headerName: 'Node Name',
      headerCheckboxSelection: true,
      checkboxSelection: true
    },
    { field: 'nodeKey', headerName: 'Node Key' },
    { field: 'description', headerName: 'Description' }
  ]);

  const onSave = () => {
    store.dispatch(addNodesToNeighborhood(selectedNodes));
  };

  return (
    <>
      <Modal show={show} backdrop="static" centered size="lg">
        <Modal.Header>
          <Modal.Title>Add Nodes to Neighborhood</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="ag-theme-alpine"
            style={{ height: 300, width: '100%', position: 'relative' }}
          >
            Nodes
            <AgGridReact
              ref={gridRef}
              columnDefs={nodeCols}
              rowData={nodes}
              rowSelection={'multiple'}
              onSelectionChanged={onSelectionChanged}
              rowMultiSelectWithClick={true}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger btn-sm text-white"
            onClick={() => setShow(false)}
          >
            Close
          </button>
          <button
            className="btn btn-primary btn-sm text-white"
            onClick={() => {
              onSave();
              setShow(false);
            }}
          >
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

AddNodeModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func
};

export default AddNodeModal;
