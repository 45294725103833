/**
 * @summary ProfileCheckInputs.js
 * @file Input options for Profile Check Report, which generates a list of nodes that contain the Subject/Accessibility Flags a user selects.
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import store from 'store/store';
import { getViews } from 'store/views/ViewActions'
import PropTypes from 'prop-types';
import axios from 'axios'
import { startCase } from 'lodash';
import { MultiSelect } from "@progress/kendo-react-dropdowns";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';

const ProfileCheckInputs = ({
    generateReport,
    project,
    userObj,
}) => {
    let navigate = useNavigate()
    const [mapDropdownOptions, setMapDropdownOptions] = useState([])
    const [selectedMap, setSelectedMap] = useState(null)

    // USE SELECTOR: Pulls branch and project data from Redux
    const { branches, views } = useSelector((state) => {
        const branchesList = { ...state.branchesDetailsReducer.activeBranches };
        const views = { ...state.viewReducer };
        delete branchesList.error;
        delete branchesList.selectedData;
        delete views?.selectedData;
        return {
            project: state.authReducer.userObj.selectedProject[0],
            branches: Object.values(branchesList).filter(Boolean),
            views: Object.values(views).filter((view) => view.isActive),
            userObj: state.authReducer.userObj,
        }
    })

    // USE EFFECT: Gets VIews for the Use Selector (for Menus)
    useEffect(() => {
        store.dispatch(getViews(userObj.selectedProject[0].id))
    }, [project])

    useEffect(() => {
        if(branches.length > 0 || views.length > 0){
            let branchOptions = branches.map(branch => {
                if(branch.name === "published"){
                    let branchObj = {
                        id: branch.id,
                        menuId: "branch" + branch.id.toString(),
                        menuName: "Published Branch",
                        name: branch.name,
                        type: "branch"
                    }
                    return branchObj
                } else {
                    let branchObj = {
                        id: branch.id,
                        menuId: "branch" + branch.id.toString(),
                        menuName: "Branch: " + branch.name,
                        name: branch.name,
                        type: "branch"
                    }  
                    return branchObj
                }
            })
            let viewOptions = views.map(view => {
                let viewObj = {
                    id: view.id,
                    menuId: "view" + view.id.toString(),
                    menuName: "View: " + view.name,
                    name: view.name,
                    type: "branch"
                }
                return viewObj
            })
            let mapOptions = branchOptions.concat(viewOptions)
            mapOptions.unshift({
                id: 0,
                menuId: 0,
                menuName: "Select Map Branch or View",
                name: "placeholder",
                type: "placeholder"
            })
            setMapDropdownOptions(mapOptions)
            setAccessibilityFlagSelections([])
            setSubjectSelection('')
        } else {
            setMapDropdownOptions([])
            setAccessibilityFlagSelections([])
            setSubjectSelection('')
        }
    }, [project])

    

    const [accessibilityFlags, setAccessibilityFlags] = useState([]) 
    const [accessibilityFlagSelections, setAccessibilityFlagSelections] = useState(null)

    const [subjectSelection, setSubjectSelection] = useState('')

    const handleMapSelection = (selectedMenuId) => {
        if(selectedMenuId !== "0"){
            let selection = mapDropdownOptions.find(branch => branch.menuId === selectedMenuId)
            if(selection.type === "branch"){
                setSelectedMap(selection)
                setAccessibilityFlagSelections([])
                setSubjectSelection('')
            } else {
                let viewMatch = mapDropdownOptions.find(view => view.menuId === selection.menuId)
                setSelectedMap(viewMatch)
                setAccessibilityFlagSelections([])
                setSubjectSelection('')
            }
        } else {
            setSelectedMap(null)
            setAccessibilityFlags([])
            setAccessibilityFlagSelections([])
            setSubjectSelection('')
        }
    }
    
    const { subjects } = useSelector((state) => {
        return {
            subjects: state.metadataReducer.subject,
        }
    })

    const getAccessibilityFlag = async () => {
        
        const resp = await axios.get(`/api/projects/${project.id}/branch/${selectedMap.id}/nodeProfileAttrs`)
        if(resp.data){
        const flags = resp.data.map((flag) => ({...flag, name: startCase(flag.name)}))
            setAccessibilityFlags(flags)
        } else {
            setAccessibilityFlags([])
        }        
    }

    useEffect(() => {
        if(selectedMap !== null){
            getAccessibilityFlag()
        }
    }, [selectedMap])

    const onClickProfileCheck = () => {
        const profileCheckData = {
            subject: subjects.find(subject => subject.name === subjectSelection),
            project,
            selectedMap,
            accessibilityFlagSelections
        }
        generateReport(profileCheckData)
    }
    
    let projectName = project.name
     ////////////////////
    // REDIRECT LINKS //
    ////////////////////
    function projectSettingsRedirect(){
        navigate('/setup')
    }

    function tableviewRedirect(){
        navigate('/tableview')
    }

    return(
    <Row>
        <Col>
            <div className='reporting-input-section'>
                <div className='reporting-instructions'>
                    <div className="selected-report-header">
                        {'Profile Check Report: ' + projectName}
                    </div>
                    <div>
                        <p>
                            This report will generate a list of nodes that match the Subject and Accessibility Flags you select below.
                        </p> 
                        <p>
                            Subjects and Accessibility Flags are configured in <strong style={{color: "#1560b7", cursor: "pointer"}} onClick={projectSettingsRedirect}>Project Settings</strong> under Node Settings. These attributes are assigned to nodes through the <strong style={{color: "#1560b7", cursor: "pointer"}} onClick={tableviewRedirect}>Tableview</strong> page, which you can view by editing a node.
                        </p> 
                        <p>
                            If the data you expect does not appear under these dropdowns, you may need to add these attributes through <strong style={{color: "#1560b7", cursor: "pointer"}} onClick={projectSettingsRedirect}>Project Settings</strong> and assign them to nodes through the <strong style={{color: "#1560b7", cursor: "pointer"}} onClick={tableviewRedirect}>TableView</strong> page - or switch to a project branch that has this data assigned.
                        </p>
                        
                    </div>
                </div>
                <div className='reporting-input-menus-container'>
                    <Row>
                        <div className='col-3'>
                            <div className="reporting-option-header">
                                Select a Map Option
                            </div>
                            <select
                                className="form-select form-select-sm"
                                aria-label=".form-select-sm example"
                                value={selectedMap?.menuId}
                                style={{width: '90%', height: '37px', marginLeft: '-2px'}}
                                onChange={(e) => {
                                    handleMapSelection(e.target.value)
                                }}
                                name="mapSelection"
                            >
                                    {mapDropdownOptions.map((map) => {
                                    return (
                                    <option key={map.menuId} value={map.menuId}>
                                        {map.menuName}
                                    </option>
                                    )})}
                            </select>
                        </div>
                        <div className='col-3'>
                            <div className="reporting-option-header">
                                Subject
                            </div>
                            <select
                                className="form-select form-select-sm"
                                style={{height: '37px', width: '90%', marginLeft: '-2px'}}
                                aria-label=".form-select-sm example"
                                value={subjectSelection}
                                placeholder={'Select Subject'}
                                onChange={(e) => {
                                    setSubjectSelection(e.target.value)
                                }}
                                name="subjectSelection"
                            >
                                <option value="0">Select a Subject</option>
                                    {subjects.map((subject) => (
                                        <option key={subject.id} value={subject.name}>
                                            {subject.name}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <div className='col-6'>
                            <div className="reporting-option-header">
                                Accessibility Flags
                            </div>
                            <MultiSelect
                                data={accessibilityFlags}
                                style={{
                                    paddingRight: "130px",
                                    color: "white"
                                    // maxHeight: "37px"
                                }}
                                dataItemKey='id'
                                textField='name'
                                value={accessibilityFlagSelections}
                                placeholder={'Multi Select the Accessibility Flags You Want to Include in Your Report'}
                                onChange={(e) => {
                                    setAccessibilityFlagSelections(e.target.value)
                                }}
                            >
                            </MultiSelect>
                        </div>  
                        
                    </Row>
                </div>
                <Row>
                    <div className='col-2'>
                        <div className='generate-report-button-container'>
                            <button
                                type="button"
                                className="k-button k-primary generate-report-button"
                                onClick={onClickProfileCheck}
                                disabled={!selectedMap || !subjectSelection || !accessibilityFlagSelections}
                            >
                                Generate Report
                            </button>
                        </div>
                    </div>
                    <div className='col-10'>
                        <div className='map-options-fine-print-container'>
                            <p className="map-options-fine-print">
                                {`Map options are limited to the project you have selected under your Profile Settings in the upper right corner. You currently have "` + projectName + `" selected.`}
                            </p>
                        </div>
                    </div>
                </Row>   
            </div>
        </Col>
    </Row>
    )
}

export default ProfileCheckInputs;

ProfileCheckInputs.propTypes = {
    generateReport: PropTypes.func,
    mapType: PropTypes.any,
    project: PropTypes.object,
    branches: PropTypes.array,
    views: PropTypes.array,
    userObj: PropTypes.obj
};