/**
 * @summary axiosMapData.js
 * @file Util: Runs Get Functions to Return Map Data for CanvasPage.js
 * @returns {JSX}
 * @usedBy CanvasWrapper.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import axios from 'axios'

export const axiosCanvasMapData = async (profileSelectionObj) => {

    const {userObj, selectedProject, selectedBranch} = profileSelectionObj;
  
    const nodes = await axios.get(`/api/projects/${selectedProject.id}/branch/${selectedBranch.id}/nodes`)
    const connections = await axios.get(`/api/projects/${selectedProject.id}/branch/${selectedBranch.id}/connections`)
    const neighborhoods = await axios.get(`/api/projects/${selectedProject.id}/branch/${selectedBranch.id}/neighborhoods`)
    // const views = await axios.get(`/api/projects/${selectedProject.id}/views`)
    const media = await axios.get( `/api/${selectedProject.id}/projects`)

    //////////////////////////////////////////////////////////////////////////
    // IF SELECTION IS THE PUBLISHED BRANCH, RETURN THE FULL AXIOS RESPONSE //
    //////////////////////////////////////////////////////////////////////////
      // If No Nodes or Connections, Throw an Error Because the Report Will Have No Data
      let errorMessages = []
      if(nodes.data.length === 0){
        errorMessages.push("There are no nodes in this branch.")
      } else if(connections.data.length === 0){
        errorMessages.push("None of the nodes in this branch are connected. Try checking out a branch with connected nodes.")
      }


    // FINAL RESPONSE: PUBLISHED BRANCH //
    const axiosResponse = {
      nodes: nodes.data,
      connections: connections.data,
      neighborhoods: neighborhoods.data,
      errorMessages,
    }


    return axiosResponse
}