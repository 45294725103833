/**
 * @summary LinkageLevelsSettings.js
 * @file allows user to add & delete short/long descriptions and node descriptors to their linkage levels
 * @returns {JSX}
 * @usedBy NeighborhoodForm.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useEffect, useState } from 'react';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import LinkageLevelMediaGrid from './LinkageLevelMediaGrid';
import { useSelector } from 'react-redux';
import store from '../../../store/store';
import PropTypes from 'prop-types';
import CKEditor from 'shared/ckEditor/CKEditor';
import { getLinkagelevelNeighborhood } from 'store/neighborhoods/NeighborhoodActions';
import Panel from '../../../shared/accordian/Panel';
import parse from 'html-react-parser';
import { Button, Row } from 'react-bootstrap';

const LinkageLevelSettings = ({
  project,
  userObj,
  updateSelectedLinkageLevels,
  selectedLinkageLevels,
  updateLinkageLevelMedia,
  updateLinkageLevels
}) => {
  const [, setRefresh] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [openEditor, setOpenEditor] = useState('');

  const { potentialLinkageLevels, nodes, neighborhood } = useSelector(
    (state) => {
      const nodes = { ...state.nodeReducer };
      delete nodes?.selectedData;
      return {
        potentialLinkageLevels: state.linkageLevelReducer.linkageLevels,
        nodes,
        neighborhood: state.neighborhoodReducer.selectedData[0]
      };
    }
  );

  useEffect(() => {
    getSelectedValues();
    availableNeighborhoodNodes();
    setTimeout(() => {
      setShowEditor(true)
    }, 1000);
  }, []);
  
  const onClickEdit = (llObj, descType, idx) => {
    setOpenEditor(`${llObj.name}-${descType}`);
  }

  const getSelectedValues = () => {
    store
      .dispatch(
        getLinkagelevelNeighborhood(
          userObj.selectedProject[0].id,
          userObj.selectedBranch[0].id,
          neighborhood?.id
        )
      )
      .then(() => {
        const neighborhoodState =
          store.getState().neighborhoodReducer.selectedLinkageLevels;

        if (potentialLinkageLevels) {
          const allLinkageLevels = potentialLinkageLevels.map((ll, idx) => {
            const foundLL = neighborhoodState.find(
              (selectedLL) => selectedLL.linkageLevelId === ll.id
            );
            if (foundLL) {
              return foundLL;
            }
            return {
              id: -Math.abs(idx),
              levelOrder: ll.levelOrder,
              linkageLevelId: ll.id,
              linkageLevelName: ll.name,
              longDescription: '',
              shortDescription: '',
              neighborhoodId: neighborhood?.id,
              nodes: [],
              projectId: ll.projectId,
              linkageLevelMedia: []
            };
          });
          const sortedLinkageLevels = allLinkageLevels.sort((a, b) =>
            a.levelOrder > b.levelOrder ? 1 : -1
          );
          updateSelectedLinkageLevels(sortedLinkageLevels);
        }
      });
  };

  const updateValuesForMultiSelect = (nodes, incomingRow, idx) => {
    let updatedList = { ...selectedLinkageLevels[idx] };
    let array = [...selectedLinkageLevels];
    updatedList.nodes = nodes;
    updatedList.toSave = true;
    array.splice(idx, 1, updatedList);
    updateSelectedLinkageLevels(array);
  };

  const updateShortDescription = (key, value, idx) => {
    let updatedList = { ...selectedLinkageLevels[idx] };
    let array = [...selectedLinkageLevels];
    if (key === 'shortDescription') {
      updatedList.shortDescription = value;
    }
    if (key === 'longDescription') {
      updatedList.longDescription = value;
    }
    updatedList.toSave = true;
    array.splice(idx, 1, updatedList);
    updateSelectedLinkageLevels(array);
  };

  const availableNeighborhoodNodes = () => {
    if (neighborhood?.nodeIds) {
      const availableNodes = neighborhood?.nodeIds.map((value) => {
        return nodes[value];
      });
      return availableNodes;
    }
    return [];
  };

  const selectedNeighborhoodNodes = (linkageLevel) => {
    const selectedNodeKeys = [];
    if (!linkageLevel.nodes) {
      return [];
    } else {
      linkageLevel.nodes.map((value) => {
        if (!value.name) {
          selectedNodeKeys.push({ id: value.id, name: value.nodeKey });
        } else {
          selectedNodeKeys.push(value);
        }
      });
      return selectedNodeKeys;
    }
  };


  const renderHTML = (incomingRow, idx) => {
    return (
      <Panel
        title={
          project.settings.linkageLevels.find((linkageLevel) =>
            linkageLevel.id === incomingRow.linkageLevelId
              ? linkageLevel.name
              : null
          ).name
        }
        body={
          <>
            <div className="pb-1 gap-2">
              <label
                className="form-label hstack gap-0"
                htmlFor={`linkage-nodes-multi-select-${idx}`}
              >
                Nodes:
              </label>
              <MultiSelect
                id={`linkage-nodes-multi-select-${idx}`}
                data={availableNeighborhoodNodes()}
                value={selectedNeighborhoodNodes(incomingRow, idx)}
                onChange={(e) => {
                  updateValuesForMultiSelect(e.value, incomingRow, idx);
                }}
                dataItemKey="id"
                textField="name"
              />
            </div>
            <>
              <Row>
                <label
                    className=""
                    htmlFor="longDescription"
                  >
                    Short Description:
                  </label>
              </Row>
              <Row className="p-3">
                {openEditor === `${incomingRow.name}-short` ? (<CKEditor
                  data={incomingRow.shortDescription}
                  fieldName="shortDescription"
                  onChange={(key, value) =>
                    updateShortDescription(key, value, idx)
                  }
                />) : (incomingRow.shortDescription ? parse(incomingRow.shortDescription) : 'No Desciption given' )}
              </Row>
                <Button onClick={() => onClickEdit(incomingRow, 'short')}>Edit</Button>
              <Row>
                <label
                  className=""
                  htmlFor="longDescription"
                >
                  Long Description:
                </label>
              </Row>
              <Row className="p-3">
              {openEditor === `${incomingRow.name}-long` ? (<CKEditor
                  data={incomingRow.longDescription}
                  fieldName="longDescription"
                  onChange={(key, value) =>
                    updateShortDescription(key, value, idx)
                  }
                />) : (incomingRow.longDescription ? parse(incomingRow.longDescription) : 'No Desciption given' )}
              </Row>
                <Button onClick={() => onClickEdit(incomingRow, 'long')}>Edit</Button>
            </>
            <>
              <label
                className="form-label hstack gap-0"
                htmlFor="form-header-desc"
              >
                Media Grid:
              </label>
              <LinkageLevelMediaGrid
                setRefresh={setRefresh}
                neighborhood={neighborhood}
                userObj={userObj}
                incomingLinkageLevel={incomingRow}
                updateMedia={updateLinkageLevelMedia}
                updateSelectedLinkageLevels={updateSelectedLinkageLevels}
                updateLinkageLevels={updateLinkageLevels}
              />
            </>
          </>
        }
      />
    );
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col p-2 justify-content-between">
          <fieldset className="bg-light p-3 rounded">
            <legend>Linkage Level</legend>
            {!selectedLinkageLevels.length ? (
              <p>no Linkage Levels selected</p>
            ) : (
              showEditor && selectedLinkageLevels.map((incomingRow, idx) => (
                <>{renderHTML(incomingRow, idx)}</>
              ))
            )}
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export default LinkageLevelSettings;

LinkageLevelSettings.propTypes = {
  getLinkagelevelNeighborhood: PropTypes.func,
  updateSelectedLinkageLevels: PropTypes.func,
  selectedLinkageLevels: PropTypes.object,
  setShortDesc: PropTypes.func,
  setLongDesc: PropTypes.func,
  shortDesc: PropTypes.string,
  longDesc: PropTypes.string,
  neighborhood: PropTypes.object,
  userObj: PropTypes.object,
  project: PropTypes.object,
  updateLinkageLevelMedia: PropTypes.func,
  updateLinkageLevels: PropTypes.func
};
