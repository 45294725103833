/**
 * @summary connectionSettings.js
 * @file connection settings form component for new/edit project page
 * @returns {JSX}
 * @usedBy newForm.js
 * @author Dj Ritchey
 * @since 02/17/2021
 * @lastUpdated 08/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect, useCallback } from 'react';
import store from '../../../store/store';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import {
  updateCheckboxValues,
  updateSelectedDataType,
  updateMultiSelect,
  updateQtyValue,
  normalizeName,
  isChecked,
  shouldDisable
} from '../../../utility/updateFields';
import { text, dropDown, multiSelect } from '../../../utility/constants';
import {
  setConnectionSelectedValues,
  updateConnectionProjectSettings,
  removeCheckedValue
} from '../../../store/ProjectSetup/ProjectSetupActions';
import ValueHeader from '../shared/valueHeader';
import { setSelectedValues } from '../../../utility/setSelectedValues';
import { useLocation } from 'react-router-dom';

const messageOptions = {
  messsageType: {
    success: 'Success'
  },
  messages: {
    errorOnCreate: 'Field name already in use!',
    underConstruction: 'This Function is Under Construction'
  }
};

const ConnectionSettings = () => {
  const location = useLocation();
  const [newField, setNewField] = useState({
    name: '',
    isTypeChangable: true,
    dataType: '',
    category: '',
    checked: true,
    hasQty: false,
    qty: 1,
    value: [],
    isNewField: true
  });

  const [defaultValues, setDefaultValues] = useState([
    'observation',
    'citation',
    'citation_category',
    'connection_type',
    'connection_name',
    'tags'
  ]);

  const defaultCheckBoxObj = {
    profile_details: ['observation', 'citation', 'citation_category'],
    tags: ['tags']
  };

  const { project, selectedValues, settings } = useSelector((state) => {
    return {
      project: state.projectSetupReducer,
      selectedValues: state.projectSetupReducer.connectionsSelectedValues,
      settings: state.projectSetupReducer.settings
    };
  });

  const [checkboxValues, setCheckBoxValue] = useState(defaultCheckBoxObj);

  const [order, setOrder] = useState(defaultValues);

  const changableTypes = [text, dropDown, multiSelect];

  const updateSelectedValues = (attribute) => {
    if (attribute.checked) {
      store.dispatch(
        setConnectionSelectedValues([
          ...selectedValues,
          setSelectedValues(attribute)
        ])
      );
    } else {
      store.dispatch(
        removeCheckedValue('connectionsSelectedValues', attribute)
      );
    }
  };
  const updateCheckboxValuesFn = (fieldName) => {
    const list = updateCheckboxValues(
      fieldName.category,
      fieldName,
      checkboxValues
    );
    setCheckBoxValue({ ...list });
  };

  const updateSelectedDataTypeFn = ({ value }, obj) => {
    const newSelection = updateSelectedDataType(selectedValues, value, obj);
    store.dispatch(setConnectionSelectedValues([...newSelection]));
  };

  const updateQtyValueFn = (value, obj) => {
    const list = updateQtyValue(selectedValues, value, obj);
    store.dispatch(setConnectionSelectedValues([...list]));
  };

  const updateValuesForFieldFn = (e, attribute) => {
    const list = updateMultiSelect(selectedValues, e, attribute);
    store.dispatch(setConnectionSelectedValues([...list]));
  };

  // should be triggered when selectedValues changes, or new field added
  const addCustomFields = useCallback(() => {
    selectedValues.forEach((val) => {
      updateCheckboxValues(val.category, val, checkboxValues);
      const inOrder = order.includes(val.name);
      if (!inOrder) {
        setOrder([...order, val.name]);
      }
    });
  }, [checkboxValues, order, selectedValues]);

  const updateProject = useCallback(() => {
    store.dispatch(
      updateConnectionProjectSettings({ attributes: selectedValues })
    );
  }, [selectedValues]);

  const saveField = () => {
    const existingField = selectedValues.find(
      (selected) => selected.name.toLowerCase() === newField.name.toLowerCase()
    );
    if (existingField) {
      toast.error(messageOptions.messages.errorOnCreate);
    } else {
      updateSelectedValues(newField);
      setOrder([...order, newField.name]);
      setDefaultValues([...defaultValues, newField.name]);
      updateCheckboxValuesFn(newField);
      setNewField({ ...newField, name: '', category: '' });
    }
  };

  //render delete icon only for custom fields in checkbox area
  const renderRemoveIcon = (attr) => {
    return !defaultValues.some(
      (valueName) => valueName.toLowerCase() === attr.toLowerCase()
    );
  };

  // find custom attr, and remove references from order, checkboxes, and selectedValues
  const removeCustomField = (attr, key, isChecked) => {
    const copyCheckboxValues = [...checkboxValues[key]];
    const findCheckboxValueIdx = checkboxValues[key].findIndex(
      (attributeName) => attributeName.toLowerCase() === attr.toLowerCase()
    );
    if (isChecked) {
      const attrObj = selectedValues.find(
        (attribute) => attribute.name === attr.toLowerCase()
      );
      attrObj.checked = false;
      updateSelectedValues(attrObj);
    }
    setOrder(defaultValues);
    copyCheckboxValues.splice(findCheckboxValueIdx, 1);
    setCheckBoxValue({ ...checkboxValues, [key]: copyCheckboxValues });
  };

  // if name exists in the selected values array, render details to UI
  const shouldRender = (selectedValues, name) => {
    const foundObj = selectedValues.find((selected) => selected.name === name);
    return foundObj;
  };

  useEffect(() => {
    addCustomFields();
  }, [settings, selectedValues, addCustomFields, location.pathname]);

  useEffect(() => {
    updateProject();
  }, [selectedValues, updateProject]);

  useEffect(() => {
    setCheckBoxValue(defaultCheckBoxObj);
    setOrder(defaultValues);
  }, [location.pathname, project]);

  const renderDataTypeInputMarkup = (attribute) => {
    if (attribute.isTypeChangable) {
      return (
        <select
          onChange={(e) => updateSelectedDataTypeFn(e.target, attribute)}
          title={`data-type-for-${attribute.name}`}
        >
          {attribute.id !== null || attribute.dataType ? (
            <option value={attribute.dataType}>{attribute.dataType}</option>
          ) : (
            <option value="0">Select a data type</option>
          )}
          {changableTypes
            .filter((type) => type !== attribute.dataType)
            .map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
        </select>
      );
    }
    return <div style={{ marginLeft: '8px' }}>{attribute.dataType}</div>;
  };

  const renderQtyInputMarkup = (attribute) => {
    if (attribute.hasQty && attribute.dataType === 'Text') {
      return (
        <input
          type="number"
          min="1"
          max="100"
          value={attribute.qty}
          onChange={(e) => {
            updateQtyValueFn(parseInt(e.target.value), attribute);
          }}
        />
      );
    }
    return <div style={{ marginLeft: '8px' }}>{attribute.qty}</div>;
  };

  const renderValuesMultiSelectMarkup = (attribute) => {
    if (attribute.dataType === 'Text') return;
    return [...Array(attribute.qty)].map((attr, idx) => (
      <MultiSelect
        key={`basic-${idx}`}
        id={`field-name-${attribute.name}`}
        data={[]}
        allowCustom
        value={attribute.value}
        popupSettings={{ popupClass: 'hidden-popup' }}
        onChange={(e) => {
          updateValuesForFieldFn(e.target, attribute);
        }}
      />
    ));
  };

  const getSelectableValueRows = () => {
    const selectedAttrs = order
      .map((name) => shouldRender(selectedValues, name))
      .filter(Boolean);
    return selectedAttrs.map((attribute) => (
      <div key={`selected-attr-${attribute.name}`}>
        <div className="row p-2 justify-content-between">
          <div className="col">
            <div className="p-2">
              <label htmlFor={`field-name-${attribute.name}`}>
                {normalizeName(attribute.name)}
              </label>
            </div>
          </div>

          <div className="col">
            <div className="p-2">
              <div>{renderDataTypeInputMarkup(attribute)}</div>
            </div>
          </div>

          <div className="col-1">
            <div className="p-2">
              <div>{renderQtyInputMarkup(attribute)}</div>
            </div>
          </div>
          {
            <div className="col-6">
              <div className="p-2">
                <div>{renderValuesMultiSelectMarkup(attribute)}</div>
              </div>
            </div>
          }
        </div>
      </div>
    ));
  };

  const renderCheckboxesMarkup = () => {
    const keys = Object.keys(checkboxValues);
    return (
      <div>
        {keys.map((key, idx) => (
          <div key={`all-keys${idx}`} className="col">
            <label className="form-check-label">{normalizeName(key)}</label>
            {checkboxValues[key].map((attribute, $idx) => (
              <div
                key={`checked-box-${attribute}-${$idx}`}
                className="row-1 form-check"
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={isChecked(selectedValues, attribute)}
                  id={attribute}
                  onChange={() =>
                    updateSelectedValues({
                      name: attribute,
                      checked: !isChecked(selectedValues, attribute),
                      category: key
                    })
                  }
                  disabled={shouldDisable(selectedValues, attribute)}
                ></input>
                <label className="form-check-label" htmlFor={attribute}>
                  {normalizeName(attribute)}
                </label>
                {renderRemoveIcon(attribute, key) &&
                  !shouldDisable(selectedValues, attribute) && (
                    <span
                      onClick={() => {
                        removeCustomField(
                          attribute,
                          key,
                          isChecked(selectedValues, attribute)
                        );
                      }}
                      className="k-icon k-i-minus-outline"
                      style={{ hidden: true }}
                    />
                  )}
              </div>
            ))}
          </div>
        ))}

        <div className="row">
          <label className="form-label">New Field</label>
        </div>

        <div className="row">
          <div className="col">
            <label className="form-label" htmlFor="new-connection-attr-name">
              Name:
            </label>
            <input
              id="new-connection-attr-name"
              value={newField.name}
              onChange={(e) => {
                setNewField({ ...newField, name: e.target.value });
              }}
            />
          </div>
        </div>

        <div className="d-flex p-2 justify-content-between">
          <div className="text-end">
            <label className="form-label" htmlFor="new-connection-attr-type">
              Field Type:
              <i className="text-danger"> *</i>
            </label>
          </div>
          <div className="col">
            <select
              id="new-connection-attr-type"
              name="fieldCat"
              className="form-select form-select-sm"
              required
              value={newField.category}
              style={{ width: '200px' }}
              onChange={(e) => {
                setNewField({ ...newField, category: e.target.value });
              }}
            >
              <option value="">Select a Field Type</option>
              <option key={'profile_details'} value={'profile_details'}>
                {normalizeName('profile_details')}
              </option>
            </select>
          </div>
        </div>
        <div className="row">
          <div>
            <button
              disabled={newField.name === '' || newField.category === ''}
              onClick={(e) => {
                saveField();
              }}
            >
              Create Field
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col p-2 justify-content-between">
          <fieldset className="bg-light p-3 rounded mt-1">
            <legend>Select Fields to add</legend>
            <div className="row p-2">{renderCheckboxesMarkup()}</div>
          </fieldset>

          <fieldset className="bg-light p-3 rounded mt-1">
            <legend>Data Types and Selectable Values</legend>
            <div className="row p-2">
              <ValueHeader />
              {getSelectableValueRows()}
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export default ConnectionSettings;
