/**
 * @summary App.js
 * @file Main landing component for the application
 * @returns {JSX}
 * @usedBy Index.js
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AllRoutes from './shared/ui/routes/routes';
import Layout from './pages/layout';
import { getProjects } from 'store/projects/ProjectActions';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from './shared/ui/Spinner';
import { retrieveMetadata } from './store/metadata/MetadataAction';
import { getUser, userStorage } from './store/auth/AuthActions';
import '@progress/kendo-theme-bootstrap/dist/all.css';

const App = () => {
  const [appLoaded, setAppLoaded] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((store) => ({
    user: store.authReducer.userObj
  }));

  useEffect(() => {
    setAppLoaded(true);
    if (user.currentAssessmentProgram) {
      dispatch(getProjects(user.currentAssessmentProgram));
      dispatch(retrieveMetadata(user.currentAssessmentProgram));
    }
  }, [user]);

  useEffect(() => {
    if (user.emailAddress) {
      dispatch(getUser(user.emailAddress));
    }
  }, []);

  useEffect(() => {
    // Before starting app, look at sessionStorage for user
    dispatch(userStorage());
    setAppLoaded(true);
  }, [dispatch]);

  if (!appLoaded) {
    return <Spinner />;
  }

  return (
    <>
      <Router>
        <Layout user={user}>
          <AllRoutes />
        </Layout>
      </Router>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        draggable
        theme="colored"
      />
    </>
  );
}

export default App;
