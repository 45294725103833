/**
 * @summary ConnectionsReducer.jsx
 * @file Connection Reducer
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import {
  CREATE_CONNECTION,
  GET_CONNECTIONS,
  GRID_DATA_ACTIONS_SELECTIONS_CONNECTIONS_FULLFILLED,
  CLEAR_CONNECTIONS,
  EDIT_CONNECTIONS,
  GET_CONNECTION,
  SET_NEW_CONNECTION,
  EDIT_CONNECTION,
  DELETE_CONNECTION,
  DELETE_MULTIPLE_CONNECTIONS
} from './ConnectionTypes';

const connections = {
  selectedData: []
};

/**
 * @summary
 * This redux reducer is used for connection CRUD based on the action type
 *
 * @param {connections} state
 * @param {reduxAction} action
 */

let copyConnection;

const connectionReducer = (state = connections, action) => {
  switch (action.type) {
    case GET_CONNECTIONS:
      return {
        selectedData: [],
        // keep all incoming nodes inside a single level
        ...action.connections.reduce((newObj, connection) => {
          newObj[connection.id] = connection;
          return newObj;
        }, {})
      };

    case GET_CONNECTION:
      return {
        ...state,
        selectedConn: action.selectedConn
      };

    case CREATE_CONNECTION:
      return {
        ...state,
        [action.meta.id]: {
          ...action.connection,
          id: action.meta.id
        },
        selectedData: [action.connection]
      };

    case EDIT_CONNECTIONS:
    case EDIT_CONNECTION:
      return {
        ...state,
        [action.meta.id]: {
          ...action.connection,
          id: action.meta.id
        }
      };

    case GRID_DATA_ACTIONS_SELECTIONS_CONNECTIONS_FULLFILLED:
      let copySelectedData = [...state.selectedData];
      const isArr = Array.isArray(action.connections.data);
      if (isArr && action.connections.data[0].selected) {
        copySelectedData = action.connections.data;
      } else if (!isArr) {
        const idx = copySelectedData.findIndex(
          (connection) => connection.id === action.connections.data.id
        );
        idx < 0
          ? copySelectedData.push(action.connections.data)
          : copySelectedData.splice(idx, 1);
      } else {
        copySelectedData = [];
      }

      return {
        ...state,
        selectedData: copySelectedData
      };

    case CLEAR_CONNECTIONS:
      return {
        selectedData: []
      };

    case SET_NEW_CONNECTION:
      return {
        ...state,
        selectedData: [
          ...state.selectedData,
          {
            id: -1,
            name: '',
            tags: [],
            sourceNodeId: '',
            destinationNodeId: '',
            connectionType: '',
            lastUpdated: null,
            settings: {
              profileDetails: []
            }
          }
        ]
      };

    case DELETE_CONNECTION:
      copyConnection = { ...state };
      delete copyConnection[action.connection.id];
      return {
        ...copyConnection,
        selectedData: []
      };

    case DELETE_MULTIPLE_CONNECTIONS:
      copyConnection = { ...state };
      action.connectionIds.forEach((id) => {
        delete copyConnection[id];
      });
      return {
        ...copyConnection,
        selectedData: []
      };

    default:
      return state;
  }
};
export default connectionReducer;
