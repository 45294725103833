/**
 * @summary DegreeHistogramInput.js
 * @file Input Options and Functionality for Degree Histogram Report. Degree Histogram returns nodes with outgoing connections whose amounts match the user's inputs.
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import store from 'store/store';
import { getViews } from 'store/views/ViewActions'
import PropTypes from 'prop-types';

import {
    NumericTextBox 
  } from "@progress/kendo-react-inputs";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const DegreeHistogramInputs = ({
    generateReport,
    project,
    userObj,
}) => {
    const [mapDropdownOptions, setMapDropdownOptions] = useState([])
    const [selectedMap, setSelectedMap] = useState()

    // USE SELECTOR: Pulls branch and project data from Redux
    const { branches, views } = useSelector((state) => {
        const branchesList = { ...state.branchesDetailsReducer.activeBranches };
        const views = { ...state.viewReducer };
        delete branchesList.error;
        delete branchesList.selectedData;
        delete views?.selectedData;
        return {
        project: state.authReducer.userObj.selectedProject[0],
        branches: Object.values(branchesList).filter(Boolean),
        views: Object.values(views).filter((view) => view.isActive),
        userObj: state.authReducer.userObj,
        }
    })

    // USE EFFECT: Gets VIews for the Use Selector (for Menus)
    useEffect(() => {
        store.dispatch(getViews(userObj.selectedProject[0].id))
    }, [])
    
    useEffect(() => {
        if(branches.length > 0 || views.length > 0){
            let branchOptions = branches.map(branch => {
                if(branch.name === "published"){
                    let branchObj = {
                        id: branch.id,
                        menuId: "branch" + branch.id.toString(),
                        menuName: "Published Branch",
                        name: branch.name,
                        type: "published branch"
                    }
                    return branchObj
                } else {
                    let branchObj = {
                        id: branch.id,
                        menuId: "branch" + branch.id.toString(),
                        menuName: "Branch: " + branch.name,
                        name: branch.name,
                        type: "branch"
                    }  
                    return branchObj
                }
            })
            let viewOptions = views.map(view => {
                let viewObj = {
                    id: view.id,
                    menuId: "view" + view.id.toString(),
                    menuName: "View: " + view.name,
                    name: view.name,
                    type: "view"
                }
                return viewObj
            })
            let mapOptions = branchOptions.concat(viewOptions)
            mapOptions.unshift({
                id: 0,
                menuId: 0,
                menuName: "Select Map Branch or View",
                name: "placeholder",
                type: "placeholder"
            })
            setMapDropdownOptions(mapOptions)
            setMaxConnections(10)
            setMinConnections(1)
        } else {
            setMapDropdownOptions([])
            setMaxConnections(10)
            setMinConnections(1)
        }
    }, [project])

    const [minConnections, setMinConnections] = useState(1)
    const [maxConnections, setMaxConnections] = useState(10)

    const onClickCycleCheck = () => {
        const sendData = {
            minConnections,
            maxConnections,
            project,
            branchSelection: selectedMap,
        }
        generateReport(sendData)
    }

    const handleMapSelection = (selectedMenuId) => {
        if(selectedMenuId !== "0"){
            let selection = mapDropdownOptions.find(branch => branch.menuId === selectedMenuId)
             if(selection.type === "branch"){
                setSelectedMap(selection)
                setMaxConnections(10)
                setMinConnections(1)
            } else {
                let viewMatch = mapDropdownOptions.find(view => view.menuId === selection.menuId)
                setSelectedMap(viewMatch)
                setMaxConnections(10)
                setMinConnections(1)
            }
        } else {
            setSelectedMap(null)
            setMaxConnections(10)
            setMinConnections(1)
        }
    }

    let projectName = project.name
    return(
    <Row>
        <Col>
            <div className='reporting-input-section'>
                <div className='reporting-instructions'>
                    <div className='selected-report-header'>
                        {'Degree Histogram Report: ' + project.name}
                    </div>
                    <div>
                        <p>
                            This report will display the incoming and outgoing connections for every node whose connections fall within the minimum and maximum amount you set below.</p>            
                        <p>
                            Choose a branch or view you would like to inspect, narrow your results to only show nodes within a minimum and maximum number of connections, 
                            and click Generate Report to see the results.
                        </p>
                    </div>
                </div>
                <div className='reporting-input-menus-container'>
                    <Row>
                        <div className='col-3'>
                            <div className="reporting-option-header">
                                Select a Map Option
                            </div>
                            <select
                                className="form-select form-select-sm"
                                aria-label=".form-select-sm example"
                                value={selectedMap?.menuId}
                                style={{width: '90%', height: '37px', width: '90%', marginLeft: '-2px'}}
                                onChange={(e) => {
                                    handleMapSelection(e.target.value)
                                }}
                                name="mapSelection"
                            >
                                {mapDropdownOptions.map((map) => (
                                <option key={map.menuId} value={map.menuId}>
                                    {map.menuName}
                                </option>
                                ))}
                            </select>
                        </div>
                        <div className='col-3'>
                            <div className="reporting-option-header">
                                Minimum Number of Connections
                            </div>
                            <NumericTextBox
                                max={100}
                                min={0}
                                className="numeric-text-box"
                                value={minConnections}
                                onChange={(e) => {
                                    setMinConnections(e.target.value)
                                }}
                            />
                        </div> 
                        <div className='col-3'>
                            <div className="reporting-option-header">
                                Maximum Number of Connections
                            </div>
                            <NumericTextBox
                                max={100}
                                min={0}
                                className="numeric-text-box"
                                value={maxConnections}
                                onChange={(e) => {
                                    setMaxConnections(e.target.value)
                                }}
                            />
                        </div>  
                    </Row>
                </div>
                <Row>
                    <div className="col-2">
                        <div className="generate-report-button-container">
                            <button
                                type="button"
                                className="k-button k-primary generate-report-button"
                                onClick={onClickCycleCheck}
                                disabled={!selectedMap}
                            >
                                Generate Report
                            </button>
                        </div>
                    </div>
                    <div className='col-10'>
                        <div className='map-options-fine-print-container'>
                            <p className="map-options-fine-print">
                                {`Map options are limited to the project you have selected under your Profile Settings in the upper right corner. You currently have "` + projectName + `" selected.`}
                            </p>
                        </div>
                    </div>
                </Row>
            </div>
        </Col>
    </Row>
    )
}

export default DegreeHistogramInputs;

DegreeHistogramInputs.propTypes = {
    generateReport: PropTypes.func,
    setMapType: PropTypes.func,
    mapType: PropTypes.any,
    nodes: PropTypes.array,
    project: PropTypes.object,
    branches: PropTypes.array,
    views: PropTypes.array,
    userObj: PropTypes.obj
};