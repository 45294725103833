/**
 * @summary Node String Types
 * @file NodeTypes.js
 * @returns {String}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

export const CREATE_NODE = 'CREATE_NODE';

export const GET_NODES = 'GET_NODES';

export const DELETE_NODE = 'DELETE_NODE';

export const GRID_DATA_ACTIONS_SELECTIONS_NODES_FULLFILLED =
  'GRID_DATA_ACTIONS_SELECTIONS_NODES_FULLFILLED';

export const CLEAR_NODES = 'CLEAR_NODES';

export const EDIT_NODES = 'EDIT_NODES';

export const GET_NODE = 'GET_NODE';

export const GET_NODE_SETTINGS = 'GET_NODE_SETTINGS';

export const UPDATE_NODES_BY_NEIGHBORHOOD = 'UPDATE_NODES_BY_NEIGHBORHOOD';

export const ADD_NODE_MEDIA = 'ADD_NODE_MEDIA';

export const SET_NEW_NODE = 'SET_NEW_NODE';

export const CLEAR_SELECTED_NODES = 'CLEAR_SELECTED_NODES';

export const GET_MEMBERSHIPS = 'GET_MEMBERSHIPS';

export const UPDATE_COMMENT = 'UPDATE_COMMENT';

export const ADD_COMMENT = 'ADD_COMMENT';

export const DELETE_COMMENT = 'DELETE_COMMENT';

export const DELETE_MULTIPLE_NODES = 'DELETE_MULTIPLE_NODES';
