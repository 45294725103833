/**
 * @summary CanvasFilter.js
 * @file Modal for Containing Filter Options - Enables User to Select a Filter Type. Opened through CanvasInterface.js.
 * @returns {JSX}
 * @usedBy CanvasPage.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */


import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './filterStyle.scss';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import { resetSelectedViewDataAction } from 'store/views/ViewActions';
import store from 'store/store';
import { toast } from 'react-toastify';


import GlobalFilterMainMenu from './filterSubmenus/GlobalFilterMainMenu';
import NodeRadiusFilter from './filterSubmenus/NodeRadiusFilter';
import NeighborhoodFilter from './filterSubmenus/NeighborhoodFilter';

import nodeRadiusFilterUtil from '../utils/filterUtils/nodeRadiusFilterUtil'
import neighborhoodFilterUtil from '../utils/filterUtils/neighborhoodFilterUtil'
import { globalFilterUtil } from '../utils/filterUtils/globalFilterUtil';

const CanvasFilter = ({
  handleFilterDisplay,
  diagramData,
  renderMapData,
  showFilterMenu,
  globalKeywordInput,
  setGlobalKeywordInput,
  filteredMapData,
  handleFilteredMapData,
  setFilter,
  filtered,
  setRenderMapData,
  selectedView,
  handleViewLoad
}) => {

  // Filter Menu Selection
  const [filterMenuSelection, setFilterMenuSelection] = useState()
  const [filterMenuInstuctions, setFilterMenuinstuctions] = useState()
  // Filter Activation/Reset
  const [unfilteredMapData, setUnfilteredMapData] = useState(null);
  const [filterIsReady, setFilterIsReady] = useState(false);

  useEffect(()=> {
    if(filterMenuSelection === "Global Filter"){
      setFilterMenuinstuctions("Enter a keyword or phrase and check the boxes of any properties you would like to match against your input.")
    } else if(filterMenuSelection === "Node Radius Filter"){
      setFilterMenuinstuctions("Filter your map down to a node within a given radius of connections.")
    } else if(filterMenuSelection === "Neighborhood Filter"){
      setFilterMenuinstuctions("Filter your map down to a neighborhood and its associated nodes.")
    } else {
      setFilterMenuinstuctions("Filter your map down to elements with properties that match keywords.")
    }
  },[filterMenuSelection])

  ////////////////
  // USEEFFECTS //
  ////////////////
  useEffect(()=> {
    if(filteredMapData){
      if(filteredMapData.filterType === "Global Filter"){
        setFilterMenuSelection("Global Filter")
      } else if(filteredMapData.filterType === "Node Radius Filter"){
        setFilterMenuSelection("Node Radius Filter")
      } else if(filteredMapData.filterType === "Neighborhood Filter"){
        setFilterMenuSelection("Neighborhood Filter")
      } 
      else {
        setFilterMenuSelection("Global Filter")
      }
    } else {
      setFilterMenuSelection("Global Filter")
    }
  },[filteredMapData])

  ////////////////////
  // EVENT HANDLERS //
  ////////////////////

  function handleFilterMenuSelection(e) {
    setFilterMenuSelection(e.target.value)
    setUnfilteredMapData(null)
  }

  function handleFilterData(inputDataObj) {
    setUnfilteredMapData(inputDataObj)
    setFilterIsReady(true)
  }

  // WHEN USER RESETS THE FILTER...
  function handleFilterReset() {
    // Clear All Filter Targets and Neighborhood Legend
    setUnfilteredMapData(null)
    // if in view reset to the view not the diagram data
    if (selectedView) {
      handleViewLoad(selectedView);
      setRenderMapData(filteredMapData);
      handleFilterDisplay()
      return;
    }
    setFilter(false);
    setGlobalKeywordInput(null);
    store.dispatch(resetSelectedViewDataAction());
    handleFilteredMapData(null);
  }
// HANDLE INSTEAD OF SET FILTERED MAP
  // ACTIVATE FILTER: After the user clicks "Filter," this utils function formats and sets the active filter
  function activateFilter() {
    if (filterMenuSelection === 'Global Filter') {
      let filterData = globalFilterUtil(diagramData, unfilteredMapData)
      if(filterData.nodeDataArray.length === 0){
        return toast.error("This filter returned no nodes. You need at least one node to render a map.")
      } 
      setGlobalKeywordInput(unfilteredMapData.keywordInput)
      let globalFilterData = cloneDeep(filterData)
      globalFilterData.filterType = "Global Filter"
      handleFilteredMapData(globalFilterData);
      setFilter(true);
    } else if(filterMenuSelection === 'Node Radius Filter') {
      let filterData = nodeRadiusFilterUtil(diagramData, unfilteredMapData) 
      let nodeRadiusFilterData = cloneDeep(filterData)
      nodeRadiusFilterData.filterType = "Node Radius Filter"
      handleFilteredMapData(nodeRadiusFilterData);
      setFilter(true);
    } else if(filterMenuSelection === 'Neighborhood Filter') {
      let filterData = neighborhoodFilterUtil(diagramData, unfilteredMapData)
      let neighborhoodFilterData = cloneDeep(filterData)
      neighborhoodFilterData.filterType = "Neighborhood Filter"
      neighborhoodFilterData.selection = unfilteredMapData.title
      handleFilteredMapData(neighborhoodFilterData);
      setFilter(true);
    }
  }

  let GlobalFilterComponent;
  if (filterMenuSelection === "Global Filter") {
    GlobalFilterComponent  = 
    <GlobalFilterMainMenu 
      renderMapData={renderMapData}
      globalKeywordInput={globalKeywordInput}
      handleFilterData={handleFilterData}
      unfilteredMapData={unfilteredMapData}
    />;
  } else {
    GlobalFilterComponent === null
  }
  
  let NodeRadiusFilterComponent;
  if(filterMenuSelection === "Node Radius Filter") {
    NodeRadiusFilterComponent = 
    <NodeRadiusFilter 
      renderMapData={renderMapData}
      diagramData={diagramData}
      filteredMapData={filteredMapData}
      unfilteredMapData={unfilteredMapData}
      handleFilterData={handleFilterData}

    />;
  } else {
    NodeRadiusFilterComponent === null
  }

  let NeighborhoodFilterComponent;
  if(filterMenuSelection === "Neighborhood Filter") {
    NeighborhoodFilterComponent = 
    <NeighborhoodFilter 
      diagramData={diagramData}
      renderMapData={renderMapData}
      unfilteredMapData={unfilteredMapData}
      handleFilterData={handleFilterData}
      filtered={filtered}
    />;
  } else {
    NeighborhoodFilterComponent === null
  }
  return (
    /////////////////
    // FILTER MENU //
    /////////////////
    <>
    <div
      className={`filter-menu-container ${
        showFilterMenu || renderMapData.nodeDataArray.lentgh > 0 ? 'open-filter-menu' : 'close-filter-menu'
      }`}
    >
      <div className="col-12">
          <div className="submenu-top-bar-blue">
              <div className='theme-menu-header'>
              Filter
              </div>
              <div
                className="bi bi-x submenu-close-x"
                onClick={handleFilterDisplay}
              ></div>
            
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>

          <div className='filter-instructions'>
            <div className='row'>
              <div className='col-12'>
                <select
                  id="filterElement"
                  className="filter-menu-selector"
                  onChange={handleFilterMenuSelection}
                  value={filterMenuSelection}
                >
                  <option value="Global Filter">Global Filter</option>
                  <option value="Node Radius Filter">Node Radius Filter</option>
                  <option value="Neighborhood Filter">Neighborhood Filter</option>
                </select>
              </div>
              <div className='col-12'>
                {filterMenuInstuctions}
              </div>
            </div>
          </div>
          </div>
        </div>
      <div className="filter-menu">
        {/* CLOSE BUTTON */}
        <div className='row'>
          {GlobalFilterComponent || NodeRadiusFilterComponent || NeighborhoodFilterComponent}
        </div>
        
        {/* FILTER BUTTON */}
        <div className="row">
          <div className='filter-menu-buttons-container'>
            <div className='row'>
              <div className='col-2'>
                <button
                  tabIndex={0}
                  // The Filter Button is Disabled Until a User Enters Something Into the AutoComplete Field
                  className="filter-button"
                  id={
                    filterIsReady === false
                      ? 'filter-button-disabled'
                      : 'filter-button-enabled'
                  }
                  onClick={() => {
                    activateFilter();
                    handleFilterDisplay();
                  }}
                  disabled={!filterIsReady}
                >
                  Filter
                </button>
              </div>
              <div className='col-2'>
                <button
                  tabIndex={0}
                  className="filter-reset-button"
                  onClick={() => {
                    handleFilterReset();
                  }}
                >
                  Reset
                </button>
              </div>

            </div>
          </div>
        </div>   
      </div>
    </div>
    </>
  );
};

CanvasFilter.propTypes = {
  handleFilterDisplay: PropTypes.func,
  diagramData: PropTypes.object,
  showFilterMenu: PropTypes.bool,
  globalKeywordInput: PropTypes.string,
  setGlobalKeywordInput: PropTypes.func,
  filteredMapData: PropTypes.object,
  selectedView: PropTypes.object,
  handleFilteredMapData: PropTypes.func,
  setFilter: PropTypes.func,
  filtered: PropTypes.bool,
  renderMapData: PropTypes.object,
  setRenderMapData: PropTypes.func,
  handleViewLoad: PropTypes.func
};

export default CanvasFilter;
