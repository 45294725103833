/**
 * @summary CanvasPage.js
 * @file Main Motherboard for the LM Canvas
 * @returns {JSX}
 * @usedBy CanvasWrapper.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { getViews } from 'store/views/ViewActions';
import './canvasPage.scss';
import { useImmer } from 'use-immer';
import store from 'store/store';
import { resetSelectedViewDataAction } from 'store/views/ViewActions';
import cloneDeep from 'lodash/cloneDeep';
import Canvas from './Canvas';
// import formatCanvasMapData from './utils/formatCanvasMapData';
import filterViewData from './utils/filterViewData';
import CanvasFilter from './submenus/CanvasFilter';
import GlobalSidebar from './submenus/filterSubmenus/GlobalSidebar'
import CanvasThemes from './submenus/CanvasThemeMenu'
import InspectorTool from './submenus/InspectorTool';
import CanvasNeighborhoodLegend from './submenus/CanvasNeighborhoodLegend';
import CanvasInterface from './submenus/CanvasInterface';
import CanvasSaveView from './submenus/CanvasSaveView';
import CanvasLoadView from './submenus/CanvasLoadView';
import CanvasExportMap from './submenus/CanvasExortMap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const CanvasPage = ({
  canvasMapData
}) => {
  const {
    views,
  } = useSelector((state) => {
    const views = { ...state.viewReducer };
    delete views?.selectedData;
    return {
      views: Object.values(views).filter((view) => view.isActive),
    };
  }, shallowEqual);
  
  //////////////////////////////
  // FORMATTING DATA FOR GOJS //
  //////////////////////////////
  const [diagramData, updateDiagramData] = useImmer(canvasMapData.data);
  // RENDER STATE
  const [renderMapData, setRenderMapData] = useState();
  // DIAGRAM DATA ACCESS
  const [diagramAccess, setDiagramAccess] = useState(null);
    
  // NEIGHNORHOOD STATES
  const [showNeighborhoodLegendMenu, setShowNeighborhoodLegendMenu] = useState(false);
  const [neighborhoodLegendArray, setNeighborhoodLegendArray] = useState([])
  // FILTER STATES
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [filtered, setFilter] = useState(false);
  const [filteredMapData, setFilteredMapData] = useState(null);
  // GLOBAL FILTER STATES
  const [globalKeywordInput, setGlobalKeywordInput] = useState(null)
  const [openGlobalSidebar, setOpenGlobalSidebar] = useState(false);
  const [invisibleNodeArray, setInvisibleNodeArray] = useState([])
  const [visibleNeighborhoodArray, setVisibleNeighborhoodArray] = useState([])
  const [highlightElementsArray, setHighlightElementsArray] = useState([])
  const [highlightLinksArray, setHighlightLinksArray] = useState([])
  const [openElementsArray, setOpenElementsArray] = useState([])
  // THEME STATES
  const [showThemeMenu, setShowThemeMenu] = useState(false);

  // INSPECTION STATE
  const [isNodeSelected, setIsNodeSelected] = useState(false);
  const [showInspectorMenu, setShowInspectorMenu] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [multiSelectActive, setMultiSelectActive] = useState(false)
  const [multiSelectArray, setMultiSelectArray] = useState([]);

  // VIEW SAVE AND LOAD STATES
  const [selectedView, setSelectedView] = useState(null)
  const [showViewSaveMenu, setShowViewSaveMenu] = useState(false);
  const [showViewLoadMenu, setShowViewLoadMenu] = useState(false);
  
  const loadCurrentViews = () => {
    store.dispatch(getViews(canvasMapData.selectedProject.id, canvasMapData.selectedBranch.id));
  };

  useEffect(() => {
    if (showViewLoadMenu) {
      loadCurrentViews();
    }
  }, [showViewLoadMenu]);

  // DOWNLOAD IMAGES
  const [showMapDownloadModal, setShowMapDownloadModal] = useState(false);

  function handleCanvasExportDisplay() {
    setShowMapDownloadModal(true)
  }

  useEffect(() => {
    if (isNodeSelected === false) {
      setShowInspectorMenu(false);
    }
  }, [isNodeSelected]);

  // INITIAL LOAD
  useEffect(() => {
    store.dispatch(resetSelectedViewDataAction());
  }, [])

  // BUILDS NEIGHBORHOOD LEGEND //
  // Takes All Nodes from RenderMapData and Creates Array of Neighborhoods Using Nodes with Category: Super
  useEffect(() => {
    if(renderMapData){
      let updatedLegendOptions = []
      renderMapData.nodeDataArray?.forEach(neighborhood => {;
        if(neighborhood.category === "Super"){
          updatedLegendOptions.push(neighborhood)
        }
      })
      setNeighborhoodLegendArray(updatedLegendOptions);
    }
  }, [renderMapData, diagramData]);
  
  ////////////////////////////
  // GOJS MODEL RESFRESHERS //
  ////////////////////////////
  const mapNodeKeyIdx = new Map();
  const mapLinkKeyIdx = new Map();

  // USE EFFECT HANDLES RENDERMAP FILTER CHANGES AND NEIGHBORHOOD LEGEND
  useEffect(() => {
    if (!filtered) {
      setRenderMapData(diagramData);
    } else {
      setRenderMapData(filteredMapData);
    }
  }, [canvasMapData, diagramData, filtered, filteredMapData]);

  useEffect(() => {
    if(filteredMapData?.filterType === "Global Filter"){
      setOpenGlobalSidebar(true)
    }
  }, [renderMapData]);

  useEffect(() => {
    if(selectedNode !== null) {
      if(multiSelectActive === true){
        setMultiSelectArray([...multiSelectArray, selectedNode])
      } else {
        setMultiSelectArray([selectedNode]);
      }
    } else {
      setMultiSelectArray([])
    }
  }, [selectedNode]);

  useEffect(() => {
    if(selectedConnection !== null) {
      if(multiSelectActive === true){
        setMultiSelectArray([...multiSelectArray, selectedConnection]);
      } else {
        setMultiSelectArray([selectedConnection]);
      }
    } else {
      setMultiSelectArray([]);
    }
  }, [selectedConnection]);

  // REFRESHES DIAGRAM WITH NODE FILTER/LEGEND
  useEffect(() => {
    if (renderMapData) {
      refreshNodeIndex(renderMapData.nodeDataArray);
      refreshLinkIndex(renderMapData.linkDataArray);
    }
  }, [renderMapData]);

  function refreshNodeIndex(nodeArr) {
    mapNodeKeyIdx.clear();
    if (nodeArr !== undefined) {
      nodeArr.forEach((n, idx) => {
        mapNodeKeyIdx.set(n.key, idx);
      });
    }
  }
  function refreshLinkIndex(linkArr) {
    mapLinkKeyIdx.clear();
    if (linkArr !== undefined) {
      linkArr.forEach((l, idx) => {
        mapLinkKeyIdx.set(l.key, idx);
      });
    }
  }
  ///////////////////////////
  // MODEL CHANGE HANDLING //
  ///////////////////////////
  // TODO: Add Model Handlers for Interactive Things
  function handleModelChange() {
    // const insertedNodeKeys = obj.insertedNodeKeys;
    // const modifiedNodeData = obj.modifiedNodeData;
    // const removedNodeKeys = obj.removedNodeKeys;
    // const insertedLinkKeys = obj.insertedLinkKeys;
    // const modifiedLinkData = obj.modifiedLinkData;
    // const removedLinkKeys = obj.removedLinkKeys;
    // let modifiedModelData = obj.themeDataObj;
    // modifiedModelData.layoutDirection = 180;
    // updateDiagramData(modifiedModelData)
    // modifiedModelData.layoutDirection = 90;
    // TODO update state with above model changes
    // https://github.com/NorthwoodsSoftware/gojs-react-samples/blob/main/gojs-react-redux-basic/src/components/GoJSWrapper.tsx#L143
  }

  /////////////////////
  // EVENT HANDLING ///
  /////////////////////
  // FILTER MENU FUNCTIONS - Displays the Filter Submenu
  function handleNeighborhoodSelection(selectedNeighborhoodArray) {
    let clonedMap = cloneDeep(renderMapData)
    clonedMap.nodeDataArray.map(node => {
      if(node.category === "Super"){
        if(selectedNeighborhoodArray.includes(node.title)){
          node.visible = true
          return node
        } else {
          node.visible = false
          // node.isHighlighted = false
          return node
        }
      } else {
        return node
      }
    })
    setShowInspectorMenu(false);
    setRenderMapData(clonedMap)
  }
  //////////////////////
  // FILTER FUNCTIONS //
  //////////////////////
  // Fires when Filter Menu is opened
  function handleFilterDisplay(e) {
    setShowInspectorMenu(false);
    setShowThemeMenu(false);
    // setOpenGlobalSidebar(false)
    if(e === true){
      setShowFilterMenu(true);
    } else {
      setShowFilterMenu(!showFilterMenu);
    }
  }
  // Fires when Filter Inputs are Submitted
  function handleFilteredMapData(filterData) {

    setShowInspectorMenu(false);
    setRenderMapData(null)
    setFilteredMapData(filterData)
    setOpenGlobalSidebar(true)
    
    if(filterData?.filterType !== "Neighborhood Filter"){
      setInvisibleNodeArray([])
      setVisibleNeighborhoodArray([])
      setOpenElementsArray([])
    } else {
      setInvisibleNodeArray([])
      setVisibleNeighborhoodArray([filterData.selection])
      setOpenElementsArray([])
    }
  }

  function handleGlobalSidebarDisplay() {
    setOpenGlobalSidebar(!openGlobalSidebar)
  }
  // When A Neighborhood's Visibility is Changed Via Global Filter,Handle It Through Neighborhood Legend
  useEffect(() => {
    if(renderMapData){
      handleNeighborhoodSelection(visibleNeighborhoodArray)
    }
  },[visibleNeighborhoodArray])

  // Global Filter Highlighter Handler
  useEffect(() => {
    if(renderMapData){
      let clonedMap = cloneDeep(renderMapData)
      clonedMap.nodeDataArray.map(node => {
        if(node.category !== "Super"){
          let selection = highlightElementsArray.find(selectedNode => selectedNode === node.nodeKey)
          if(selection){
            node.isHighlighted = true
            return node
          } else {
            node.isHighlighted = false
            return node
          }
        } else {
          let selection = highlightElementsArray.find(selectedNode => selectedNode === node.title)
          if(selection){
            node.isHighlighted = true
            return node
          } else {
            node.isHighlighted = false
            return node
          }
        }
      })
      setRenderMapData(clonedMap)
    }
  },[highlightElementsArray])

  useEffect(() => {
    if(renderMapData){
      let clonedMap = cloneDeep(renderMapData)
      clonedMap.linkDataArray.map(connection => {
          let selection = highlightLinksArray.find(selectedConnection => selectedConnection === connection.id)
          if(selection){
            connection.isHighlighted = true
            return connection
          } else {
            connection.isHighlighted = false
            return connection
          }
      })
      setRenderMapData(clonedMap)
    }
  },[highlightLinksArray])

  // Global Filter Visibility Handler
  useEffect(() => {
    if(renderMapData){
      let clonedMap = cloneDeep(renderMapData)
      clonedMap?.nodeDataArray?.map(node => {
        if(node.category !== "Super"){
          let selection = invisibleNodeArray.find(selectedNode => selectedNode === node.nodeKey)
          if(selection){
            node.visible = false
            // node.isHighlighted = false
            return node
          } else {
            node.visible = true
            return node
          }
        } else {
          return node
        }
      })
      setRenderMapData(clonedMap)
    }
  },[invisibleNodeArray])

  

  /////////////////////
  // THEME FUNCTIONS //
  /////////////////////
  // Fires when Theme Menu is opened
  function handleThemeMenuDisplay() {
    setShowInspectorMenu(false);
    setShowFilterMenu(false)
    setShowThemeMenu(!showThemeMenu);
  }

  // INSPECTION FUNCTIONS - Displays the Inspector Submenu
  function handleInspectionToolDisplay() {
    setShowFilterMenu(false);
    setShowThemeMenu(false)
    setShowInspectorMenu(!showInspectorMenu);
  }

  // SAVE AND LOAD VIEW SUBMENU FUNCTIONS - Displays the Save and Load Submenus
  function handleViewSaveDisplay() {
    setShowViewSaveMenu(!showViewSaveMenu);
  }

  function handleViewLoadDisplay() {
    setShowViewLoadMenu(!showViewLoadMenu);
  }

  // LOAD VIEW FUNCTION - This function gets the view, cross-references it Id's, and sets it as the new map
  function handleViewLoad(view) {
    if(view){
    const filteredViewData = filterViewData(view);
    setSelectedView(filteredViewData)
    setFilter(false);
    setFilteredMapData(filteredViewData);
    setFilter(true);
    setShowViewLoadMenu(false);
    } else {
      return toast.error("You must select a view to load.");
    }
  }

  function handleExitView() {
    setFilter(false);
    setShowViewLoadMenu(false)
    setSelectedView(null)
    setFilteredMapData(null);
    setRenderMapData(diagramData);
  }

  // NEIGHBORHOOD LEGEND FUNCTIONS - This function displays the neighborhood legend
  function handleDisplayNeighborhoodLegend() {
    setShowNeighborhoodLegendMenu(!showNeighborhoodLegendMenu);
  }

  function handleElementSelection(selected){
    if(selected.length === 0){
      setIsNodeSelected(false);
      setMultiSelectActive(false);
      setSelectedNode(null);
      setMultiSelectArray([]);
    } else if (selected.length === 1) {
      if(selected[0].data.type !== "Connection"){
        setIsNodeSelected(true);
        setMultiSelectActive(false);
        setSelectedNode(selected[0]);
      } else {
        setIsNodeSelected(false);
        setMultiSelectActive(false);
        setSelectedConnection(selected[0]);
      }
    } else if (selected.length > 1) {
      setIsNodeSelected(false);
      setMultiSelectActive(true);
      setMultiSelectArray(selected);
    }
  }

  // VIEW CHANGE FUNCTIONS
  //  These buttons control the rotation
  function handleRotate(buttonClicked) {
    let direction = diagramData.modelData.layoutDirection;
    let rotation = 90;
    if (
      buttonClicked.target.className ===
      'bi bi-arrow-counterclockwise view-change-button'
    ) {
      rotation = -90;
    } else if (
      buttonClicked.target.className ===
      'bi bi-arrow-clockwise view-change-button'
    ) {
      rotation = 90;
    }
    let completeRotation = direction + rotation;
    updateDiagramData((draft) => {
      switch (completeRotation) {
        case -90:
          draft.modelData.layoutDirection = 270;
          // updateDiagramData(newDiagramData);
          break;
        case 360:
          draft.modelData.layoutDirection = 0;
          // updateDiagramData(newDiagramData);
          break;
        default:
          draft.modelData.layoutDirection = completeRotation;
          // updateDiagramData(newDiagramData);
          break;
      }
    });
  }
  // These buttons control the diretional layout
  function handleLayeringOptionChange(buttonClicked) {
    updateDiagramData((draft) => {
      if (draft.modelData.layeringOption !== buttonClicked.target.id) {
        draft.modelData.layeringOption = buttonClicked.target.id;
      } else if (buttonClicked.target.id === 'LayerLongestPathSource') {
        draft.modelData.layeringOption = 'resetLayerLongestPathSource';
      } else if (buttonClicked.target.id === 'LayerLongestPathSink') {
        draft.modelData.layeringOption = 'resetLayerLongestPathSink';
      } else {
        draft.modelData.layeringOption = 'resetLayerLongestPathSource';
      }
    });
    handleModelChange(diagramData);
  }

  useEffect(()=> {
    if(renderMapData){
      if(renderMapData.nodeDataArray.length === 0) {
        if(canvasMapData.selectedBranch.name === "published" && !filtered){
          toast.error("You may need to merge your edit_draft branch into your Published branch. Check to make sure your edit_draft branch has nodes.");
        } else if (canvasMapData.selectedBranch.name === "edit_draft"  && !filtered){
          toast.error("You need to upload nodes into youe edit_draft branch through the Map Upload section - under the Projects tab above.");
        } else if( !filtered){
          toast.error("This project has no nodes to render into a map.");
        } else if(filtered === true){
          toast.error("No nodes were detected in this filter.");
        }
        
      } 
      
      if(renderMapData.nodeDataArray?.length > 1200){
        setShowFilterMenu(true)
        setOpenGlobalSidebar(true)
        toast.error("Please filter your map to under 1200 nodes.")
      }
    }
  }, [renderMapData])

  ////////////
  // RETURN //
  ////////////
  if(!renderMapData) {
    return (
      <div style={{marginTop: "40vh", fontSize: "28px"}} className='load-message'>
        Rendering Data into a Map...
      </div>
      );
  }

  if(renderMapData !== undefined) {
    return (
      <div className="canvas-page-container">
        {/* ///////////////////////////// */}
        {/* /////// UI INTERFACE //////// */}
        {/* ///////////////////////////// */}
        <CanvasInterface
          renderMapData={renderMapData}
          handleInspectionToolDisplay={handleInspectionToolDisplay}
          handleFilterDisplay={handleFilterDisplay}
          openGlobalSidebar={openGlobalSidebar}
          handleGlobalSidebarDisplay={handleGlobalSidebarDisplay}
          handleThemeMenuDisplay={handleThemeMenuDisplay}
          handleViewSaveDisplay={handleViewSaveDisplay}
          handleViewLoadDisplay={handleViewLoadDisplay}
          handleCanvasExportDisplay={handleCanvasExportDisplay}
          handleRotate={handleRotate}
          handleLayeringOptionChange={handleLayeringOptionChange}
          nodeAlignment={diagramData.modelData.layeringOption}
          handleDisplayNeighborhoodLegend={handleDisplayNeighborhoodLegend}
          isNodeSelected={isNodeSelected}
          showThemeMenu={showThemeMenu}
          showFilterMenu={showFilterMenu}
          // LEAVE THIS FOR WHEN WE NEED TO FORCE REDUCE THE MAP SIZE FOR TESTING
          // showFilterMenu={(diagramData?.nodeDataArray?.length > 1200) ? true : showFilterMenu}
          showInspectorMenu={showInspectorMenu}
          showViewSaveMenu={showViewSaveMenu}
          showViewLoadMenu={showViewLoadMenu}
          showMapDownloadModal={showMapDownloadModal}
          showNeighborhoodLegendMenu={showNeighborhoodLegendMenu}
          filtered={filtered}
        />

        {/* //////// FILTER SUBMENU ///////*/}
        <CanvasFilter
          handleFilterDisplay={handleFilterDisplay}
          diagramData={diagramData}
          diagramAccess={diagramAccess}
          showFilterMenu={showFilterMenu}
          setShowFilterMenu={setShowFilterMenu}
          filteredMapData={filteredMapData}
          renderMapData={renderMapData}
          setRenderMapData={setRenderMapData}
          handleFilteredMapData={handleFilteredMapData}
          setFilter={setFilter}
          filtered={filtered}
          globalKeywordInput={globalKeywordInput}
          setGlobalKeywordInput={setGlobalKeywordInput}
        />
        <div className={`global-filter-sidebar-container  
          ${openGlobalSidebar ? 'open-global-sidebar' : 'close-global-sidebar'}`
        }>
          <GlobalSidebar
            handleFilterDisplay={handleFilterDisplay}
            handleGlobalSidebarDisplay={handleGlobalSidebarDisplay}
            renderMapData={renderMapData}
            invisibleNodeArray={invisibleNodeArray}
            setInvisibleNodeArray={setInvisibleNodeArray}
            highlightElementsArray={highlightElementsArray}
            setHighlightElementsArray={setHighlightElementsArray}
            highlightLinksArray={highlightLinksArray}
            setHighlightLinksArray={setHighlightLinksArray}
            visibleNeighborhoodArray={visibleNeighborhoodArray}
            setVisibleNeighborhoodArray={setVisibleNeighborhoodArray}
            openElementsArray={openElementsArray}
            setOpenElementsArray={setOpenElementsArray}
            globalKeywordInput={globalKeywordInput}
          />
        </div>
        {/* //////// THEME SUBMENU ////////// */}
        <CanvasThemes
          diagramData={diagramData}
          diagramAccess={diagramAccess}
          filteredMapData={filteredMapData}
          setFilteredMapData={setFilteredMapData}
          handleThemeMenuDisplay={handleThemeMenuDisplay}
          showThemeMenu={showThemeMenu}
          selectedNode={selectedNode}
          multiSelectActive={multiSelectActive}
          multiSelectArray={multiSelectArray}
          updateDiagramData={updateDiagramData}
          setHighlightElementsArray={setHighlightElementsArray}
        />
        {/* //////// INSPECTOR SUBMENU ///////*/}
        <InspectorTool
          handleInspectionToolDisplay={handleInspectionToolDisplay}
          showInspectorMenu={showInspectorMenu}
          selectedNode={selectedNode}
          show={showInspectorMenu}
          setShow={setShowInspectorMenu}
        />
        {/* //////// SAVE AND LOAD VIEWS */}
        <CanvasSaveView
          showViewSaveMenu={showViewSaveMenu}
          onShowViewSaveMenu={setShowViewSaveMenu}
          setShowViewSaveMenu={setShowViewSaveMenu}
          diagramAccess={diagramAccess}
          setRenderMapData={setRenderMapData}
          renderMapData={renderMapData}
          selectedProject={canvasMapData.selectedProject}
          selectedBranch={canvasMapData.selectedBranch}
          views={views}
        />
        {/* //////// SAVE AND LOAD VIEWS */}
        <CanvasLoadView
          showViewLoadMenu={showViewLoadMenu}
          onShowViewLoadMenu={setShowViewLoadMenu}
          selectedProject={canvasMapData.selectedProject}
          selectedBranch={canvasMapData.selectedBranch}
          selectedView={selectedView}
          views={views}
          handleViewLoad={handleViewLoad}
          handleExitView={handleExitView}
        />
        {/* CANVAS IMAGE EXPORT MENU */}
        <CanvasExportMap
          showMapDownloadModal={showMapDownloadModal}
          setShowMapDownloadModal={setShowMapDownloadModal}
          diagramAccess={diagramAccess}
        />
        {/* /////// NEIGHBORHOOD LEGEND SUBMENU ///////// */}
        <CanvasNeighborhoodLegend
          neighborhoodLegendArray={neighborhoodLegendArray}
          setVisibleNeighborhoodArray={setVisibleNeighborhoodArray}
          showNeighborhoodLegendMenu={showNeighborhoodLegendMenu}          
        />
        {/* ///////////////////////////// */}
        {/* ///// CANVAS COMPONENT ////// */}
        {/* ///////////////////////////// */}
        <div className="gojs-wrapper-div">
          <div className="gsfBackground">
            {renderMapData.nodeDataArray.length > 0 && renderMapData.nodeDataArray.length < 1200 ?
            //////////////////
            // Heavy Canvas //
            //////////////////
            <Canvas
              nodeDataArray={renderMapData.nodeDataArray}
              linkDataArray={renderMapData.linkDataArray}
              modelData={diagramData.modelData}
              onModelChange={handleModelChange}
              handleElementSelection={handleElementSelection}
              neighborhoodLegendArray={neighborhoodLegendArray}
              setMultiSelectActive={setMultiSelectActive}
              setMultiSelectArray={setMultiSelectArray}
              setIsNodeSelected={setIsNodeSelected}
              setSelectedNode={setSelectedNode}
              skipsDiagramUpdate={renderMapData.skipsDiagramUpdate}
              setDiagramAccess={setDiagramAccess}
              className="gsfBackground"
            />
            :
            <div className='empty-canvas'>
            </div>
         }
          </div>
        </div> 
      </div>
    );
  }
};

CanvasPage.propTypes = {
  canvasMapData: PropTypes.object,
};

export default CanvasPage;
