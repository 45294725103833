/**
 * @summary CanvasThemeMenu.js
 * @file Modal for Containing Theme Options - Enables User to Change Colors, Fonts, Shapes and Sizes of Elements - then apply them. Opened through CanvasInterface.js.
 * @returns {JSX}
 * @usedBy CanvasPage.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';
import MiniCanvas from './themeSubmenus/MiniCanvas'
import '../canvasPage.scss';

// THEME MENU COMPONENTS
import NodeStylizationSection from './themeSubmenus/NodeStylization'
import NeighborhoodStylizationSection from './themeSubmenus/NeighborhoodStylization'
import ConnectionStylizationSection from './themeSubmenus/ConnectionStylization';
import ThemeFontsSection from './themeSubmenus/ThemeFontsSection'
import ColorPicker from './themeSubmenus/ColorPicker'

// CANVAS THEMES
// This feature handles the filtering of nodes and neighborhoods based on user input.
const CanvasThemeMenu = ({
  diagramData,
  filteredMapData,
  setFilteredMapData,
  handleThemeMenuDisplay,
  showThemeMenu,
  selectedNode,
  multiSelectArray,
  updateDiagramData,
  setHighlightElementsArray
}) => {
  const defaultTheme = {
    nodeShape: 'RoundedRectangle',
    nodeBackgroundColor: '#FFFFFF',
    nodeBorderColor: '#1560b7',
    nodeBorderWidth: 2,
    
    nodeFont: 'Arial',
    nodeFontColor: '#000000',
    nodeFontPrimarySize: '16px',
    nodeFontSecondarySize: '14px',

    neighborhoodShape: 'RoundedRectangle',
    neighborhoodBackgroundColor: '#FAF03B',
    neighborhoodBorderColor: '#1560b7',
    neighborhoodBorderWidth: 1,

    neighborhoodFont: 'Arial',
    neighborhoodFontSize: '24px',
    neighborhoodFontColor: '#000000',

    connectionColor: '#1560b7',
    connectionWidth: 2,
    connectionArrowHeadStyle: 'Triangle'
  }

  const [themeNodeArray, setNodeThemeArray] = useState([]);

  /////////////////
  // NODE STATES //
  /////////////////
  const [nodeShape, setNodeShape] = useState(defaultTheme.nodeShape);
  const [nodeBackgroundColor, setNodeBackgroundColor] = useState(defaultTheme.nodeBackgroundColor);
  const [nodeBackgroundColorPallete, setNodeBackgroundColorPallete] = useState(false)
  const [nodeBorderColor, setNodeBorderColor] = useState(defaultTheme.nodeBorderColor);
  const [nodeBorderColorPallete, setNodeBorderColorPallete] = useState(false)
  const [nodeBorderWidth, setNodeBorderWidth] = useState(defaultTheme.nodeBorderWidth);

  const [nodeShapeDropdownLabel, setNodeShapeDropdownLabel] = useState("RoundedRectangle");
  const [nodeBorderWidthDropdownLabel, setNodeBorderWidthDropdownLabel] = useState(2);

  /////////////////////////
  // NEIGHBORHOOD STATES //
  /////////////////////////
  const [neighborhoodShape, setNeighborhoodShape] = useState(defaultTheme.neighborhoodShape);
  const [neighborhoodBackgroundColor, setNeighborhoodBackgroundColor] = useState(defaultTheme.neighborhoodBackgroundColor);
  const [neighborhoodBackgroundColorPallete, setNeighborhoodBackgroundColorPallete] = useState(false)
  const [neighborhoodBorderColor, setNeighborhoodBorderColor] = useState(defaultTheme.neighborhoodBorderColor);
  const [neighborhoodBorderColorPallete, setNeighborhoodBorderColorPallete] = useState(false)
  const [neighborhoodBorderWidth, setNeighborhoodBorderWidth] = useState(defaultTheme.neighborhoodBorderWidth)

  const [neighborhoodShapeDropdownLabel, setNeighborhoodShapeDropdownLabel] = useState("RoundedRectangle");
  const [neighborhoodBorderWidthDropdownLabel, setNeighborhoodBorderWidthDropdownLabel] = useState(1);
  
  ///////////////////////
  // CONNECTION STATES //
  ///////////////////////
  const [connectionColor, setConnectionColor] = useState(defaultTheme.connectionColor);
  const [connectionWidth, setConnectionWidth] = useState(defaultTheme.connectionWidth)
  const [connectionArrowHeadStyle, setConnectionArrowHeadStyle] = useState(defaultTheme.connectionArrowHeadStyle);
  const [connectionColorPallete, setConnectionColorPallete] = useState(false)
  const [connectionStylePallete, setConnectionStylePallete] = useState(false)

  const [connectionWidthDropdownLabel, setConnectionWidthDropdownLabel] = useState(); 
  const [connectionArrowStyleDropdownLabel, setConnectionArrowStyleDropdownLabel] = useState(); 

  //////////////////////////////
  // MINICANVAS PREVIEW STATE //
  ////////////./////////////////
  const [miniCanvasPreview, setMiniCanvasPreview] = useState('All Elements');

  ///////////
  // FONTS //
  ///////////
  const [nodeFont, setNodeFont] = useState('Arial');
  const [nodeFontColor, setNodeFontColor] = useState('#000000');
  const [nodeFontPrimarySize, setNodeFontPrimarySize] = useState("16px")
  const [nodeFontSecondarySize, setNodeFontSecondarySize] = useState("14px")
  const [nodeFontColorPallete, setNodeFontColorPallete] = useState(false)
  const [neighborhoodFont, setNeighborhoodFont] = useState('Arial');
  const [neighborhoodFontSize, setNeighborhoodFontSize] = useState("24px")
  const [neighborhoodFontColor, setNeighborhoodFontColor] = useState('#000000');
  const [neighborhoodFontColorPallete, setNeighborhoodFontColorPallete] = useState(false)

  // FONT DROPDOWN LABELS
  const [nodeFontDropdownLabel, setNodeFontDropdownLabel] = useState('Arial')
  const [nodeFontSizeDropdownLabel, setNodeFontSizeDropdownLabel]= useState('Arial')
  const [neighborhoodFontDropdownLabel, setNeighborhoodFontDropdownLabel] = useState('Arial')
  const [neighborhoodFontSizeDropdownLabel, setNeighborhoodFontSizeDropdownLabel] = useState('Arial')

  // FINAL FONTS
  const [completeNodePrimaryFont, setCompleteNodePrimaryFont] = useState();
  const [completeNodeSecondaryFont, setCompleteNodeSecondaryFont] = useState();
  const [completeNeighborhoodFont, setCompleteNeighborhoodFont] = useState();

  useEffect(() => {
    setNodeBackgroundColorPallete(false)
    setNodeBorderColorPallete(false)
    setNeighborhoodBackgroundColorPallete(false)
    setNeighborhoodBorderColorPallete(false)
    setConnectionColorPallete(false)
    setNodeFontColorPallete(false)
    setNeighborhoodFontColorPallete(false)
  },[nodeBackgroundColor, nodeBorderColor, neighborhoodBackgroundColor, neighborhoodBorderColor, connectionColor, nodeFontColor, neighborhoodFontColor])

  /////////////////////////////
  // MULTI SELECT CONTROLLER //
  /////////////////////////////
  useEffect(() => {
    if(multiSelectArray.length){
      let selectedNodesforThemes =[]
      for(let i = 0; i < multiSelectArray.length; i++){
        if(multiSelectArray[i].data.type !== "Connection"){
          selectedNodesforThemes.push(multiSelectArray[i].data.nodeKey) 
        } else {
          selectedNodesforThemes.push(multiSelectArray[i].data.key) 
        }
      }
      setNodeThemeArray(selectedNodesforThemes)
    } 
  }, [multiSelectArray, selectedNode])
  //////////////////
  // FONT BUILDER //
  //////////////////
  useEffect(() => {
    let primaryText = ("bold" + " " + nodeFontPrimarySize + " " + nodeFont).toString()
    let secondaryText = ("100" + " " + nodeFontSecondarySize + " " + nodeFont).toString()
    let neighborhoodText = ("bolder" + " " + neighborhoodFontSize + " " + neighborhoodFont).toString()
    
    setCompleteNodePrimaryFont(primaryText)
    setCompleteNodeSecondaryFont(secondaryText)
    setCompleteNeighborhoodFont(neighborhoodText)

  }, [nodeFont, nodeFontPrimarySize, nodeFontSecondarySize, neighborhoodFont, neighborhoodFontSize])

  // SELECT ALL OVERRIDE
  const [selectAllOverride, setSelectAllOverride] = useState(false);

  function selectAllOverrideHandler () {
    setSelectAllOverride(!selectAllOverride)
  }

  /////////////////////////
  // NODE THEME HANDLERS //
  /////////////////////////
  function handleNodeShapeSelector(e) {
    const shape = e.target.value
    setNodeShape(shape)
    setNodeShapeDropdownLabel(e.target.value)
  }

  function handleNodeBackgroundColorChange(color) {
    setNodeBackgroundColor(color)
  }

  function handleNodeBorderColorChange(color) {    
    setNodeBorderColor(color)
  }

  function handleNodeBorderWidthSelector(e){
    setNodeBorderWidth(e.target.value)
    setNodeBorderWidthDropdownLabel(e.target.value)
  }

  function handleNodeFontSelector(e) {
    setNodeFont(e.target.value)
    setNodeFontDropdownLabel(e.target.value)
  }

  function handleNodeFontColorChange(color) {
    setNodeFontColor(color)
  }

  function handleNodeFontSizeSelector(e) {
    setNodeFontSizeDropdownLabel(e.target.value)
    if(e.target.value === "Small"){
      setNodeFontPrimarySize("14px")
      setNodeFontSecondarySize("12px")
    } else if(e.target.value === "Medium"){
      setNodeFontPrimarySize("16px")
      setNodeFontSecondarySize("14px")
    } else if(e.target.value === "Large"){
      setNodeFontPrimarySize("24px")
      setNodeFontSecondarySize("16px")
    }

  }

  /////////////////////////////////
  // NEIGHBORHOOD THEME HANDLERS //
  /////////////////////////////////

  function handleNeighborhoodShapeSelector(e) {
    const shape = e.target.value
    setNeighborhoodShape(shape)
    setNeighborhoodShapeDropdownLabel(e.target.value)
  }

  function handleNeighborhoodBackgroundColorChange(color) {
    setNeighborhoodBackgroundColor(color)
  }

  function handleNeighborhoodBorderColorChange(color) {
    setNeighborhoodBorderColor(color)
  }

  function handleNeighborhoodBorderWidthSelector(e){
    setNeighborhoodBorderWidth(e.target.value)
    setNeighborhoodBorderWidthDropdownLabel(e.target.value)
  }

  function handleNeighborhoodFontSelector(e) {
    setNeighborhoodFont(e.target.value)
    setNeighborhoodFontDropdownLabel(e.target.value)
  }

  function handleNeighborhoodFontSizeSelector(e){
    setNeighborhoodFontSizeDropdownLabel(e.target.value)
    if(e.target.value === "Small"){
      setNeighborhoodFontSize("16px")
    } else if(e.target.value === "Medium"){
      setNeighborhoodFontSize("24px")
    } else if(e.target.value === "Large"){
      setNeighborhoodFontSize("32px")
    }
  }

  function handleNeighborhoodFontColorChange(color) {
    setNeighborhoodFontColor(color)
  }

  /////////////////////////////////
  // CONNECTION THEME HANDLERS //
  /////////////////////////////////

  function handleConnectionColorChange(color) {
    setConnectionColor(color)
  }

  function handleConnectionThicknessChange(e) {
    setConnectionWidth(e.target.value)
    setConnectionWidthDropdownLabel(e.target.value)
  }

  function handleConnectionArrowHeadStyleChange(e) {
    setConnectionArrowHeadStyle(e.target.value)
    setConnectionArrowStyleDropdownLabel(e.target.value)
  }

  //////////////////////
  // PREVIEW SELECTOR //
  //////////////////////
  // This section is not being utitlized at this moment, but will change the preview elements to different focuses 
  function handlePreviewSelector(e) {
    setMiniCanvasPreview(e.target.value)
  }

  ///////////////////////////////////////
  // THEME OBJECT THAT WILL BE APPLIED //
  ///////////////////////////////////////

  let themeTemplate = {
    nodeShape: nodeShape,
    nodeBackgroundColor: nodeBackgroundColor,
    nodeBorderColor: nodeBorderColor,
    nodeBorderWidth: nodeBorderWidth,
    nodePrimaryFont: completeNodePrimaryFont,
    nodeSecondaryFont: completeNodeSecondaryFont,
    nodeFontColor: nodeFontColor,
    neighborhoodShape: neighborhoodShape,
    neighborhoodBackgroundColor: neighborhoodBackgroundColor,
    neighborhoodBorderColor: neighborhoodBorderColor,
    neighborhoodBorderWidth: neighborhoodBorderWidth,
    neighborhoodFont: completeNeighborhoodFont,
    neighborhoodFontColor: neighborhoodFontColor,
    connectionColor: connectionColor,
    connectionWidth: connectionWidth,
    connectionArrowHeadStyle: connectionArrowHeadStyle,
  }
  /////////////////////
  // APPLYING THEMES //
  /////////////////////
  function applyThemes(){
    // Building Node Theme Object
    let nodeThemeObj = {
      shape: nodeShape,
      color: nodeBackgroundColor,
      border: nodeBorderColor,
      strokeWidth: nodeBorderWidth,
      fontColor: nodeFontColor,
      fontPrimary: completeNodePrimaryFont,
      fontSecondary: completeNodeSecondaryFont,
    }

    // Building Neighborhood Theme Object
    let neighborhoodThemeObj = {
      shape: neighborhoodShape,
      color: neighborhoodBackgroundColor,
      border: neighborhoodBorderColor,
      strokeWidth: neighborhoodBorderWidth,
      fontColor: neighborhoodFontColor,
      fontPrimary: completeNeighborhoodFont,
    }

    // Building Connection Theme Object
    let connectionThemeObj = {
      color: connectionColor,
      strokeWidth: connectionWidth,
      arrowHeadStyle: connectionArrowHeadStyle
    }
    // IF THE 'APPLY ALL' CHECKBOX IS NOT CHECKED
    if(selectAllOverride === false){
      // IF ELEMENTS HAVE BEEN SELECTED
      if(themeNodeArray.length > 0){
        // IF THE FILTER IS NOT SET
        if(filteredMapData === null){
          // MAP NEW THEME PROPERTIES
          let newDiagramData = cloneDeep(diagramData);
          newDiagramData.nodeDataArray.map(node => {
            if(themeNodeArray.includes(node.nodeKey)){
              if(node.category !== 'Super'){
                node.shape = nodeShape
                node.color = nodeBackgroundColor
                node.border = nodeBorderColor
                node.strokeWidth = nodeBorderWidth

                node.fontPrimary = completeNodePrimaryFont
                node.fontSecondary = completeNodeSecondaryFont
                node.fontColor = nodeFontColor
                
                node.theme = nodeThemeObj
              } else {
                node.shape = neighborhoodShape;
                node.backgroundColor = neighborhoodBackgroundColor              
                node.border = neighborhoodBorderColor
                node.borderWidth = neighborhoodBorderWidth

                node.fontPrimary = completeNeighborhoodFont
                node.fontColor = neighborhoodFontColor
                
                node.theme = neighborhoodThemeObj
              }
            }
          })
          // Connection Map
          newDiagramData.linkDataArray.map((connection) => {
            if(themeNodeArray.includes(connection.key)){
              connection.color = connectionColor;
              connection.strokeWidth = connectionWidth;              
              connection.arrowHeadStyle = connectionArrowHeadStyle
              
              connection.theme = connectionThemeObj
            }
          })         

          updateDiagramData(newDiagramData)
          handleThemeMenuDisplay()
          return toast.success("Theme Applied");
        // IF THE FILTER IS SET
        } else {
          // MAP THEME TO FULL DATA SO ITS AVAILABLE AFTER RESETTING FILTER
          let newDiagramData = cloneDeep(diagramData);
          newDiagramData.nodeDataArray.map(node => {
            if(themeNodeArray.includes(node.nodeKey)){
              if(node.category !== 'Super'){
                node.shape = nodeShape
                node.color = nodeBackgroundColor
                node.border = nodeBorderColor
                node.strokeWidth = nodeBorderWidth

                node.fontPrimary = completeNodePrimaryFont
                node.fontSecondary = completeNodeSecondaryFont
                node.fontColor = nodeFontColor
                
                node.theme = nodeThemeObj
              } else {
                node.shape = neighborhoodShape;
                node.backgroundColor = neighborhoodBackgroundColor              
                node.border = neighborhoodBorderColor
                node.borderWidth = neighborhoodBorderWidth

                node.fontPrimary = completeNeighborhoodFont
                node.fontColor = neighborhoodFontColor
                
                node.theme = neighborhoodThemeObj
              }
            }
          })
          newDiagramData.linkDataArray.map((connection) => {
            if(themeNodeArray.includes(connection.key)){
              connection.color = connectionColor;
              connection.strokeWidth = connectionWidth;              
              connection.arrowHeadStyle = connectionArrowHeadStyle
              
              connection.theme = connectionThemeObj
            }
          })
          // MAP THEMES TO CURRENT FILTERED VIEW
          let newFilteredDiagramData = cloneDeep(filteredMapData);
          newFilteredDiagramData.nodeDataArray.map(node => {
            if(themeNodeArray.includes(node.nodeKey)){
              if(node.category !== 'Super'){
                node.shape = nodeShape
                node.color = nodeBackgroundColor
                node.border = nodeBorderColor
                node.strokeWidth = nodeBorderWidth

                node.fontPrimary = completeNodePrimaryFont
                node.fontSecondary = completeNodeSecondaryFont
                node.fontColor = nodeFontColor
                
                node.theme = nodeThemeObj
              } else {
                node.shape = neighborhoodShape;
                node.backgroundColor = neighborhoodBackgroundColor              
                node.border = neighborhoodBorderColor
                node.borderWidth = neighborhoodBorderWidth

                node.fontPrimary = completeNeighborhoodFont
                node.fontColor = neighborhoodFontColor
                
                node.theme = neighborhoodThemeObj
              }
            }
          })
          newFilteredDiagramData.linkDataArray.map((connection) => {
            if(themeNodeArray.includes(connection.key)){
              connection.color = connectionColor;
              connection.strokeWidth = connectionWidth;              
              connection.arrowHeadStyle = connectionArrowHeadStyle
              
              connection.theme = connectionThemeObj
            }
          })
          // UPDATE THE CANVAS DATA
          updateDiagramData({
            projectHeadline: diagramData.projectHeadline,
            modelData: diagramData.modelData,
            nodeDataArray: newDiagramData.nodeDataArray,
            // neighborhoodLegend: newDiagramData.neighborhoodLegend,
            linkDataArray: newDiagramData.linkDataArray,
            selectedBranch: diagramData.selectedbranch,
            skipsDiagramUpdate: diagramData.skipsDiagramUpdate
          })  
          // UPDATE THE CURRENT FILTER
          setFilteredMapData(newFilteredDiagramData)
          setHighlightElementsArray([])
          handleThemeMenuDisplay()
          return toast.success("Theme Applied");
        }
      }
    // IF 'APPLY ALL' CHECKBOX IS CHECKED
    } else {
      // IF FILTERED MAP NOT SET
      if(filteredMapData === null){
        // MAP THEMES TO THE EVERY ELEMENT IN SOURCE DATA
        let newDiagramData = cloneDeep(diagramData);
        newDiagramData.nodeDataArray.map(node => {
          if(node.category !== 'Super'){
            node.shape = nodeShape
            node.color = nodeBackgroundColor
            node.border = nodeBorderColor
            node.strokeWidth = nodeBorderWidth

            node.fontPrimary = completeNodePrimaryFont
            node.fontSecondary = completeNodeSecondaryFont
            node.fontColor = nodeFontColor
            
            node.theme = nodeThemeObj
          } else {
            node.shape = neighborhoodShape;
            node.backgroundColor = neighborhoodBackgroundColor              
            node.border = neighborhoodBorderColor
            node.borderWidth = neighborhoodBorderWidth

            node.fontPrimary = completeNeighborhoodFont
            node.fontColor = neighborhoodFontColor
            
            node.theme = neighborhoodThemeObj
          }
        })
        newDiagramData.linkDataArray.map((connection) => {
          connection.color = connectionColor;
          connection.strokeWidth = connectionWidth;              
          connection.arrowHeadStyle = connectionArrowHeadStyle
          
          connection.theme = connectionThemeObj
        })
        updateDiagramData(newDiagramData)
        handleThemeMenuDisplay()
        return toast.success("Theme Applied");
      // IF FILTERED MAP IS SET AND 'APPLY ALL' CHECKBOX IS CHECKED
      } else {
        let newDiagramData = cloneDeep(diagramData);
        // GRAB ALL KEYS FROM FILTERED DATA
        let filteredNodeKeys = filteredMapData.nodeDataArray.map(node => {return node.nodeKey})
        let filteredConnections = filteredMapData.linkDataArray.map(connection => {return connection.key})

        // MAP THEMES TO FULL CANVAS SOURCE DATA, IF THAT ELEMENT MATCHES FILTERED DATA
        // This basically applies the theme to every element in the source data that was included in the filter
        // So that after filter is reset, the themes stay applied to the matching elemnts on the full data.
        newDiagramData.nodeDataArray.map(node => {
          if(filteredNodeKeys.includes(node.nodeKey)){
            if(node.category !== 'Super'){
              node.shape = nodeShape
              node.color = nodeBackgroundColor
              node.border = nodeBorderColor
              node.strokeWidth = nodeBorderWidth

              node.fontPrimary = completeNodePrimaryFont
              node.fontSecondary = completeNodeSecondaryFont
              node.fontColor = nodeFontColor
              
              node.theme = nodeThemeObj
            } else {
              node.shape = neighborhoodShape;
              node.backgroundColor = neighborhoodBackgroundColor              
              node.border = neighborhoodBorderColor
              node.borderWidth = neighborhoodBorderWidth

              node.fontPrimary = completeNeighborhoodFont
              node.fontColor = neighborhoodFontColor
              
              node.theme = neighborhoodThemeObj
            }
          }
        })
        newDiagramData.linkDataArray.map((connection) => {
          if(filteredConnections.includes(connection.key)){
              connection.color = connectionColor;
              connection.strokeWidth = connectionWidth;              
              connection.arrowHeadStyle = connectionArrowHeadStyle
              
              connection.theme = connectionThemeObj
          }
        })
        // MAP THEMES TO CURRENT FILTERED VIEW
        let newFilteredDiagramData = cloneDeep(filteredMapData);
        newFilteredDiagramData.nodeDataArray.map(node => {
          if(node.category !== 'Super'){
            node.shape = nodeShape
            node.color = nodeBackgroundColor
            node.border = nodeBorderColor
            node.strokeWidth = nodeBorderWidth

            node.fontPrimary = completeNodePrimaryFont
            node.fontSecondary = completeNodeSecondaryFont
            node.fontColor = nodeFontColor
            
            node.theme = nodeThemeObj
          } else {
            node.shape = neighborhoodShape;
            node.backgroundColor = neighborhoodBackgroundColor              
            node.border = neighborhoodBorderColor
            node.borderWidth = neighborhoodBorderWidth

            node.fontPrimary = completeNeighborhoodFont
            node.fontColor = neighborhoodFontColor
            
            node.theme = neighborhoodThemeObj
          }
        })

        newFilteredDiagramData.linkDataArray.map((connection) => {
            connection.color = connectionColor;
            connection.strokeWidth = connectionWidth;              
            connection.arrowHeadStyle = connectionArrowHeadStyle
            
            connection.theme = connectionThemeObj
        })
        updateDiagramData({
          projectHeadline: diagramData.projectHeadline,
          modelData: diagramData.modelData,
          nodeDataArray: newDiagramData.nodeDataArray,
          // neighborhoodLegend: newDiagramData.neighborhoodLegend,
          linkDataArray: newDiagramData.linkDataArray,
          selectedBranch: diagramData.selectedbranch,
          skipsDiagramUpdate: diagramData.skipsDiagramUpdate
        })
        setFilteredMapData(newFilteredDiagramData)
        handleThemeMenuDisplay()
        return toast.success("Theme Applied");
      }
    }
  }

  // RESETS THEMES BACK TO DEFAULT SETTINGS - USER MUST THEN APPLY
  function resetThemes(){
    setNodeShape(defaultTheme.nodeShape)
    setNodeBackgroundColor(defaultTheme.nodeBackgroundColor)
    setNodeBorderColor(defaultTheme.nodeBorderColor)
    setNodeBorderWidth(defaultTheme.nodeBorderWidth)

    setNodeFont(defaultTheme.nodeFont)
    setNodeFontColor(defaultTheme.nodeFontColor)
    setNodeFontPrimarySize(defaultTheme.nodeFontPrimarySize)
    setNodeFontSecondarySize(defaultTheme.nodeFontSecondarySize)

    setNeighborhoodShape(defaultTheme.neighborhoodShape)
    setNeighborhoodBackgroundColor(defaultTheme.neighborhoodBackgroundColor)
    setNeighborhoodBorderColor(defaultTheme.neighborhoodBorderColor)
    setNeighborhoodBorderWidth(defaultTheme.neighborhoodBorderWidth)    

    setNeighborhoodFont(defaultTheme.neighborhoodFont)
    setNeighborhoodFontColor(defaultTheme.neighborhoodFontColor)
    setNeighborhoodFontSize(defaultTheme.neighborhoodFontSize)

    setConnectionColor(defaultTheme.connectionColor)
    setConnectionWidth(defaultTheme.connectionWidth)
    setConnectionArrowHeadStyle(defaultTheme.connectionArrowHeadStyle)

    // DROPDOWN LABELS
    setNodeShapeDropdownLabel("RoundedRectangle")
    setNeighborhoodShapeDropdownLabel("RoundedRectangle")

    setNodeBorderWidthDropdownLabel(2)
    setNeighborhoodBorderWidthDropdownLabel(1)

    setConnectionWidthDropdownLabel("2")
    setConnectionArrowStyleDropdownLabel("Triangle")

    setNodeFontDropdownLabel("Arial")
    setNodeFontSizeDropdownLabel("Medium")

    setNeighborhoodFontDropdownLabel("Arial")
    setNeighborhoodFontSizeDropdownLabel("Medium")
  }

  ///////////////////////////////
  // COLOR PALLETTE VISIBILITY //
  ///////////////////////////////
  // When a user clicks a color selector, this controls which color pallete is visible.
  function handleColorPalletteVisibility(e) {
    
    if(e.target.id === "node-fill-color-button"){
      setNodeBackgroundColorPallete(!nodeBackgroundColorPallete);
      setNodeBorderColorPallete(false)
      setNodeFontColorPallete(false)
      setNeighborhoodBackgroundColorPallete(false)
      setNeighborhoodBorderColorPallete(false)
      setNeighborhoodFontColorPallete(false)
      setConnectionColorPallete(false)
      setConnectionStylePallete(false)
    } else if (e.target.id === "node-border-color-button") {
      setNodeBorderColorPallete(!nodeBorderColorPallete);
      setNodeBackgroundColorPallete(false);
      setNodeFontColorPallete(false)
      setNeighborhoodBackgroundColorPallete(false)
      setNeighborhoodBorderColorPallete(false)
      setNeighborhoodFontColorPallete(false)
      setConnectionColorPallete(false)
      setConnectionStylePallete(false)
    } else if (e.target.id === "node-font-color-button") {
      setNodeFontColorPallete(!nodeFontColorPallete);
      setNodeBorderColorPallete(false);
      setNodeBackgroundColorPallete(false);
      setNeighborhoodBackgroundColorPallete(false)
      setNeighborhoodBorderColorPallete(false)
      setNeighborhoodFontColorPallete(false);
      setConnectionColorPallete(false)
      setConnectionStylePallete(false)
    } else if (e.target.id === "neighborhood-background-color-button") {
      setNeighborhoodBackgroundColorPallete(!neighborhoodBackgroundColorPallete);
      setNodeFontColorPallete(false);
      setNodeBorderColorPallete(false);
      setNodeBackgroundColorPallete(false);
      setNeighborhoodBorderColorPallete(false)
      setNeighborhoodFontColorPallete(false)
      setConnectionColorPallete(false)
      setConnectionStylePallete(false)
    } else if (e.target.id === "neighborhood-border-color-button") {
      setNeighborhoodBorderColorPallete(!neighborhoodBorderColorPallete);
      setNeighborhoodBackgroundColorPallete(false);
      setNodeFontColorPallete(false);
      setNodeBorderColorPallete(false);
      setNodeBackgroundColorPallete(false);
      setNeighborhoodFontColorPallete(false)
      setConnectionColorPallete(false)
      setConnectionStylePallete(false)
    } else if (e.target.id === "neighborhood-font-color-button") {
      setNeighborhoodFontColorPallete(!neighborhoodFontColorPallete);
      setNeighborhoodBackgroundColorPallete(false);
      setNeighborhoodBorderColorPallete(false)
      setNodeFontColorPallete(false);
      setNodeBorderColorPallete(false);
      setNodeBackgroundColorPallete(false);
      setConnectionColorPallete(false)
      setConnectionStylePallete(false)
    } else if (e.target.id === "connection-color-button") {
      setConnectionColorPallete(!connectionColorPallete);
      setNeighborhoodFontColorPallete(false);
      setNeighborhoodBackgroundColorPallete(false);
      setNeighborhoodBorderColorPallete(false)
      setNodeFontColorPallete(false);
      setNodeBorderColorPallete(false);
      setNodeBackgroundColorPallete(false);
      setConnectionStylePallete(false)
    } else if (e.target.id === "connection-style-button") {
      setConnectionStylePallete(!connectionStylePallete);
      setConnectionColorPallete(false);
      setNeighborhoodFontColorPallete(false);
      setNeighborhoodBorderColorPallete(false)
      setNeighborhoodBackgroundColorPallete(false);
      setNodeFontColorPallete(false);
      setNodeBorderColorPallete(false);
      setNodeBackgroundColorPallete(false);
    }
  }

  return (
    /////////////////////////
    // THEME SELECTOR MENU //
    /////////////////////////
    <>
    <div
      className={`theme-menu-container ${
        showThemeMenu ? 'open-theme-menu' : 'close-theme-menu'
      }`}
    >
      {/* CLOSE BUTTON */}
      <div className="col-12">
        <div className="submenu-top-bar-blue">
          <div className='theme-menu-header'>
            Theme Editor
          </div>
          <div
            className="bi bi-x submenu-close-x"
            onClick={handleThemeMenuDisplay}
          ></div>
        </div>
      </div>
      <div className='theme-menu'>
        {/* ///////////////////////// */}
        {/* THEME STYLIZATION SECTION */}
        {/* ///////////////////////// */}
        <div className='row'>
          <div className='col-7'>
            <div className='theme-stylization-section'>
            {/* //////////////// */}
            {/* NODE STYLIZATION */}
            {/* //////////////// */}
              <div className='row'>
                <NodeStylizationSection 
                  handleColorPalletteVisibility={handleColorPalletteVisibility}
                  nodeBackgroundColorPallete={nodeBackgroundColorPallete}
                  nodeBorderColorPallete={nodeBorderColorPallete}
                  nodeBackgroundColor={nodeBackgroundColor}
                  nodeBorderColor={nodeBorderColor}
                  handleNodeShapeSelector={handleNodeShapeSelector}
                  handleNodeBackgroundColorChange={handleNodeBackgroundColorChange}
                  handleNodeBorderColorChange={handleNodeBorderColorChange}
                  handleNodeBorderWidthSelector={handleNodeBorderWidthSelector}
                  ColorPicker={ColorPicker}
                  nodeShapeDropdownLabel={nodeShapeDropdownLabel}
                  nodeBorderWidthDropdownLabel={nodeBorderWidthDropdownLabel}
                />
                {/* //////////////////////// */}
                {/* NEIGHBORHOOD STYLIZATION */}
                {/* //////////////////////// */}
                <NeighborhoodStylizationSection 
                  handleColorPalletteVisibility={handleColorPalletteVisibility}
                  neighborhoodBackgroundColorPallete={neighborhoodBackgroundColorPallete}
                  neighborhoodBorderColorPallete={neighborhoodBorderColorPallete}
                  neighborhoodBackgroundColor={neighborhoodBackgroundColor}
                  neighborhoodBorderColor={neighborhoodBorderColor}
                  handleNeighborhoodShapeSelector={handleNeighborhoodShapeSelector}
                  handleNeighborhoodBackgroundColorChange={handleNeighborhoodBackgroundColorChange}
                  handleNeighborhoodBorderColorChange={handleNeighborhoodBorderColorChange}
                  handleNeighborhoodBorderWidthSelector={handleNeighborhoodBorderWidthSelector}
                  neighborhoodShapeDropdownLabel={neighborhoodShapeDropdownLabel}
                  neighborhoodBorderWidthDropdownLabel={neighborhoodBorderWidthDropdownLabel}
                />
                {/* ////////////////////// */}
                {/* CONNECTION STYLIZATION */}
                {/* ////////////////////// */}
                <ConnectionStylizationSection 
                  handleColorPalletteVisibility={handleColorPalletteVisibility}
                  connectionColorPallete={connectionColorPallete}
                  connectionColor={connectionColor}
                  handleConnectionColorChange={handleConnectionColorChange}
                  handleConnectionThicknessChange={handleConnectionThicknessChange}
                  handleConnectionArrowHeadStyleChange={handleConnectionArrowHeadStyleChange}
                  connectionWidthDropdownLabel={connectionWidthDropdownLabel}
                  connectionArrowStyleDropdownLabel={connectionArrowStyleDropdownLabel}
                />
              </div>
            </div>
            {/* ////////////////// */}
            {/* THEME FONT SECTION */}
            {/* ////////////////// */}
            <div className='theme-font-section'>
              {/* ////////// */}
              {/* NODE FONTS */}
              {/* ////////// */}
              <div className='row'>
                <ThemeFontsSection 
                  handleNodeFontSelector={handleNodeFontSelector}
                  handleNeighborhoodFontSelector={handleNeighborhoodFontSelector}
                  nodeFontColorPallete={nodeFontColorPallete}
                  handleNodeFontColorChange={handleNodeFontColorChange}
                  nodeFontColor={nodeFontColor}
                  neighborhoodFontColorPallete={neighborhoodFontColorPallete}
                  handleNeighborhoodFontColorChange={handleNeighborhoodFontColorChange}
                  neighborhoodFontColor={neighborhoodFontColor}
                  handleNodeFontSizeSelector={handleNodeFontSizeSelector}
                  handleNeighborhoodFontSizeSelector={handleNeighborhoodFontSizeSelector}
                  handleColorPalletteVisibility={handleColorPalletteVisibility}
                  nodeFont={nodeFont}
                  neighborhoodFont={neighborhoodFont}
                  nodeFontSize={nodeFontPrimarySize}
                  neighborhoodFontSize={neighborhoodFontSize}
                  nodeFontDropdownLabel={nodeFontDropdownLabel}
                  nodeFontSizeDropdownLabel={nodeFontSizeDropdownLabel}
                  neighborhoodFontDropdownLabel={neighborhoodFontDropdownLabel}
                  neighborhoodFontSizeDropdownLabel={neighborhoodFontSizeDropdownLabel}
                />
              </div>
            </div>
          </div>
          <div className='col-5'>
            <div className='theme-preview-section'>
              <div className='theme-options-headers'>
                  Preview
              </div>
              <MiniCanvas themeTemplate={themeTemplate} miniCanvasPreview={miniCanvasPreview} />
            </div>
            <div className='theme-select-all-checkbox'>
              <input type="checkbox" id="selectAllCheck" name="selectAll" value="selectAll" checked={selectAllOverride} onChange={selectAllOverrideHandler}/>
              <label htmlFor="selectAllCheck" className='theme-select-all-label'> Check to Apply on All Elements {filteredMapData === null ? "" : " in this Filter"}</label>
              <i className="bi bi-question-circle" id="theme-select-all-override-explainer"
              title={filteredMapData === null ? "Check this box to apply these themes to every element on this map." : "Check this box to apply these themes to every element within this filter."}
              />
            </div>
            <div className='theme-action-buttons'>
              
              <button 
                className='theme-apply-button'
                onClick={applyThemes}
              >
                Apply New Themes
              </button>
              <button 
                className='theme-reset-button'
                onClick={resetThemes}
              >
                Reset to Default
              </button>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    </>
  );
};

CanvasThemeMenu.propTypes = {
  diagramData: PropTypes.object,
  filteredMapData: PropTypes.any,
  setFilteredMapData: PropTypes.func,
  handleThemeMenuDisplay: PropTypes.func,
  showThemeMenu: PropTypes.bool,
  selectedNode: PropTypes.any,
  multiSelectArray: PropTypes.array,
  updateDiagramData: PropTypes.func,
  setHighlightElementsArray: PropTypes.func,
};

export default CanvasThemeMenu;
