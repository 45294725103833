/**
 * @summary Redux actions for auth compoments
 * @file AuthActions.jsx
 * @returns {Function}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import axios from 'axios';
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SIGNOUT_SUCCESS,
  SIGNOUT_FAILURE,
  FORGOT_PWD_FAILURE,
  RESET_PWD_FAILURE,
  UPDATE_SELECTED_PROGRAM,
  NEW_PWD_SUCCESS,
  NEW_PWD_FAILURE,
  GET_CURRENT_USER,
  MODE_CHANGE,
  UPDATE_USER,
  UPDATE_USER_TAB
} from './AuthType';

export const receiveLogin = (user) => ({
  type: LOGIN_SUCCESS,
  isAuthenticated: true,
  userObj: user,
  errorMessage: null
});
export const loginError = (message) => ({
  type: LOGIN_FAILURE,
  isAuthenticated: false,
  userObj: {},
  errorMessage: message
});

export const forgotPasswordError = (message) => ({
  type: FORGOT_PWD_FAILURE,
  isAuthenticated: false,
  userObj: {},
  errorMessage: message
});

export const resetPasswordError = (message) => ({
  type: RESET_PWD_FAILURE,
  isAuthenticated: false,
  userObj: {},
  errorMessage: message
});

export const receiveSignOut = () => {
  window.sessionStorage.removeItem('user');
  return {
    type: SIGNOUT_SUCCESS,
    isAuthenticated: false,
    userObj: {},
    errorMessage: null
  };
};

export const signOutError = (message) => ({
  type: SIGNOUT_FAILURE,
  isAuthenticated: true,
  userObj: {},
  errorMessage: message
});

export const updateSelectedProgram = (userObj) => ({
  type: UPDATE_SELECTED_PROGRAM,
  userObj
});

export const newPasswordError = (message) => ({
  type: NEW_PWD_FAILURE,
  isAuthenticated: false,
  userObj: {},
  errorMessage: message
});

export const receiveNewPassword = (user) => ({
  type: NEW_PWD_SUCCESS,
  isAuthenticated: false,
  userObj: user
});

export const getUser = (userEmail) => async (dispatch) => {
  const { data } = await axios.get(`/api/users/${userEmail}`);
  dispatch(dispatchGetUser(data));
};

const dispatchGetUser = (userObj) => ({
  type: GET_CURRENT_USER,
  userObj
});

export const updateUserPrefs = (userData) => async (dispatch) => {
  const { data } = axios.post('/api/saveUserPreference', userData);
  dispatch(modeChange(userData.mode));
};

export const modeChange = (mode) => {
  return {
    type: MODE_CHANGE,
    mode
  };
};

export const updateUser = (userObj) => {
  return {
    type: UPDATE_USER,
    userObj
  };
};

export const updateUserTab = (tabName) => {
  return {
    type: UPDATE_USER_TAB,
    tabName
  };
};

export const userStorage = () => async (dispatch) => {
  const user = window.sessionStorage.user;
  //we only want to set this if we get a user back
  const parsedPrevUser = user ? JSON.parse(user) : {};
  if (parsedPrevUser.id) {
    dispatch(dispatchGetUser(parsedPrevUser));
  }
};
