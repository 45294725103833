/**
 * @summary CanvasLoadView.js
 * @file Modal that contains functionality for Loading Views. Opened through CanvasInterface.js.
 * @returns {JSX}
 * @usedBy CanvasPage.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useEffect, useState, useRef, useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import '../canvasPage.scss';
import { toast } from 'react-toastify';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Modal from 'react-bootstrap/Modal';
import store from 'store/store';
import { deleteView, getView, getViews } from 'store/views/ViewActions';

const CanvasLoadView = ({
  showViewLoadMenu,
  onShowViewLoadMenu,
  selectedProject,
  selectedBranch,
  selectedView,
  views,
  handleViewLoad,
  handleExitView
}) => {
  const [preSelectedView, setPreSelectedView] = useState();

  const gridRef = useRef();
  const nodeCols = [
    { field: 'name', headerName: 'Name', width: 275},
    { field: 'nodes', headerName: 'Node Count', width: 120},
    { field: 'lastUpdated', headerName: 'Updated', width: 170},
    { field: 'checkedOutBy', headerName: 'Author'}
  ];
  
  useEffect(() => {
    if (showViewLoadMenu) {
      setPreSelectedView();
    }
  }, [showViewLoadMenu]);

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    setPreSelectedView(selectedRows[0]);
  }, []);

  const handleLoad = () => {
    if(preSelectedView){
    const json = {
      projectId: selectedProject.id,
      branchId: selectedBranch.id,
      viewId: preSelectedView.id
    };
    store
      .dispatch(getView(json.projectId, json.branchId, json.viewId))
      .then(() => {
        const selectedViewData = store.getState().viewReducer.selectedData;
        handleViewLoad(selectedViewData);
      })
      .catch((errors) => {
        console.log(errors)
    })
    .catch((errors) => {
      console.log(errors)
    });
    } else {
      return toast.error("You must select a view to load.");
    }
  };

  const handleDelete = () => {
    store.dispatch(deleteView(selectedProject.id, preSelectedView.id));
    setPreSelectedView();
  };

  return (
    <>
      <Modal show={showViewLoadMenu} backdrop="static" centered size="lg">
        <Modal.Header>
          <Modal.Title className="view-save-modal-header">
            Select a View and Load
          </Modal.Title>
          <div
              className="bi bi-x submenu-close-x"
              onClick={() => onShowViewLoadMenu(false)}
            ></div>
        </Modal.Header>
        <Modal.Body>
          <div
            className="ag-theme-alpine"
            style={{ height: 300, width: '100%', position: 'relative' }}
          >
            Filtered Nodes in View
            <AgGridReact
              ref={gridRef}
              columnDefs={nodeCols}
              rowData={views}
              rowSelection="single"
              onSelectionChanged={onSelectionChanged}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary btn-sm text-white"
            onClick={handleLoad}
          >
            Load View
          </button>
            <button
              tabIndex={0}
              className="btn btn-danger btn-sm text-white"
              disabled={!selectedView}
              onClick={() => {
                handleExitView();
              }}
            >
              Exit View
            </button>
          <button
            className="btn btn-danger btn-sm text-white"
            onClick={handleDelete}
            disabled={!preSelectedView}
          >
            Add to Recycling Bin
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

CanvasLoadView.propTypes = {
  showViewLoadMenu: PropTypes.bool,
  onShowViewLoadMenu: PropTypes.func,
  selectedProject: PropTypes.obj,
  selectedBranch: PropTypes.obj,
  selectedView: PropTypes.obj,
  views: PropTypes.array,
  handleViewLoad: PropTypes.func,
  handleExitView: PropTypes.func
};

export default CanvasLoadView;
